import Container from '@mui/material/Container';
import React from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import PurchaseNewEditForm from './new-edit-form';

export default function PurchaseOrderCreate() {
  const { themeStretch } = useSettings();

  const { translate } = useLocales();

  return (
    <Page title={translate('inventory.purchase_orders.purchase_order_create')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.purchase_orders.purchase_order_create')}
          links={[
            {
              name: translate('nav.dashboard'),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate('inventory.purchase_orders.purchase_orders'),
              href: PATH_DASHBOARD.inventory.purchaseOrders,
            },
            { name: translate('inventory.purchase_orders.purchase_order_create') },
          ]}
        />

        <PurchaseNewEditForm />
      </Container>
    </Page>
  );
}
