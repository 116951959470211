import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import useLocales from 'src/hooks/useLocales';
import { fDate } from 'src/utils/formatTime';
import styles from './TransferRequestStyle';

// ----------------------------------------------------------------------

type Props = {
  invoice: any;
  user: any;
};

export default function TransferRequestPDF({ invoice, user }: Props) {
  const { fromInventory } = invoice;

  const { translate } = useLocales();

  return (
    <Document>
      <Page size="A4" style={[styles.page, { textAlign: 'right' }]}>
        <View style={[styles.gridContainer, { marginBottom: 30 }]}>
          <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
            <Text style={styles.h3}>
              {translate(`inventory.purchase_orders.status.${invoice.status}`)}
            </Text>
            <Text style={styles.body1}>
              {translate('inventory.transfers_requests.transfer_request_no')}
            </Text>
            <Text style={styles.overline}> {`TR-${invoice.id}`} </Text>
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Image
              source={user?.companyMemberships[0].company.profilePictureUrl}
              style={{ height: 20 }}
            />
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={[styles.overline, styles.mb8]}>
              {translate('inventory.transfers_requests.transfer_request_due_date')}
            </Text>
            <Text style={styles.body1}>{fDate(invoice.dueAt)}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer]}>
          <View style={styles.col4}>
            <Text style={[styles.h3, styles.mb8]} />
          </View>
          <View style={[styles.col4, { textAlign: 'center' }]}>
            <Text style={[styles.h3, styles.mb8]}>
              {translate('inventory.transfers_requests.transfer_request')}
            </Text>
          </View>
          <View style={styles.col4}>
            <Text style={[styles.overline, styles.mb8]}>
              {translate('accounting.invoicing.company_details')}
            </Text>
            <Text style={styles.subtitle2}>
              {user?.companyMemberships[0].company.name} : {translate('account.company.name')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {user?.companyMemberships[0].company.address} : {translate('account.company.address')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {user?.companyMemberships[0].company.vatRegistrationNumber} :{' '}
              {translate('account.company.vatRegistrationNumber')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {fromInventory?.name} : {translate('inventory.transfers_requests.warehouse')}
            </Text>
          </View>
        </View>
        <View style={[styles.gridContainer, { marginBottom: 30 }]}>
          <View style={styles.col8}>
            <Text style={[styles.overline, styles.mb8]} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
          </View>
        </View>
        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]} />
            <Text style={[styles.body1, { marginRight: 10 }]} />
            <Text style={[styles.body1, { marginRight: 10 }]} />
          </View>
          <View style={styles.col4}>
            <Text style={[styles.subtitle2]}>
              {translate('inventory.transfers_requests.to_inventory')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {invoice.toInventory.name} : {translate('customers.name')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {invoice.toInventory.address ?? 'NaN'} : {translate('company.warehouses.address')}
            </Text>
            {/* <Text style={[styles.subtitle2]}>
              {supplier.vatRegistrationNumber} : {translate('customers.vatRegistrationNumber')}
            </Text> */}
          </View>
        </View>
        <Text style={[styles.overline, styles.mb8]}>
          {translate('inventory.transfers_requests.transfer_request_details')}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>{translate('orders.qty')}</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>{translate('orders.description')}</Text>
              </View>

              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {invoice.transferItems.map((item: any, index: number) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{item.createdQty}</Text>
                </View>
                <View style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>{item.item.name}</Text>
                </View>

                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={{ marginTop: 100 }} />

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={[styles.col8, styles.alignLeft]}>
            <Text style={styles.body1}>
              {translate('accounting.invoicing.onestep_system')}{' '}
              {translate('accounting.invoicing.generated_using')}{' '}
            </Text>
            <Text>info@onestep.com.sa</Text>
          </View>
          <View style={[styles.col4, styles.alignRight]}>
            <Text style={styles.subtitle2}>
              {translate('inventory.transfers_requests.transfer_request_status')}
            </Text>
            <Text> {translate(`inventory.transfers_requests.status.${invoice.status}`)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
