import { createSlice } from '@reduxjs/toolkit';
// utils
import { CreateOrderType, OrderType, OrderTypeState } from '../../@types/orderType';
import axios from '../../utils/axios';
//

// ----------------------------------------------------------------------

const initialState: OrderTypeState = {
  isLoading: false,
  error: null,
  orderTypes: [],
  orderType: null,
};

const slice = createSlice({
  name: 'orderType',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET OrderTypes
    getOrderTypesSuccess(state, action) {
      state.isLoading = false;
      state.orderTypes = action.payload;
    },

    // GET OrderType
    getOrderTypeSuccess(state, action) {
      state.isLoading = false;
      state.orderType = action.payload;
    },

    // CREATE OrderType
    createOrderTypeSuccess(state, action) {
      state.isLoading = false;
      state.orderTypes.push(action.payload);
    },

    // UPDATE OrderType
    updateOrderTypeSuccess(state, action) {
      state.isLoading = false;
      state.orderTypes = state.orderTypes.map((orderType) => {
        if (orderType.id === action.payload.id) {
          return action.payload;
        }
        return orderType;
      });
    },

    // DELETE OrderType
    deleteOrderTypeSuccess(state, action) {
      state.isLoading = false;
      state.orderTypes = state.orderTypes.filter((orderType) => orderType.id !== action.payload);
    },
  },
});
// Reducer

export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getOrderTypesSuccess,
  getOrderTypeSuccess,
  createOrderTypeSuccess,
  updateOrderTypeSuccess,
  deleteOrderTypeSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getOrderTypes(branchId: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/order-types/${branchId}`);
      dispatch(getOrderTypesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getOrderTypesByBranch(branchId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/order-types/${branchId}`);
      dispatch(getOrderTypesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getOrderType(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/order-types/${id}`);
      dispatch(getOrderTypeSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function createOrderType(orderType: CreateOrderType) {
  startLoading();
  const { branchId, ...rest } = orderType;
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`/order-types/${branchId}`, rest);
      dispatch(createOrderTypeSuccess(response.data));
    } catch (error) {
      hasError(error);
    }
  };
}

export function updateOrderType(orderType: OrderType) {
  return async (dispatch: any) => {
    const { branchId, ...rest } = orderType;
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/order-types/${branchId}/${orderType.id}`, rest);
      dispatch(updateOrderTypeSuccess(response.data));
      dispatch(getOrderTypes(branchId));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteOrderType(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.delete(`/order-types/${id}`);
      dispatch(deleteOrderTypeSuccess(id));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
