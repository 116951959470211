import { useState } from 'react';
// @mui
import { Checkbox, MenuItem, TableCell, TableRow } from '@mui/material';

// components
import { Can } from '@casl/react';
import { Tax } from 'src/@types/tax';
import Label from 'src/components/Label';
import ability from 'src/utils/ability';
import Iconify from '../../../../../components/Iconify';
import { TableMoreMenu } from '../../../../../components/table';
import useLocales from '../../../../../hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  row: Tax;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function TaxTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: Props) {
  const { translate } = useLocales();

  const { name, rate, isDefault } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell align="left">{name}</TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {rate} %
      </TableCell>

      <TableCell align="center">
        <Label
          variant="ghost"
          color={(isDefault === true && 'success') || (isDefault === false && 'error') || 'default'}
          sx={{ textTransform: 'capitalize' }}
        >
          {isDefault ? translate('general.yes') : translate('general.no')}
        </Label>
      </TableCell>

      <Can I="write" a="tax" ability={ability}>
        {() => (
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {/* <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem> */}

                  {/* <MenuItem
                onClick={() => {
                  onViewRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
                {translate('general.view')}
              </MenuItem> */}

                  <MenuItem
                    onClick={() => {
                      onEditRow();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} />
                    {translate('general.edit')}
                  </MenuItem>
                </>
              }
            />
          </TableCell>
        )}
      </Can>
    </TableRow>
  );
}
