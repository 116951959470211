import { createSlice } from '@reduxjs/toolkit';
// utils
import { ITaxReportState } from '../../../../@types/taxReport/taxReport';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

// ----------------------------------------------------------------------

const initialState: ITaxReportState = {
  isLoading: false,
  error: null,
  taxReport: null,
  branchTaxReport: [],
};

const slice = createSlice({
  name: 'taxReport',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORT
    getTaxReportSuccess(state, action) {
      state.isLoading = false;
      state.taxReport = action.payload;
    },

    // GET BRANCH REPORT
    getBranchTaxReportSuccess(state, action) {
      state.isLoading = false;
      state.branchTaxReport = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;

// Actions

export const { getTaxReportSuccess, getBranchTaxReportSuccess, hasError, startLoading } =
  slice.actions;

// ----------------------------------------------------------------------

export function getTaxReport(from: Date | null, to: Date | null) {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/reports/tax-report/${companyId}`, { from, to });
      dispatch(getTaxReportSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBranchTaxReport(from: Date | null, to: Date | null) {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/reports/branches-tax-report/${companyId}`, { from, to });
      dispatch(getBranchTaxReportSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
