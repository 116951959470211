import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
import useTable, { emptyRows, getComparator } from '../../../../hooks/useTable';
// redux
import { getBranches } from '../../../../redux/slices/branch';
import { getOrderTypes, getOrderTypesByBranch } from '../../../../redux/slices/orderType';
import { useDispatch, useSelector } from '../../../../redux/store';
// @types
import { Branch } from '../../../../@types/branch';
import { OrderType } from '../../../../@types/orderType';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from '../../../../components/table';
// sections
import {
  OrderTypesTableRow,
  OrderTypesTableToolbar,
} from '../../../../sections/@dashboard/warehouse/orderTypes/list';
// Translate
import { Can } from '@casl/react';
import ability from 'src/utils/ability';
import { DialogAnimate } from '../../../../components/animate';
import useLocales from '../../../../hooks/useLocales';
import OrderTypeCreateEditFrom from '../../../../sections/@dashboard/warehouse/orderTypes/OrderTypeCreateEditFrom';
import Joyride, { Step } from 'react-joyride';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'inventory.order_types.name', align: 'left' },
  { id: 'nameLocalized', label: 'inventory.order_types.name_localized', align: 'left' },
  { id: 'isDefault', label: 'inventory.order_types.is_default', align: 'center' },
  { id: 'isActive', label: 'inventory.order_types.is_active', align: 'center' },
  { id: 'branch', label: 'inventory.order_types.branch', align: 'center' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function OrderTypesList() {
  const { themeStretch , firstLogin } = useSettings();

  const { translate } = useLocales();

  const [{ run, steps }, setState] = useState({
    run: firstLogin,
    steps: [
      {
        title: translate('tourSteps.orderTypes.title') as string,
        content: <h2>{
          translate('tourSteps.orderTypes.description')
          }</h2>,
          locale: { skip: <strong>{
            translate('tourSteps.general.skip')
            }</strong>, 
            next: <strong>{
              translate('tourSteps.general.next')
              }</strong>, 
              last: <strong>{
                translate('tourSteps.general.finish')
                }</strong>, 
                back: <strong>{
                  translate('tourSteps.general.back')
                  }</strong>,
          },
        target: "body",
        placement: "center",
      },
      {
        content: (
          <h2>
            {translate('tourSteps.orderTypes.table')}
          </h2>
        ),
        target: '#step-1',
        title: translate('tourSteps.orderTypes.title') as string,
        locale: { skip: <strong>{
          translate('tourSteps.general.skip')
          }</strong>, 
          next: <strong>{
            translate('tourSteps.general.next')
            }</strong>, 
            last: <strong>{
              translate('tourSteps.general.finish')
              }</strong>, 
              back: <strong>{
                translate('tourSteps.general.back')
                }</strong>,
        },
      },
      {
        content: <h2>{
          translate('tourSteps.orderTypes.addOrderType')
          }</h2>,
        target: "#step-2",
        title: translate('tourSteps.orderTypes.title') as string,
        locale: { skip: <strong>{
          translate('tourSteps.general.skip')
          }</strong>, 
          next: <strong>{
            translate('tourSteps.general.next')
            }</strong>, 
            last: <strong>{
              translate('tourSteps.general.finish')
              }</strong>, 
              back: <strong>{
                translate('tourSteps.general.back')
                }</strong>,
        },
      },
      {
        content: <h2>{
          translate('tourSteps.orderTypes.editOrderType')
          }</h2>,
        target: "#step-3",
        title: translate('tourSteps.orderTypes.title') as string,
        locale: { skip: <strong>{
          translate('tourSteps.general.skip')
          }</strong>, 
          next: <strong>{
            translate('tourSteps.general.next')
            }</strong>, 
            last: <strong>{
              translate('tourSteps.general.finish')
              }</strong>, 
              back: <strong>{
                translate('tourSteps.general.back')
                }</strong>,
        },
      },
    ]
  });


  const [tourStep, setTourStep] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [onCreateAndEdit, setOnCreateAndEdit] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [currentId, setCurrentId] = useState(0);

  const [filterBranch, setFilterBranch] = useState(0);

  const [currentOrderType, setCurrentOrderType] = useState<OrderType | null>(null);

  const theme = useTheme();

  const handleClose = () => {
    setOnCreateAndEdit(false);
    setIsEdit(false);
    setCurrentId(0);
  };

  const handleEdit = (id: number) => {
    setCurrentId(id);
    setIsEdit(true);
    setOnCreateAndEdit(true);
  };

  const { orderTypes } = useSelector((state) => state.orderTypes);

  const { branches } = useSelector((state) => state.branches);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [tableData, setTableData] = useState(orderTypes as OrderType[]);

  const [filterName, setFilterName] = useState('');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(getOrderTypesByBranch(event.target.value));
    setFilterBranch(parseInt(event.target.value));
  };

  const handleDeleteRow = (id: number) => {
    const deleteRow = tableData.filter((row) => parseInt(row.id) !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleViewRow = (id: number) => {
    navigate('/');
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = (!dataFiltered.length && !!filterName) || !tableData.length;

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    if (filterBranch !== 0) {
      dispatch(getOrderTypes(filterBranch));
    }
    dispatch(getBranches());
  }, [filterBranch, dispatch]);

  useEffect(() => {
    setTableData(orderTypes as OrderType[]);
  }, [orderTypes]);

  useEffect(() => {
    if (filterBranch === 0 && branches.length > 0) {
      setFilterBranch(branches[0].id);
    }
  }, [branches, filterBranch]);

  useEffect(() => {
    const filteredOrderType = orderTypes?.find((orderType) => parseInt(orderType.id) === currentId);
    setCurrentOrderType(filteredOrderType ? filteredOrderType : null);
  }, [currentId, orderTypes]);

  return (
    <Page title={translate('inventory.order_types.title')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.order_types.title')}
          links={[
            { name: translate('routes.root'), href: PATH_DASHBOARD.root },
            { name: translate('inventory.title'), href: PATH_DASHBOARD.inventory.orderTypes },
            { name: translate('inventory.order_types.title') },
          ]}
          action={
            <Can I="write" a="order" ability={ability}>
              {() => (
                <Button
                  id='step-2'
                  variant="contained"
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                  onClick={() => setOnCreateAndEdit(true)}
                >
                  {translate('inventory.order_types.add_order_type')}
                </Button>
              )}
            </Can>
          }
        />
        <Card>
          <OrderTypesTableToolbar
            filterName={filterName}
            filterBranch={filterBranch}
            onFilterName={handleFilterName}
            onFilterBranch={handleFilterBranch}
            branches={branches as Branch[]}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id.toString())
                    )
                  }
                  actions={
                    <Stack spacing={1} direction="row">
                      <Tooltip title="Sent">
                        <IconButton color="primary">
                          <Iconify icon={'ic:round-send'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Download">
                        <IconButton color="primary">
                          <Iconify icon={'eva:download-outline'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Print">
                        <IconButton color="primary">
                          <Iconify icon={'eva:printer-fill'} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id.toString())
                    )
                  }
                />

                <TableBody id="step-1">
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <OrderTypesTableRow
                        id="step-3"
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id.toString())}
                        onSelectRow={() => onSelectRow(row.id.toString())}
                        onViewRow={() => handleViewRow(parseInt(row.id))}
                        onEditRow={() => handleEdit(parseInt(row.id))}
                        onDeleteRow={() => handleDeleteRow(parseInt(row.id))}
                        branches={branches as Branch[]}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label={translate('general.dense')}
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
        <DialogAnimate open={onCreateAndEdit} onClose={handleClose}>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center">
              {translate(
                isEdit
                  ? 'inventory.order_types.edit_order_type'
                  : 'inventory.order_types.add_order_type'
              )}
            </Typography>
          </Box>
          <OrderTypeCreateEditFrom
            handleClose={handleClose}
            isEdit={isEdit}
            currentOrderType={currentOrderType}
          />
        </DialogAnimate>
      </Container>
      <Joyride
        steps={steps as Step[]} // Ensure the type compatibility
        run={run}
        stepIndex={tourStep}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={(data) => {
          console.log(data)
          const { status, type } = data;
          if (status === 'finished' || status === 'skipped' || type === 'tour:end') {
            setState({ run: false, steps });
          }
          if (status === 'running' && type === 'step:after') {
            setTourStep((prevIndex) => prevIndex + 1);
          }
        }}
        styles={{
          options: {
            arrowColor: theme.palette.common.white,
            backgroundColor: theme.palette.common.white,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: theme.palette.primary.main,
            textColor: theme.palette.text.secondary,
            width: 700,
            zIndex: 1000,
          },
        }}
/>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: OrderType[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
