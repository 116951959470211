import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { CardActionArea, Stack } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import useLocales from 'src/hooks/useLocales';


export default function SettingFirstLogin() {

    // get first login from useSettings
    const { firstLogin , onToggleFirstLogin } = useSettings();

    const { translate } = useLocales(); 

    const BoxStyle = styled(CardActionArea)(({ theme }) => ({
        padding: theme.spacing(2),
        color: theme.palette.text.disabled,
        border: `solid 1px ${theme.palette.grey[500_12]}`,
        backgroundColor: theme.palette.background.neutral,
        borderRadius: Number(theme.shape.borderRadius) * 1.25,
      }));

    const handleFirstLogin = () => {
        onToggleFirstLogin();
    }
    
  return (
    <BoxStyle
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3,
            }}
        >
        <Typography variant="subtitle2">
            {
                translate('general.show_assistant')
            }
        </Typography>
        <Switch
            checked={firstLogin}
            onChange={handleFirstLogin}
            color="primary"
            />
    </BoxStyle>


  )
}
