import { createSlice } from '@reduxjs/toolkit';
// utils
import { VariantIngredient, VariantIngredientState } from '../../@types/variantIngredient';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: VariantIngredientState = {
  isLoading: false,
  error: null,
  ingredients: [],
  ingredient: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'variantIngredient',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET variantIngredients
    getVariantIngredientsSuccess(state, action) {
      state.isLoading = false;
      state.ingredients = action.payload;
    },

    // GET productIngredient
    getVariantIngredientSuccess(state, action) {
      state.isLoading = false;
      state.ingredient = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getVariantIngredients(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredients: VariantIngredient[] } } = await axios.get(
        `/ingredients/${defaultBranchId}/variance/${id}`
      );
      dispatch(slice.actions.getVariantIngredientsSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVariantIngredient(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: VariantIngredient } } = await axios.get(
        `/ingredients/${defaultBranchId}/variance/${id}`
      );
      dispatch(slice.actions.getVariantIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createVariantIngredient(data: VariantIngredient) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: VariantIngredient } } = await axios.post(
        `/ingredients/${defaultBranchId}/variance`,
        data
      );
      dispatch(slice.actions.getVariantIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function updateVariantIngredient(data: VariantIngredient, id: number) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: { ingredient: VariantIngredient } } = await axios.patch(
      `/ingredients/${defaultBranchId}/variance/${id}`,
      data
    );
    dispatch(slice.actions.getVariantIngredientSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

// ----------------------------------------------------------------------

export function deleteVariantIngredient(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: VariantIngredient } } = await axios.delete(
        `/ingredients/${defaultBranchId}/variance/${id}`
      );
      dispatch(slice.actions.getVariantIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
