import { createSlice } from '@reduxjs/toolkit';
import { CreateTax, Tax, TaxState } from 'src/@types/tax';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: TaxState = {
  isInitialized: false,
  error: null,
  taxes: [],
  tax: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
      state.error = action.payload;
    },

    // GET Taxes
    getTaxesSuccess(state, action) {
      state.isInitialized = false;
      state.taxes = action.payload;
    },

    // GET Tax
    getTaxSuccess(state, action) {
      state.isInitialized = false;
      state.tax = action.payload;
    },

    // CREATE TAX
    createTaxSuccess(state, action) {
      state.isInitialized = false;
      state.taxes?.push(action.payload as never);
    },
    // UPDATE Tax
    updateTaxSuccess(state, action) {
      state.isInitialized = false;
      state.taxes = state.taxes?.map((tax) => {
        if (tax.id === action.payload.id) {
          return action.payload;
        }
        return tax;
      });
    },
  },
});
// Reducer
export default slice.reducer;

export function getTaxes() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { taxes: Tax[] } } = await axios.get(`/taxes/${companyId}`);
      dispatch(slice.actions.getTaxesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTax(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { tax: Tax } } = await axios.get('/taxes', {
        params: { name },
      });
      dispatch(slice.actions.getTaxSuccess(response.data.tax));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export add tax function
export function addTax(tax: CreateTax) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/taxes/${companyId}`, tax);
      dispatch(slice.actions.createTaxSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export update tax function
export function updateTax(id: number, tax: Tax) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/taxes/${companyId}/${id}`, tax);
      dispatch(slice.actions.updateTaxSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete tax function
export function deleteTax(id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/taxes/${companyId}/${id}`);
      dispatch(slice.actions.updateTaxSuccess(null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
