import { createSlice } from '@reduxjs/toolkit';
// utils
import { Addon, AddonState, UpdateAddonPrice } from '../../@types/product';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: AddonState = {
  isLoading: false,
  error: null,
  addons: [],
  addon: null,
};

const slice = createSlice({
  name: 'addon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Addons
    getAddonsSuccess(state, action) {
      state.isLoading = false;
      state.addons = action.payload;
    },

    // GET PRODUCT
    getAddonSuccess(state, action) {
      state.isLoading = false;
      state.addon = action.payload;
    },

    // CREATE PRODUCT
    createAddonSuccess(state, action) {
      state.isLoading = false;
      state.addons?.push(action.payload);
    },

    // UDPATE PRODUCT
    updateAddonSuccess(state, action) {
      state.isLoading = false;
      state.addons = state.addons.map((addon) => {
        if (addon.id === action.payload.id) {
          return action.payload;
        }
        return addon;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// // Actions
// export const {
//   onGotoStep,
//   onBackStep,
//   onNextStep,
// } = slice.actions;

// ----------------------------------------------------------------------

export function getAddons(id: string) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { addons: Addon[] } } = await axios.get(
        `/add-ons/${defaultBranchId}/${id}`
      );
      dispatch(slice.actions.getAddonsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// edit addon function
export function editAddon(id: string | null | undefined, addon: any) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    const body = {
      ...addon,
    };
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { addon: Addon } } = await axios.patch(
        `/add-ons/${defaultBranchId}/${id}`,
        body
      );
      dispatch(slice.actions.getAddonSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddon(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { addon: Addon } } = await axios.get('/products/item', {
        params: { id },
      });
      dispatch(slice.actions.getAddonSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAddon(id: number, addon: any) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    const body = {
      ...addon,
      itemId: id,
    };
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { addon: Addon } } = await axios.post(
        `/add-ons/${defaultBranchId}`,
        body
      );
      dispatch(slice.actions.createAddonSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateAddonPrice(branchId: number, data: UpdateAddonPrice) {
  dispatch(slice.actions.startLoading());
  try {
    await axios.post(`/add-ons/${branchId}/update-price`, { ...data });
    dispatch(slice.actions.updateAddonSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
}

// delete addon function
export function deleteAddon(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { addon: Addon } } = await axios.delete(
        `/add-ons/${defaultBranchId}/${id}`
      );
      dispatch(slice.actions.getAddonSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
