import { createSlice } from '@reduxjs/toolkit';
// utils
import { UnitOfMeasureConversion, UnitOfMeasureConversionState } from '../../@types/uomConversion';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: UnitOfMeasureConversionState = {
  isLoading: false,
  error: null,
  unitOfMeasureConversions: [],
  unitOfMeasureConversion: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'conversion',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET UnitOfMeasures
    getConversionsSuccess(state, action) {
      state.isLoading = false;
      state.unitOfMeasureConversions = action.payload;
    },

    // GET unitOfMeasure
    getConversionSuccess(state, action) {
      state.isLoading = false;
      state.unitOfMeasureConversion = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getConversions(id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasureConversions: UnitOfMeasureConversion[] } } =
        await axios.get(`/units-of-measurement/${companyId}/${id}/conversions`);
      dispatch(slice.actions.getConversionsSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversion(name: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasureConversion: UnitOfMeasureConversion } } =
        await axios.get(`/units-of-measurement/${companyId}`, {
          params: { name },
        });
      dispatch(slice.actions.getConversionSuccess(response.data.unitOfMeasureConversion));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//add UnitOfMeasure
export function addConversion(unitOfMeasureConversion: UnitOfMeasureConversion) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasureConversion: UnitOfMeasureConversion } } =
        await axios.post(`/units-of-measurement/${companyId}/conversions`, unitOfMeasureConversion);
      dispatch(slice.actions.getConversionSuccess(response.data.unitOfMeasureConversion));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//update UnitOfMeasure
export function updateConversion(id: string, unitOfMeasureConversion: { qty: number }) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasureConversion: UnitOfMeasureConversion } } =
        await axios.patch(
          `/units-of-measurement/${companyId}/conversions/${id}`,
          unitOfMeasureConversion
        );
      dispatch(slice.actions.getConversionSuccess(response.data.unitOfMeasureConversion));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
