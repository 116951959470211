// @mui
import {
  Box,
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ItemElement, StockTaking } from 'src/@types/stockTaking';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import useLocales from 'src/hooks/useLocales';
import { fDate } from 'src/utils/formatTime';
import StockTakingToolbar from './StockTakingToolbar';

// ----------------------------------------------------------------------

type Props = {
  stockTaking: StockTaking;
};

export default function StockTakingDetails({ stockTaking }: Props) {
  const theme = useTheme();
  const { translate } = useLocales();

  const { createdAt, status, items } = stockTaking;

  console.log(stockTaking);
  return (
    <>
      <StockTakingToolbar stockTaking={stockTaking} />
      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'left' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (status === 'paid' && 'success') ||
                  (status === 'unpaid' && 'warning') ||
                  (status === 'overdue' && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {status}
              </Label>
              <Typography>{translate('accounting.invoicing.invoice_no')}</Typography>
              <Typography variant="h6">{`INV-${stockTaking.id}`}</Typography>
              <Typography paragraph variant="overline">
                {' '}
                {translate('accounting.invoicing.invoice_date')}
              </Typography>
              <Typography variant="body2">{fDate(createdAt)}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'center' } }}>
              <Typography variant="h6">
                {' '}
                {translate(`inventory.stock_taking.stock_takings`)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {translate('inventory.stock_taking.stock_taking_warehouse')}
              </Typography>
              <Typography sx={{ textAlign: { sm: 'right' } }} variant="body2">
                {stockTaking.inventory.name}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">{translate('orders.description')}</TableCell>
                  <TableCell align="left">
                    {translate('inventory.stock_taking.old_quantity')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('inventory.stock_taking.new_quantity')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('inventory.stock_taking.difference')}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {items.map((row: ItemElement, index: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{row.itemChild.item.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.oldQty}</TableCell>
                    <TableCell align="right">{row.newQty}</TableCell>
                    <TableCell align="right">{row.newQty - row.oldQty}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <Divider sx={{ mt: 5 }} />
      </Card>
    </>
  );
}
