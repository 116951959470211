import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { ItemElement, StockTaking } from 'src/@types/stockTaking';
import useLocales from 'src/hooks/useLocales';
import { fDate } from 'src/utils/formatTime';
import styles from './StockTakingStyle';

// ----------------------------------------------------------------------

type Props = {
  stockTaking: StockTaking;
  user: any;
};

export default function StockTakingPDF({ stockTaking, user }: Props) {
  const { items, status, inventory, createdAt } = stockTaking;

  const { translate } = useLocales();

  return (
    <Document>
      <Page size="A4" style={[styles.page, { textAlign: 'right' }]}>
        <View style={[styles.gridContainer, { marginBottom: 30 }]}>
          <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
            <Text style={styles.h3}>{status}</Text>
            <Text style={styles.body1}>{translate('accounting.invoicing.invoice_no')}</Text>
            <Text> {`INV-${stockTaking.id}`} </Text>
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Image
              src={{
                uri: user?.companyMemberships[0].company.profilePictureUrl,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                },
                body: '',
              }}
              style={{ height: 20 }}
            />
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={[styles.overline, styles.mb8]}>
              {translate('accounting.invoicing.invoice_date')}
            </Text>
            <Text style={styles.body1}>{fDate(createdAt)}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer]}>
          <View style={styles.col4}>
            <Text style={[styles.h3, styles.mb8]} />
          </View>
          <View style={[styles.col4, { textAlign: 'center' }]}>
            <Text style={[styles.body2, styles.mb8]}>
              {translate(`inventory.stock_taking.stock_taking`)}
            </Text>
          </View>
          <View style={styles.col4}>
            <Text style={[styles.overline, styles.mb8]}>
              {translate('accounting.invoicing.company_details')}
            </Text>
            <Text style={styles.subtitle2}>
              {user?.companyMemberships[0].company.name} : {translate('account.company.name')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {user?.companyMemberships[0].company.address} : {translate('account.company.address')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {user?.companyMemberships[0].company.vatRegistrationNumber} :{' '}
              {translate('account.company.vatRegistrationNumber')}
            </Text>
          </View>
        </View>
        <View style={[styles.gridContainer, { marginBottom: 30 }]}>
          <View style={styles.col8}>
            <Text style={[styles.overline, styles.mb8]} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
          </View>
        </View>
        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]} />
            <Text style={[styles.body1, { marginRight: 10 }]} />
            <Text style={[styles.body1, { marginRight: 10 }]} />
          </View>
          <View style={styles.col4}>
            <Text style={[styles.subtitle2]}>{translate('accounting.invoicing.customer')}</Text>
            <Text style={[styles.subtitle2]}>
              {inventory.name} : {translate('customers.name')}
            </Text>
          </View>
        </View>
        <Text style={[styles.overline, styles.mb8]}>
          {translate('accounting.invoicing.invoice_details')}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>
                  {translate('inventory.stock_taking.difference')}
                </Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>
                  {translate('inventory.stock_taking.new_quantity')}
                </Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>
                  {translate('inventory.stock_taking.old_quantity')}
                </Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>{translate('orders.description')}</Text>
              </View>

              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {items.map((item: ItemElement, index: number) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{item.newQty - item.oldQty}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item.newQty}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item.oldQty}</Text>
                </View>

                <View style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>{item.itemChild.item.name}</Text>
                </View>

                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={[styles.gridContainer, styles.footer]}>
          <View style={[styles.col8, styles.alignLeft]}>
            <Text style={styles.body1}>
              {translate('accounting.invoicing.onestep_system')}{' '}
              {translate('accounting.invoicing.generated_using')}{' '}
            </Text>
            <Text>info@onestep.com.sa</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
