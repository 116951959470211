import { createSlice } from '@reduxjs/toolkit';
import { CreatePurchaseTax, PurchaseTax, PurchaseTaxState } from 'src/@types/purchaseTax';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: PurchaseTaxState = {
  isInitialized: false,
  error: null,
  purchaseTaxes: [],
  purchaseTax: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'purchaseTax',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
      state.error = action.payload;
    },

    // GET Taxes
    getPurchaseTaxesSuccess(state, action) {
      state.isInitialized = false;
      state.purchaseTaxes = action.payload;
    },

    // GET Tax
    getPurchaseTaxSuccess(state, action) {
      state.isInitialized = false;
      state.purchaseTax = action.payload;
    },

    // CREATE TAX
    createPurchaseTaxSuccess(state, action) {
      state.isInitialized = false;
      state.purchaseTaxes.push(action.payload as never);
    },

    // UPDATE Tax
    updatePurchaseTaxSuccess(state, action) {
      state.isInitialized = false;
      state.purchaseTaxes = state.purchaseTaxes?.map((purchaseTax) => {
        if (purchaseTax.id === action.payload.id) {
          return action.payload;
        }
        return purchaseTax;
      });
    },
  },
});
// Reducer
export default slice.reducer;

export function getPurchaseTaxes() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { purchaseTaxes: PurchaseTax[] } } = await axios.get(
        `/purchase-taxes/${companyId}`
      );
      dispatch(slice.actions.getPurchaseTaxesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPurchaseTax(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { purchaseTax: PurchaseTax } } = await axios.get('/purchase-taxes', {
        params: { name },
      });
      dispatch(slice.actions.getPurchaseTaxSuccess(response.data.purchaseTax));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export add tax function
export function addPurchaseTax(tax: CreatePurchaseTax) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/purchase-taxes/${companyId}`, tax);
      dispatch(slice.actions.createPurchaseTaxSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export update tax function
export function updatePurchaseTax(id: number, tax: PurchaseTax) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/purchase-taxes/${companyId}/${id}`, tax);
      dispatch(slice.actions.updatePurchaseTaxSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete tax function
export function deleteTax(id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/purchase-taxes/${companyId}/${id}`);
      dispatch(getPurchaseTaxes());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
