// @mui
import { Box, List } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { CanViewMenuGroup } from 'src/utils/utils';
import { NavSectionProps } from '../type';
import NavList from './NavList';
import { ListSubheaderStyle } from './style';
import { Tooltip } from 'react-tooltip'

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(`nav.${group.subheader}`)}
          </ListSubheaderStyle>

          {group.items.map(
            (list) =>
              CanViewMenuGroup(list) && (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChildren={!!list.children}
                  isCollapse={isCollapse}
                />
              )
          )}
        </List>
      ))}
    </Box>
  );
}
