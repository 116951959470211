import { createSlice } from '@reduxjs/toolkit';
// utils
import { CreateCustomer, Customer, CustomerState } from '../../@types/customer';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState: CustomerState = {
  isLoading: false,
  error: null,
  customers: [],
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET customers
    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
    },

    // CREATE customer
    createCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customers = [...state.customers, action.payload];
    },

    // UPDATE customer
    updateCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customers = state.customers.map((customer) => {
        if (customer.id === action.payload.id) {
          return action.payload;
        }
        return customer;
      });
    },

    // DELETE customer
    deleteCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customers = state.customers.filter((customer) => customer.id !== action.payload);
    },
  },
});

// Reducer
// // Actions
export const {
  startLoading,
  hasError,
  createCustomerSuccess,
  deleteCustomerSuccess,
  getCustomersSuccess,
  updateCustomerSuccess,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/customers/${companyId}`);
      dispatch(slice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createCustomer(data: CreateCustomer) {
  const companyId = localStorage.getItem('companyId');
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`/customers/${companyId}`, data);
    dispatch(slice.actions.createCustomerSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function updateCustomer(data: Customer) {
  const companyId = localStorage.getItem('companyId');
  dispatch(slice.actions.startLoading());
  const { id, ...rest } = data;
  try {
    const response = await axios.patch(`/customers/${companyId}/${id}`, rest);
    dispatch(slice.actions.updateCustomerSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function deleteCustomer(id: string) {
  const companyId = localStorage.getItem('companyId');
  dispatch(slice.actions.startLoading());
  try {
    await axios.delete(`/customers/${companyId}/${id}`);
    dispatch(slice.actions.deleteCustomerSuccess(id));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function getCustomerStats(id: number) {
  const companyId = localStorage.getItem('companyId');
  // dispatch(slice.actions.startLoading());
  try {
    return await axios.get(`/customers/${companyId}/${id}/stats`);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}
