import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';

export default function AppNoDataFound() {
  const { translate } = useLocales();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Iconify
          icon="mdi:emoticon-sad-outline"
          sx={{
            width: 100,
            height: 100,
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3
          style={{
            color: '#6B7280',
            fontSize: '1.5rem',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          {translate('errors.no_data_found')}
        </h3>
      </div>
    </div>
  );
}
