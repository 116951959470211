import { createSlice } from '@reduxjs/toolkit';
// types
import { CancelReason, CancelReasonState } from '../../@types/cancelReason';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: CancelReasonState = {
  isLoading: false,
  error: null,
  cancelReasons: [],
  cancelReason: null,
};

const slice = createSlice({
  name: 'cancelReason',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CancelReasons
    getCancelReasonsSuccess(state, action) {
      state.isLoading = false;
      state.cancelReasons = action.payload;
    },

    // GET CancelReason
    getCancelReasonSuccess(state, action) {
      state.isLoading = false;
      state.cancelReason = action.payload;
    },

    // CREATE CancelReason
    createCancelReasonSuccess(state, action) {
      state.isLoading = false;
      state.cancelReasons.push(action.payload);
    },

    // UPDATE CancelReason
    updateCancelReasonSuccess(state, action) {
      state.isLoading = false;
      state.cancelReasons = state.cancelReasons.map((cancelReason) => {
        if (cancelReason.id === action.payload.id) {
          return action.payload;
        }
        return cancelReason;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getCancelReasonsSuccess,
  getCancelReasonSuccess,
  createCancelReasonSuccess,
  updateCancelReasonSuccess,
} = slice.actions;

export function getCancelReasons() {
  const companyId = localStorage.getItem('companyId');
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`cancel-reasons/all/${companyId}`);
      dispatch(getCancelReasonsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getCancelReasonsByBranch(branchId: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`cancel-reasons/${branchId}`);
      dispatch(getCancelReasonsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getByDates(branchId: string, from: Date | null, to: Date | null) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`cancel-reasons/all/${branchId}`, { from, to });
      dispatch(getCancelReasonsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getCancelReason(cancelReasonId: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`cancel-reasons/${cancelReasonId}`);
      dispatch(getCancelReasonSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createCancelReason(cancelReason: CancelReason) {
  return async (dispatch: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, branchId, ...rest } = cancelReason;
    dispatch(startLoading());
    try {
      const response = await axios.post(`cancel-reasons/${branchId}`, rest);
      dispatch(createCancelReasonSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateCancelReason(cancelReason: CancelReason) {
  return async (dispatch: any) => {
    const { id, branchId, ...rest } = cancelReason;
    dispatch(startLoading());
    try {
      const response = await axios.patch(`cancel-reasons/${branchId}/${id}`, rest);
      dispatch(updateCancelReasonSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteCancelReason(branchId: String | null, id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`cancel-reasons/${branchId}/${id}`);
      dispatch(updateCancelReasonSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function activeCancelReason(branchId: String | null, id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`cancel-reasons/${branchId}/active/${id}`);
      dispatch(updateCancelReasonSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
