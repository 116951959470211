import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { getSaleOrders } from 'src/redux/slices/saleOrder';
import { dispatch, useSelector } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import StockTaking from './details';

// ----------------------------------------------------------------------

export default function StockTakingDetails() {
  const { themeStretch } = useSettings();

  const { translate } = useLocales();

  const { id } = useParams();

  const { companyStockTakingHistory } = useSelector((state) => state.companyStockTaking);

  const date = new Date();
  const newDate = new Date(date.setDate(date.getDate() - 7));

  const [filterStartDate] = useState<Date | null>(newDate);

  const [filterEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (companyStockTakingHistory.length === 0) {
      dispatch(getSaleOrders(filterStartDate, filterEndDate));
    }
  }, [filterStartDate, filterEndDate, companyStockTakingHistory.length]);

  const stockTaking = companyStockTakingHistory.find(
    (companyStockTaking) => companyStockTaking.id === parseInt(id as string)
  );

  return (
    <Page title={translate('')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('accounting.invoicing.invoice_details')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('accounting.invoicing.invoice_details'),
              href: PATH_DASHBOARD.invoicing.root,
            },
            { name: `INV-${stockTaking?.id}` || '' },
          ]}
        />

        {stockTaking && <StockTaking stockTaking={stockTaking} />}
      </Container>
    </Page>
  );
}
