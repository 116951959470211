// eslint-disable-next-line import/no-duplicates
import { format, formatDistanceToNow, getTime } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ar } from 'date-fns/locale';

// ----------------------------------------------------------------------
export function fDate(date: Date | string | number) {
  return format(new Date(date), 'PPpp').toLocaleLowerCase();
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'PPpp').toLocaleLowerCase();
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p', { locale: ar }).toLocaleLowerCase();
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
