// import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import type { InferType } from 'yup';
import * as Yup from 'yup';

// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
// translate
import useLocales from '../../../../hooks/useLocales';
// // redux
import { useSnackbar } from 'notistack';
import { UpdateVariantPrice, VariantPrices } from '../../../../@types/product';
import { getOrderTypes } from '../../../../redux/slices/orderType';
import { updateVariantPrice } from '../../../../redux/slices/variant';
import { useDispatch, useSelector } from '../../../../redux/store';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
  // avatarUrl: CustomFile | string | null;
}

type Props = {
  isEdit: boolean;
  currentId: number;
  currentBranchId?: number;
  currentVariantPrices: VariantPrices[] | null;
  handleClosePrices: VoidFunction;
};

export default function VariantPricesNewEditForm({
  isEdit,
  currentId,
  currentBranchId,
  currentVariantPrices,
  handleClosePrices,
}: Props) {
  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { orderTypes } = useSelector((state) => state.orderTypes);

  const yepSchema: any = generateSchema();
  type SchemaType = InferType<typeof yepSchema>;

  const NewPointOfSaleSchema = Yup.object().shape(yepSchema);

  const defaultValues = useMemo(() => {
    const obj: any = {};
    currentVariantPrices?.map(async (variant) => {
      obj[`orderType-${variant.orderTypeId}`] = variant.price;
    });
    return obj;
  }, [currentVariantPrices]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPointOfSaleSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const body: UpdateVariantPrice = {
      variantsId: currentId,
      VariantsPrices: { ...data },
    };
    if (isEdit && currentVariantPrices) {
      try {
        currentBranchId && updateVariantPrice(currentBranchId, body);
        reset();
        enqueueSnackbar(translate('messages.update_success'));
        handleClosePrices();
      } catch (error) {
        enqueueSnackbar(translate('messages.update_error'));
      }
    }
  };

  function createYupSchema(
    schema: SchemaType,
    config: {
      id: string;
      validationType: string;
      validations?: { type: string; params: any[] }[];
    }
  ) {
    const { id, validationType, validations = [] } = config;
    if (!(Yup as any)[validationType]) {
      return schema;
    }
    let validator = (Yup as any)[validationType]();
    validations.forEach((validation: any) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  function generateSchema() {
    const schema: SchemaType = [];
    orderTypes.map((orderType) =>
      schema.push({
        id: `orderType-${orderType.id}`,
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: [translate('Price-is-required')],
          },
        ],
      })
    );
    const yepSchema = schema.reduce(createYupSchema, {});
    return yepSchema;
  }

  useEffect(() => {
    currentBranchId && dispatch(getOrderTypes(currentBranchId));
  }, [currentBranchId, dispatch]);

  useEffect(() => {
    if (isEdit && currentVariantPrices) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentVariantPrices]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              {orderTypes &&
                orderTypes.length > 0 &&
                orderTypes.map((orderType) => (
                  <RHFTextField
                    key={orderType.id}
                    name={`orderType-${orderType.id}`}
                    label={orderType.name}
                    type="number"
                    placeholder={orderType.name}
                    fullWidth
                  />
                ))}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? translate('general.create') : translate('general.edit')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
