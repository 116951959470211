// @mui
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Eotd } from 'src/@types/eotd';
// utils

// components
import Image from 'src/components/Image';
import Label from 'src/components/Label';
import CanceledOrdersList from '../../endOfDay/CanceledOrdersList';
//
import useLocales from 'src/hooks/useLocales';
import { fDateTime } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

type Props = {
  eotd?: Eotd;
  isLoading?: boolean;
};

export default function EtodInvoice({ eotd, isLoading }: Props) {
  const theme = useTheme();
  const { translate } = useLocales();

  if (!eotd) {
    return null;
  }

  const {
    openAt,
    closeAt,
    pointOfSaleSalesReport,
    user,
    orders,
    paymentMethodReport,
    categorySalesReport,
    itemSalesReport,
    orderTypesSalesReport,
  } = eotd;

  return (
    <>
      {/* <EotdToolbar eotd={eotd} /> */}

      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src={user?.profilePictureUrl}
              sx={{ maxWidth: 120 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={pointOfSaleSalesReport?.deference === '0' ? 'success' : 'warning'}
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {pointOfSaleSalesReport?.deference}
              </Label>

              <Typography variant="h6">{`ID-${eotd.id}`}</Typography>
            </Box>
          </Grid>

          {/* user and open at and close at  */}

          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.report_from')}
            </Typography>

            <Typography variant="body2">{user?.name}</Typography>

            <Typography variant="body2">{user?.phone}</Typography>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.openedAt')}
            </Typography>

            <Typography variant="body2">{openAt && fDateTime(openAt)}</Typography>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.closedAt')}
            </Typography>

            <Typography variant="body2">{closeAt && fDateTime(closeAt)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 5 }} />

        {/* Write End of day point of sale report simple view  */}

        {/* pointOfSaleSalesReport */}
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.sales_summary_report')}
            </Typography>
            <Typography variant="body2">{translate('eotd.orders_number')}</Typography>
            <Typography variant="body2">{translate('eotd.refunded_orders_number')}</Typography>
            <Typography variant="body2">{translate('eotd.total_income')}</Typography>
            <Typography variant="body2">{translate('eotd.sales_profit')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.value')}
            </Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.ordersNumber}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.refundedOrdersNumber}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.totalIncome}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.salesProfit}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.payment_method_report')}
            </Typography>
            <Typography variant="body2">{translate('eotd.cash_sales')}</Typography>
            <Typography variant="body2">{translate('eotd.card_sales')}</Typography>
            <Typography variant="body2">{translate('eotd.cash_refunds')}</Typography>
            <Typography variant="body2">{translate('eotd.card_refunds')}</Typography>

            <Typography variant="body2">{translate('eotd.cash_in_opening')}</Typography>
            <Typography variant="body2">{translate('eotd.cash_in_closing')}</Typography>
            <Typography variant="body2">{translate('eotd.expected_cash')}</Typography>
            <Typography variant="body2">{translate('eotd.deference')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.value')}
            </Typography>
            <Typography variant="body2">{paymentMethodReport?.cashSales}</Typography>
            <Typography variant="body2">{paymentMethodReport?.cardSales}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.cashRefunds}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.cardRefunds}</Typography>

            <Typography variant="body2">{pointOfSaleSalesReport?.cashInOpening}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.cashInClosing}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.expectedCash}</Typography>
            <Typography variant="body2">{pointOfSaleSalesReport?.deference}</Typography>
          </Grid>

          {/* order types sales report in table */}

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.order_types_sales_report')}
            </Typography>

            {orderTypesSalesReport?.orderTypes?.map((orderType) => (
              <Typography key={orderType.id} variant="body2">
                {orderType.name}
              </Typography>
            ))}
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.value')}
            </Typography>

            {orderTypesSalesReport?.orderTypes?.map((orderType) => (
              <Typography key={orderType.id} variant="body2">
                {orderType.income}
              </Typography>
            ))}
          </Grid>

          {/* category sales report in table */}

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.category_sales_report')}
            </Typography>

            {categorySalesReport?.categories?.map((category) => (
              <Typography key={category.id} variant="body2">
                {category.name}
              </Typography>
            ))}
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.value')}
            </Typography>

            {categorySalesReport?.categories?.map((category) => (
              <Typography key={category.id} variant="body2">
                {category.paidNumber}
              </Typography>
            ))}
          </Grid>

          {/* item sales report in table */}
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.item_sales_report')}
            </Typography>

            {itemSalesReport?.items?.map((item) => (
              <Typography key={item.id} variant="body2">
                {item.name}
              </Typography>
            ))}
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('eotd.value')}
            </Typography>

            {itemSalesReport?.items?.map((item) => (
              <Typography key={item.id} variant="body2">
                {item.paidNumber}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Card>

      <Divider sx={{ my: 5 }} />

      <CanceledOrdersList orders={orders} isLoading={isLoading} />
    </>
  );
}
