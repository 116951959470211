// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import LogRocket from 'logrocket';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import ThemeSettings from './components/settings';
import { TourProvider } from './contexts/TourContext';
// import setupLogRocketReact from 'logrocket-react';

export default function App() {
  LogRocket.init('bgnr0r/onestep-saas');
  // setupLogRocketReact(LogRocket);
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
