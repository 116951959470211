// defining device state type
export type DeviceState = {
  isLoading: boolean;
  error: Error | string | null;
  devices: Device[];
  device: Device | null;
  deviceModels: DeviceModel[];
  sortBy: string | null;
};

// export type device
export type Device = {
  id: string;
  name: string;
  model: DeviceModel;
  type: DeviceType;
  ip: string;
  branchId: number;
  pointOfSaleId: number;
  categories?: any[];
};

export interface DeviceModel {
  id: string;
  name: string;
}

export enum DeviceType {
  Cashier = 'CASHIER',
  Kitchen = 'KITCHEN',
  Subkitchen = 'SUB_KITCHEN',
}
