import { createSlice } from '@reduxjs/toolkit';
// types
import { CreateRole, InitScopes, Role, RolesState } from '../../@types/role';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: RolesState = {
  isInitialized: false,
  roles: [],
  scopes: [],
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
    },

    // GET ROLES
    getRolesSuccess(state, action) {
      state.isInitialized = true;
      state.roles = action.payload;
    },

    // CREATE ROLE
    createRoleSuccess(state, action) {
      state.isInitialized = true;
      state.roles = action.payload;
    },

    // UPDATE ROLE
    updateRoleSuccess(state, action) {
      state.isInitialized = true;
      state.roles = action.payload;
    },

    // DELETE ROLE
    deleteRoleSuccess(state, action) {
      state.isInitialized = true;
      state.roles = action.payload;
    },

    // UPDATE ROLE SCOPES
    updateRoleScopesSuccess(state, action) {
      state.isInitialized = true;
      state.roles = action.payload;
    },

    // Get initial scopes success
    getInitScopesSuccess(state, action) {
      state.isInitialized = true;
      state.scopes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getRolesSuccess,
  createRoleSuccess,
  updateRoleSuccess,
  deleteRoleSuccess,
  updateRoleScopesSuccess,
  getInitScopesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getRoles() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await axios.get(`/roles/${companyId}`);
      dispatch(getRolesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function createRole(data: CreateRole) {
  const companyId = localStorage.getItem('companyId');
  startLoading();
  try {
    const response = await axios.post(`/roles/${companyId}`, data);
    createRoleSuccess(response.data);
  } catch (error) {
    hasError(error);
  }
}

export async function updateRole(data: CreateRole) {
  const { id, ...rest } = data;
  const companyId = localStorage.getItem('companyId');
  startLoading();
  try {
    const response = await axios.patch(`/roles/${companyId}/${id}`, rest);
    createRoleSuccess(response.data);
  } catch (error) {
    hasError(error);
  }
}

export async function deleteRole(id: number) {
  const companyId = localStorage.getItem('companyId');
  startLoading();
  try {
    const response = await axios.delete(`/roles/${companyId}/${id}`);
    deleteRoleSuccess(response.data);
    return response;
  } catch (error) {
    hasError(error);
    return error;
  }
}

export function updateRoleScopes(data: Role) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.put('/api/roles/scopes', data);
      dispatch(updateRoleScopesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getInitScopes() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response: { data: InitScopes[] } = await axios.get('/auth/init-scopes');
      dispatch(getInitScopesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
