import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
//
import { CreditNote, CreditNoteState } from 'src/@types/creditNote';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: CreditNoteState = {
  isLoading: false,
  error: null,
  creditNotes: [],
  creditNote: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'creditNote',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Credit Notes Success
    getCreditNotesSuccess(state, action) {
      state.isLoading = false;
      state.creditNotes = action.payload;
    },

    // GET Credit Note Success
    getCreditNoteSuccess(state, action) {
      state.isLoading = false;
      state.creditNote = action.payload;
    },

    // UPDATE Credit Note Success
    updateCreditNoteSuccess(state, action) {
      state.isLoading = false;
      state.creditNote = action.payload;
    },
    // CREATE Credit Note Success
    createCreditNoteSuccess(state, action) {
      state.isLoading = false;
      state.creditNotes = [...state.creditNotes, action.payload];
    },
  },
});
// Reducer
export default slice.reducer;

export function getCreditNotes() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { creditNote: CreditNote[] } } = await axios.get(
        `/credit-notes/company/${companyId}`
      );
      dispatch(slice.actions.getCreditNotesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCreditNote(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { creditNote: CreditNote } } = await axios.get('/credit-notes', {
        params: { name },
      });
      dispatch(slice.actions.getCreditNoteSuccess(response.data.creditNote));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// export add sale order
export function addCreditNote(creditNote: any) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    console.log('creditNote', creditNote);
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { creditNote: CreditNote } } = await axios.post(
        `/credit-notes/${defaultBranchId}`,
        creditNote
      );
      dispatch(slice.actions.createCreditNoteSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function payCreditNote(
  id: string,
  payload: {
    paymentMethod: string;
    paid: number;
  }
) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { creditNote: CreditNote } } = await axios.post(
        `/sale-orders/${defaultBranchId}/pay/${id}`,
        payload
      );
      dispatch(slice.actions.updateCreditNoteSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function cancelSaleOrder(id: string) {
//   const defaultBranchId = localStorage.getItem('defaultBranchId');
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response: { data: { saleOrder: SaleOrder } } = await axios.get(
//         `/sale-orders/${defaultBranchId}/cancelled/${id}`
//       );
//       dispatch(slice.actions.updateCreditNoteSuccess(response.data));
//     } catch (error) {
//       console.error(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
