import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import qr from 'qr-image';
import { useEffect, useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import { fDate } from 'src/utils/formatTime';
import styles from './SaleOrderStyle';

// ----------------------------------------------------------------------

type Props = {
  invoice: any;
  user: any;
  getPriceByOrderType: (orderType: string) => number;
};

export default function SaleOrderPDF({ invoice, user, getPriceByOrderType }: Props) {
  const { orderItems, status, orderDate, totalWithTax, customer, orderInvoice, totalTax } = invoice;

  const { translate } = useLocales();
  let [QRCode, setQRCode] = useState<any>(null);
  useEffect(() => {
    if (orderInvoice?.qrCode) {
      setQRCode(generateQRCode(orderInvoice?.qrCode));
    }
  }, [orderInvoice?.qrCode]);

  const getInvoiceTitle = () => {
    if (customer.vatRegistrationNumber == null || customer.vatRegistrationNumber === undefined) {
      if (orderInvoice.status !== 'CANCELLED') {
        return translate('accounting.invoicing.simplified_tax_invoice');
      }
      return translate('accounting.invoicing.simplified_tax_invoice_return');
    } else {
      if (orderInvoice.status !== 'CANCELLED') {
        return translate('accounting.invoicing.tax_invoice');
      }
      return translate('accounting.invoicing.tax_invoice_return');
    }
  };

  return (
    <Document>
      <Page size="A4" style={[styles.page, { textAlign: 'right' }]}>
        <View style={[styles.gridContainer, { marginBottom: 30 }]}>
          <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
            <Text style={styles.h3}>{status}</Text>
            <Text style={styles.body1}>{translate('accounting.invoicing.invoice_no')}</Text>
            <Text> {`INV-${orderInvoice.invoiceNumber}`} </Text>
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Image
              src={{
                uri: user?.companyMemberships[0].company.profilePictureUrl,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                },
                body: '',
              }}
              style={{ height: 20 }}
            />
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={[styles.overline, styles.mb8]}>
              {translate('accounting.invoicing.invoice_date')}
            </Text>
            <Text style={styles.body1}>{fDate(orderDate)}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer]}>
          <View style={styles.col4}>
            <Text style={[styles.h3, styles.mb8]} />
          </View>
          <View style={[styles.col4, { textAlign: 'center' }]}>
            <Text style={[styles.body2, styles.mb8]}>{customer && getInvoiceTitle()}</Text>
          </View>
          <View style={styles.col4}>
            <Text style={[styles.overline, styles.mb8]}>
              {translate('accounting.invoicing.company_details')}
            </Text>
            <Text style={styles.subtitle2}>
              {user?.companyMemberships[0].company.name} : {translate('account.company.name')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {user?.companyMemberships[0].company.address} : {translate('account.company.address')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {user?.companyMemberships[0].company.vatRegistrationNumber} :{' '}
              {translate('account.company.vatRegistrationNumber')}
            </Text>
          </View>
        </View>
        <View style={[styles.gridContainer, { marginBottom: 30 }]}>
          <View style={styles.col8}>
            <Text style={[styles.overline, styles.mb8]} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
          </View>
        </View>
        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]} />
            <Text style={[styles.body1, { marginRight: 10 }]} />
            <Text style={[styles.body1, { marginRight: 10 }]} />
          </View>
          <View style={styles.col4}>
            <Text style={[styles.subtitle2]}>{translate('accounting.invoicing.customer')}</Text>
            <Text style={[styles.subtitle2]}>
              {customer.name} : {translate('customers.name')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {customer.phone} : {translate('customers.phone')}
            </Text>
            <Text style={[styles.subtitle2]}>
              {customer.vatRegistrationNumber} : {translate('customers.vatRegistrationNumber')}
            </Text>
          </View>
        </View>
        <Text style={[styles.overline, styles.mb8]}>
          {translate('accounting.invoicing.invoice_details')}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>{translate('orders.total')}</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>{translate('orders.unit_price')}</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>{translate('orders.qty')}</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>{translate('orders.description')}</Text>
              </View>

              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {orderItems.map((item: any, index: number) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>
                    {translate('general.SR')} {item.itemChild.item.price * item.qty}
                  </Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>
                    {translate('general.SR')} {getPriceByOrderType(item.itemChild.id)}
                  </Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item.qty}</Text>
                </View>

                <View style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>{item.itemChild.item.name}</Text>
                </View>

                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>
              </View>
            ))}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_3}>
                <Text>{translate('accounting.invoicing.invoice_tax')}</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>
                  {translate('general.SR')} {totalTax}
                </Text>
              </View>

              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
            </View>
            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_3}>
                <Text>{translate('orders.total_without_tax')}</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>
                  {translate('general.SR')} {totalWithTax - totalTax}
                </Text>
              </View>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
            </View>
            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_3}>
                <Text>{translate('orders.total')}</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>
                  {translate('general.SR')} {totalWithTax}
                </Text>
              </View>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
            </View>
          </View>
        </View>

        <View style={{ marginTop: 100 }}>
          {orderInvoice.qrCode && (
            <View style={{ height: 'auto', margin: '0 auto', maxWidth: 120, width: '100%' }}>
              <Image src={QRCode} />
            </View>
          )}
        </View>

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={[styles.col8, styles.alignLeft]}>
            <Text style={styles.body1}>
              {translate('accounting.invoicing.onestep_system')}{' '}
              {translate('accounting.invoicing.generated_using')}{' '}
            </Text>
            <Text>info@onestep.com.sa</Text>
          </View>
          <View style={[styles.col4, styles.alignRight]}>
            <Text style={styles.subtitle2}>
              {translate('inventory.purchase_orders.actions.payment_terms')}
            </Text>
            <Text> {translate(`inventory.purchase_orders.terms.${orderInvoice.term}`)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const generateQRCode = (text: string): string => {
  const qrCode = qr.imageSync(text, { type: 'png' });
  return `data:image/png;base64,${qrCode.toString('base64')}`;
};
