import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { DialogAnimate } from 'src/components/animate';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useToggle from 'src/hooks/useToggle';
import ApproveTransfer from './ApproveTransferForm';
import TransferRequestPDF from './TransferRequestPDF';

// ----------------------------------------------------------------------

type Props = {
  invoice: any;
};

export default function TransferRequestToolbar({ invoice }: Props) {
  const navigate = useNavigate();

  const { translate } = useLocales();

  const { user } = useAuth();

  const { toggle: open, onOpen, onClose } = useToggle();

  const [onCreateAndEdit, setOnCreateAndEdit] = useState(false);

  const handleClose = () => {
    setOnCreateAndEdit(false);
  };
  const handleEdit = () => {
    setOnCreateAndEdit(true);
  };

  return (
    <>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ sm: 'center' }}
        sx={{ mb: 5 }}
      >
        <DialogAnimate open={onCreateAndEdit} onClose={handleClose}>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center">
              {translate('general.approve')}
            </Typography>
          </Box>
          <ApproveTransfer id={invoice.id} currentInvoice={invoice} />
        </DialogAnimate>

        <Stack direction="row" spacing={1}>
          <Tooltip title="View">
            <IconButton onClick={onOpen}>
              <Iconify icon={'eva:eye-fill'} />
            </IconButton>
          </Tooltip>

          <PDFDownloadLink
            document={<TransferRequestPDF invoice={invoice} user={user} />}
            fileName={invoice.invoiceNumber}
            style={{ textDecoration: 'none' }}
          >
            {({ loading }) => (
              <Tooltip title="Download">
                <IconButton>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Iconify icon={'eva:download-fill'} />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </PDFDownloadLink>

          <Tooltip title="Print">
            <IconButton>
              <Iconify icon={'eva:printer-fill'} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Send">
            <IconButton>
              <Iconify icon={'ic:round-send'} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Share">
            <IconButton>
              <Iconify icon={'eva:share-fill'} />
            </IconButton>
          </Tooltip>
        </Stack>

        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ sm: 'center' }}
          // sx={{ mb: 5 }}
        >
          {invoice.status === 'PENDING' ||
            (invoice.status === 'INITIAL' && (
              <Button
                color="success"
                variant="contained"
                startIcon={<Iconify icon={'eva:checkmark-fill'} />}
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => handleEdit()}
              >
                {translate('general.approve')}
              </Button>
            ))}

          {(invoice.status === 'PENDING' && invoice.status !== 'APPROVED') ||
            (invoice.status === 'INITIAL' && invoice.status !== 'APPROVED' && (
              <Button
                color="error"
                variant="outlined"
                startIcon={<Iconify icon={'ic:outline-error'} />}
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => handleEdit()}
              >
                {translate('general.reject')}
              </Button>
            ))}

          {
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon={'material-symbols:call-received-rounded'} />}
              sx={{ alignSelf: 'flex-end' }}
              onClick={() => navigate(`/dashboard/ware-house/transfers/${invoice.id}/edit`)}
            >
              {translate('general.receive')}
            </Button>
          }
        </Stack>
      </Stack>

      <Dialog fullScreen open={open}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={onClose}>
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <TransferRequestPDF invoice={invoice} user={user} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
