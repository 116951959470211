import { useState } from 'react';
// @mui
import { Checkbox, Link, MenuItem, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
import createAvatar from '../../../../../utils/createAvatar';
import { fDateTimeSuffix } from '../../../../../utils/formatTime';
// @types
import { Order } from '../../../../../@types/order';
// components
import useLocales from 'src/hooks/useLocales';
import Avatar from '../../../../../components/Avatar';
import Iconify from '../../../../../components/Iconify';
import Label from '../../../../../components/Label';
import { TableMoreMenu } from '../../../../../components/table';

// ----------------------------------------------------------------------

type Props = {
  row: Order;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function OrderTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: Props) {
  const theme = useTheme();

  const { orderNumber, orderDate, orderStatus, totalWithTax } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const { translate } = useLocales();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={orderStatus} color={createAvatar(orderStatus).color} sx={{ mr: 2 }}>
          {createAvatar(orderStatus).name}
        </Avatar>

        <Stack>
          <Typography variant="subtitle2" noWrap>
            {translate(`orders.${orderStatus}`)}
          </Typography>

          <Link
            noWrap
            variant="body2"
            onClick={onViewRow}
            sx={{ color: 'text.disabled', cursor: 'pointer' }}
          >
            {`No-${orderNumber}`}
          </Link>
        </Stack>
      </TableCell>

      <TableCell align="left">{fDateTimeSuffix(orderDate)}</TableCell>

      <TableCell align="center">
        {totalWithTax} {translate('orders.SR')}
      </TableCell>

      {/* <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {total} SR
      </TableCell> */}

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            ((orderStatus === 'PENDING' && 'info') ||
              (orderStatus === 'DONE' && 'success') ||
              (orderStatus === 'CANCELLED' && 'error')) as any
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {translate(`orders.${orderStatus}`)}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {/* <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem> */}

              <MenuItem
                onClick={() => {
                  onViewRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
                {translate('orders.view')}
              </MenuItem>

              {/* <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem> */}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
