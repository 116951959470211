import sum from 'lodash/sum';
import { useCallback, useEffect } from 'react';
// form
import { useFieldArray, useFormContext } from 'react-hook-form';
// @mui
import { Box, Button, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { PurchaseOrderItem } from 'src/@types/purchaseOrder';
import Iconify from 'src/components/Iconify';
import RHFSelect from 'src/components/hook-form/RHFSelect';
import RHFTextField from 'src/components/hook-form/RHFTextField';
import useLocales from 'src/hooks/useLocales';
import { getProducts } from 'src/redux/slices/product';
import { useDispatch, useSelector } from 'src/redux/store';
// utils

export default function PurchaseNewEditDetails() {
  const { control, setValue, watch, resetField } = useFormContext();

  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.product);

  // filter products that can be stored
  const filteredProducts = items.filter((product) => product.item.canBeStored === true);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const values = watch();
  const { translate } = useLocales();
  const totalOnRow = values.items?.map((item: PurchaseOrderItem) => item.qty * item.price);

  const totalPrice = sum(totalOnRow) + values.taxes;

  useEffect(() => {
    setValue('totalPrice', totalPrice);
  }, [setValue, totalPrice]);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const handleAdd = () => {
    append({
      id: 0,
      name: '',
      qty: 1,
      price: 0,
      total: 0,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleClearService = useCallback(
    (index: number) => {
      resetField(`items[${index}].createdQty`);
    },
    [resetField]
  );

  const handleSelectService = useCallback(
    (index: number, option: string) => {
      const item = items.find((product) => product.id === option);
      setValue(`items[${index}].itemId`, item?.item.id);
    },
    [items, setValue]
  );

  const handleChangeQuantity = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      setValue(`items[${index}].createdQty`, Number(event.target.value));
    },
    [setValue]
  );

  // const handleChangePrice = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
  //     setValue(`items[${index}].price`, Number(event.target.value));
  //     setValue(
  //       `items[${index}].total`,
  //       values.items.map((item: PurchaseOrderItem) => item.qty * item.price)[index]
  //     );
  //   },
  //   [setValue, values.items]
  // );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        {translate('inventory.purchase_orders.actions.Details')}
      </Typography>

      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
              {/* <RHFTextField
                size="small"
                name={`items[${index}].title`}
                label="Title"
                InputLabelProps={{ shrink: true }}
              />

              <RHFTextField
                size="small"
                name={`items[${index}].description`}
                label="Description"
                InputLabelProps={{ shrink: true }}
              /> */}

              <RHFSelect
                name={`items[${index}].name`}
                size="small"
                label={translate('inventory.purchase_orders.actions.product')}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: 300 } }}
              >
                <MenuItem
                  value=""
                  onClick={() => handleClearService(index)}
                  sx={{
                    mx: 1,
                    borderRadius: 0.75,
                    typography: 'body2',
                    fontStyle: 'italic',
                    color: 'text.secondary',
                  }}
                >
                  None
                </MenuItem>

                <Divider />

                {filteredProducts.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.item.name}
                    onClick={() => handleSelectService(index, option.id)}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.item.name}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].createdQty`}
                label={translate('inventory.purchase_orders.actions.quantity')}
                placeholder="0"
                onChange={(event) => handleChangeQuantity(event, index)}
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 120 } }}
              />

              {/* <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].price`}
                label={translate('inventory.purchase_orders.actions.Price')}
                placeholder="0"
                onChange={(event) => handleChangePrice(event, index)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 120 } }}
              /> */}

              {/* <RHFTextField
                disabled
                size="small"
                name={`items[${index}].total`}
                label={translate('inventory.purchase_orders.total')}
                placeholder="0"
                value={fNumber(totalOnRow[index])}
                InputProps={{
                  startAdornment: <InputAdornment position="start">SR</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 120 } }}
              /> */}
            </Stack>

            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="eva:trash-2-outline" />}
              onClick={() => handleRemove(index)}
            >
              {translate('inventory.purchase_orders.actions.remove')}
            </Button>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={2}
        direction={{ xs: 'column-reverse', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <Button
          size="small"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          {translate('inventory.purchase_orders.actions.add_details')}
        </Button>

        <Stack
          spacing={2}
          justifyContent="flex-end"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1 }}
        >
          {/* <RHFTextField
            size="small"
            label="Discount"
            name="discount"
            onChange={(event) => setValue('discount', Number(event.target.value))}
            sx={{ maxWidth: { md: 200 } }}
          /> */}

          {/* <RHFTextField
            size="small"
            label={translate('inventory.purchase_orders.actions.tax_amount')}
            name="taxes"
            onChange={(event) => setValue('taxes', Number(event.target.value))}
            sx={{ maxWidth: { md: 200 } }}
          /> */}
        </Stack>
      </Stack>

      {/* <Stack spacing={2} sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="flex-end">
          <Typography>{translate('inventory.purchase_orders.actions.subtotal')}</Typography>
          <Typography sx={{ textAlign: 'right', width: 120 }}>
            {fCurrency(sum(totalOnRow))}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Typography>{translate('inventory.purchase_orders.actions.tax')}</Typography>
          <Typography sx={{ textAlign: 'right', width: 120 }}>
            {values.taxes ? fCurrency(values.taxes) : '-'}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <Typography variant="h6">
            {translate('inventory.purchase_orders.actions.total_price')}
          </Typography>
          <Typography variant="h6" sx={{ textAlign: 'right', width: 120 }}>
            {fCurrency(totalPrice)}
          </Typography>
        </Stack>
      </Stack> */}
      {/* <Stack>
        <RHFCheckbox
          name="updateCost"
          label={translate('inventory.purchase_orders.actions.update_cost_msg')}
          checked={values.updateCost}
        />
      </Stack> */}
    </Box>
  );
}
