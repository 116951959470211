import { paramCase } from 'change-case';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
// sections
import useLocales from 'src/hooks/useLocales';
import { getSuppliers } from 'src/redux/slices/supplier';
import SupplierNewEditForm from './SupplierNewEditForm';

// ----------------------------------------------------------------------

export default function SupplierEdit() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { id = '' } = useParams();

  const { translate } = useLocales();

  const { suppliers } = useSelector((state) => state.supplier);

  const currentSupplier = suppliers.find((supplier) => paramCase(supplier.id.toString()) === id);

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  return (
    <Page title="Suppliers: Edit supplier">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.suppliers.edit_supplier')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: currentSupplier?.name as string },
          ]}
        />

        <SupplierNewEditForm isEdit currentSupplier={currentSupplier} />
      </Container>
    </Page>
  );
}
