import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader, CardProps, TextField } from '@mui/material';
// components
import { BaseOptionChart } from '../../../../components/chart';
import useLocales from '../../../../hooks/useLocales';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chartLabels: string[];
  chartData: {
    label: string;
    data: {
      name: string;
      data: number[];
    }[];
  }[];
}

export default function AppAreaInstalled({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}: Props) {
  const { translate } = useLocales();

  const [seriesData, setSeriesData] = useState(chartData.map((item) => item.label)[0]);
  const handleChangeSeriesData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeriesData(event.target.value);
  };

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: chartLabels,
    },
  });

  useEffect(() => {
    setSeriesData(chartData.map((item) => item.label)[0]);
  }, [chartData]);

  return (
    <Card {...other}>
      <CardHeader
        title={translate(`reports.general.${title}`)}
        subheader={subheader}
        action={
          <TextField
            select
            fullWidth
            value={seriesData}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': {
                pl: 1,
                py: 0.5,
                pr: '24px !important',
                typography: 'subtitle2',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: 0.75,
                bgcolor: 'background.neutral',
              },
              '& .MuiNativeSelect-icon': {
                top: 4,
                right: 0,
                width: 20,
                height: 20,
              },
            }}
          >
            {chartData.map((option) => (
              <option key={option.label} value={option.label}>
                {option.label}
              </option>
            ))}
          </TextField>
        }
      />

      {chartData.map((item) => (
        <Box key={item.label} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.label === seriesData && (
            <ReactApexChart type="line" series={item.data} options={chartOptions} height={364} />
          )}
        </Box>
      ))}
    </Card>
  );
}
