import { createSlice } from '@reduxjs/toolkit';
// utils
import { Report, ReportState } from '../../@types/report';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: ReportState = {
  isLoading: false,
  error: null,
  reports: null,
  report: null,
  companyReports: {
    salesSummaryReport: null,
    paymentMethodsReport: null,
    itemSalesReport: null,
    categorySalesReport: null,
  },
  branchReports: {
    salesSummaryReport: null,
    paymentMethodsReport: null,
    itemSalesReport: null,
    categorySalesReport: null,
  },
  pointOfSaleReports: {
    salesSummaryReport: null,
    paymentMethodsReport: null,
    itemSalesReport: null,
    categorySalesReport: null,
  },
  sessionReports: {
    paymentMethodsReport: null,
    itemSalesReport: null,
    categorySalesReport: null,
    pointOfSaleReport: null,
  },
  sortBy: null,
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORTs
    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.reports = action.payload;
    },

    // GET REPORT
    getReportSuccess(state, action) {
      state.isLoading = false;
      state.report = action.payload;
    },

    // GET COMPANY REPORTS
    getCompanyReportsSuccess(state, action) {
      state.isLoading = false;
      state.companyReports = action.payload;
    },

    // GET BRANCH REPORTS
    getBranchReportsSuccess(state, action) {
      state.isLoading = false;
      state.branchReports = action.payload;
    },

    // GET POINT OF SALE REPORTS
    getPointOfSaleReportsSuccess(state, action) {
      state.isLoading = false;
      state.pointOfSaleReports = action.payload;
    },

    // GET SESSION REPORTS
    getSessionReportsSuccess(state, action) {
      state.isLoading = false;
      state.sessionReports = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// // Actions
export const {
  startLoading,
  hasError,
  getReportsSuccess,
  getReportSuccess,
  getCompanyReportsSuccess,
  getBranchReportsSuccess,
  getPointOfSaleReportsSuccess,
  getSessionReportsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getReports() {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { reports: Report } } = await axios.post(
        `/reports/orders/${companyId}`,
        {}
      );
      dispatch(getReportsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getAllReports() {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(startLoading());
    try {
      const response: { data: { reports: Report } } = await axios.get(
        `/reports/sessions/${defaultBranchId}`,
        {}
      );
      dispatch(getReportsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getReport() {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { report: Report } } = await axios.get('/products/item', {});
      dispatch(getReportSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanyReports(from: Date | null, to: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/reports/summary/by-company/${companyId}`, {
        from,
        to,
      });
      dispatch(getCompanyReportsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export async function getBranchReports(
  branchId: number | null,
  from: Date | null,
  to: Date | null
) {
  dispatch(startLoading());
  try {
    const response = await axios.post(`/reports/summary/by-branch/${branchId}`, {
      from,
      to,
    });
    dispatch(getBranchReportsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
}

// ----------------------------------------------------------------------

export async function getPointOfSaleReports(
  posId: number | null,
  from: Date | null,
  to: Date | null
) {
  dispatch(startLoading());
  try {
    const response = await axios.post(`/reports/summary/by-point-of-sale/${posId}`, {
      from,
      to,
    });
    dispatch(getPointOfSaleReportsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
}
