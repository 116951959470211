import { useState } from 'react';
// @mui
import { Checkbox, MenuItem, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../../../utils/formatNumber';
// @types
// components
import useLocales from 'src/hooks/useLocales';
import Iconify from '../../../../../components/Iconify';
import Label from '../../../../../components/Label';
import { TableMoreMenu } from '../../../../../components/table';

// ----------------------------------------------------------------------

type Props = {
  id: string;
  row: any;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function PurchaseOrderTableRow({
  id,
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: Props) {
  const theme = useTheme();

  const { number, term, total, status, supplier } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const { translate } = useLocales();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected} id={id}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      {/* <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={invoiceTo.name} color={createAvatar(invoiceTo.name).color} sx={{ mr: 2 }}>
          {createAvatar(invoiceTo.name).name}
        </Avatar>

        <Stack>
          <Typography variant="subtitle2" noWrap>
            {invoiceTo.name}
          </Typography>

          <Link
            noWrap
            variant="body2"
            onClick={onViewRow}
            sx={{ color: 'text.disabled', cursor: 'pointer' }}
          >
            {`INV-${invoiceNumber}`}
          </Link>
        </Stack>
      </TableCell> */}

      <TableCell align="left">{number}</TableCell>

      <TableCell align="left">{translate(`inventory.purchase_orders.terms.${term}`)}</TableCell>

      {/* <TableCell align="left">{fDate(expiredDate)}</TableCell> */}

      <TableCell align="center">{fCurrency(total)}</TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {supplier?.name}
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (status === 'DONE' && 'success') ||
            (status === 'PENDING' && 'warning') ||
            (status === 'PENDING' && 'error') ||
            'default'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {translate(`inventory.purchase_orders.status.${status}`)}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {/* <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem> */}

              <MenuItem
                onClick={() => {
                  onViewRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
                View
              </MenuItem>

              {/* <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem> */}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
