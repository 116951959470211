import { paramCase } from 'change-case';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
// sections
import useLocales from 'src/hooks/useLocales';
import { getProductIngredients } from 'src/redux/slices/productIngredient';
import AddonNewEditForm from './AddonNewEditForm';

// ----------------------------------------------------------------------

export default function AddonEdit() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { id = '' } = useParams();

  const { translate } = useLocales();

  const { addons } = useSelector((state) => state.addon);

  const currentAddon = addons.find((addon) => paramCase(addon.id.toString()) === id);

  useEffect(() => {
    dispatch(getProductIngredients(parseInt(id)));
  }, [dispatch, id]);

  return (
    <Page title="Inventory: Edit Addon">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.products.edit_addons')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: currentAddon?.name as string },
          ]}
        />

        <AddonNewEditForm isEdit currentAddon={currentAddon} id={id} />
      </Container>
    </Page>
  );
}
