import { createMongoAbility } from '@casl/ability';
import { initialAbility } from './initialAbility';

const getExistingAbility = () => {
  const ability = localStorage.getItem('ability');
  return ability ? JSON.parse(ability) : null;
};

const ability = createMongoAbility(getExistingAbility() || initialAbility);

export default ability;
