import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// redux
import { useDispatch } from '../../../../redux/store';
// @mui
import { LoadingButton } from '@mui/lab';
// hooks
import useLocales from '../../../../hooks/useLocales';

import { Box, Card, Grid, Stack, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// @types
// components
import { Supplier } from 'src/@types/supplier';
import { getDevices } from 'src/redux/slices/device';
import { addSupplier, updateSupplier } from 'src/redux/slices/supplier';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';

interface FormValuesProps extends Omit<Supplier, 'supplier'> {
  name: string;
}

type Props = {
  isEdit?: boolean;
  currentSupplier?: Supplier;
};

export default function SupplierNewEditForm({ isEdit, currentSupplier }: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  // use dispatch to get suppliers from redux
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const NewDeviceSchema = Yup.object().shape({
    name: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentSupplier?.name || '',
      bankName: currentSupplier?.bankName || '',
      bankAccountNumber: currentSupplier?.bankAccountNumber || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSupplier]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewDeviceSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    dispatch(getDevices());
  }, [dispatch]);

  useEffect(() => {
    if (isEdit && currentSupplier) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentSupplier]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // convert data to product type
      const supplier: Supplier = {
        ...data,
      };

      if (isEdit && currentSupplier) {
        dispatch(updateSupplier(currentSupplier.id, supplier));
      } else {
        await dispatch(addSupplier(supplier));
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(PATH_DASHBOARD.inventory.suppliers);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} md={8}>
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}
          >
            <RHFTextField
              name="name"
              type="string"
              label={translate('inventory.suppliers.supplier_name')}
              defaultValue={currentSupplier?.name}
            />

            <RHFTextField
              name="address"
              type="string"
              label={translate('inventory.suppliers.supplier_address')}
              defaultValue={currentSupplier?.address}
            />

            <RHFTextField
              name="city"
              type="string"
              label={translate('inventory.suppliers.supplier_city')}
              defaultValue={currentSupplier?.city}
            />

            <RHFTextField
              name="state"
              type="string"
              label={translate('inventory.suppliers.supplier_state')}
              defaultValue={currentSupplier?.state}
            />

            <RHFTextField
              name="zip"
              type="string"
              label={translate('inventory.suppliers.supplier_zip')}
              defaultValue={currentSupplier?.zip}
            />

            <RHFTextField
              name="phone"
              type="string"
              label={translate('inventory.suppliers.supplier_phone')}
              defaultValue={currentSupplier?.phone}
            />

            <RHFTextField
              name="email"
              type="string"
              label={translate('inventory.suppliers.supplier_email')}
              defaultValue={currentSupplier?.email}
            />

            <RHFTextField
              name="website"
              type="string"
              label={translate('inventory.suppliers.supplier_website')}
              defaultValue={currentSupplier?.website}
            />

            <RHFTextField
              name="notes"
              type="string"
              label={translate('inventory.suppliers.supplier_notes')}
              defaultValue={currentSupplier?.notes}
            />
          </Box>

          {/* add box to add bank account info for supplier */}
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              mt: 3,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}
          >
            <Card
              sx={{
                p: 5,
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 3 }}>
                {translate('inventory.suppliers.supplier_bank_account_info')}
              </Typography>
              <RHFTextField
                sx={{ mb: 3 }}
                name="bankName"
                type="string"
                label={translate('inventory.suppliers.supplier_bank_name')}
                defaultValue={currentSupplier?.bankName}
              />
              <RHFTextField
                name="bankAccountNumber"
                type="string"
                label={translate('inventory.suppliers.supplier_bank_account_number')}
                defaultValue={currentSupplier?.bankAccountNumber}
              />
            </Card>
          </Box>
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit
                ? translate('inventory.suppliers.create_supplier')
                : translate('inventory.products.save_changes')}
            </LoadingButton>
          </Stack>
        </Card>
      </Grid>
    </FormProvider>
  );
}
