import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// @mui
import { LoadingButton } from '@mui/lab';
// hooks
import useLocales from '../../../../hooks/useLocales';

import { Card, Grid, MenuItem, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// @types
// components
import { VariantIngredient } from 'src/@types/variantIngredient';
import { getProducts } from 'src/redux/slices/product';
import {
  createVariantIngredient,
  updateVariantIngredient,
} from 'src/redux/slices/variantIngredient';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';

interface FormValuesProps extends Omit<VariantIngredient, 'variantaction'> {
  qty: number;
}

type Props = {
  isEdit?: boolean;
  currentVariantIngredient?: VariantIngredient;
  id: string;
};

export default function VariantActionNewEditForm({ isEdit, currentVariantIngredient, id }: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  // use dispatch to get suppliers from redux
  const dispatch = useDispatch();

  const { items, isLoading } = useSelector((state) => state.product);

  const { enqueueSnackbar } = useSnackbar();

  const NewPIngredientSchema = Yup.object().shape({
    qty: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      qty: currentVariantIngredient?.qty || 0,
      itemId: currentVariantIngredient?.itemId || null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentVariantIngredient]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPIngredientSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const productsWithTypeItem = items.filter(
    (product) => product.item.type === 'ITEM' && product.item.isActive === true
  );

  useEffect(() => {
    if (isEdit && currentVariantIngredient) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentVariantIngredient]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // convert data to product type
      const variantIngredient: VariantIngredient = {
        ...data,
        varianceId: parseInt(id),
      };

      if (isEdit) {
        updateVariantIngredient(data, parseInt(id));
      } else {
        console.log('variantIngredient', variantIngredient);

        const res = await dispatch(createVariantIngredient(variantIngredient));
        console.log(res);
      }

      console.log(data);
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(PATH_DASHBOARD.inventory.root);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mt={2}>
                <RHFSelect
                  name="itemId"
                  label={translate('inventory.products.choose_ingredient')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: false,
                    sx: { textTransform: 'capitalize' },
                  }}
                  disabled={isEdit}
                >
                  {!isLoading &&
                    productsWithTypeItem.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.item.name}
                      </MenuItem>
                    ))}
                </RHFSelect>
                <RHFTextField
                  name="qty"
                  type="string"
                  label={translate('inventory.products.qty_to_reduce')}
                  defaultValue={currentVariantIngredient?.qty}
                />
              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit
                ? translate('inventory.products.add_ingredient')
                : translate('inventory.products.save_changes')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
