// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
//useLocales

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },

  // REPORTS
  // ----------------------------------------------------------------------

  {
    subheader: 'reports',
    items: [
      {
        title: 'inventory',
        path: PATH_DASHBOARD.reports.warehouse.idleStock,
        icon: ICONS.invoice,
        
        meta: {
          action: 'read',
          resource: 'reports',
        },
        children: [
          {
            title: 'idle_stock',
            path: PATH_DASHBOARD.reports.warehouse.idleStock,
            caption: 'captions.idle_stock_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'inventory_action',
            path: PATH_DASHBOARD.reports.warehouse.inventoriesActions,
            caption: 'captions.inventory_action_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'stock_value',
            path: PATH_DASHBOARD.reports.warehouse.stockValue,
            caption: 'captions.inventory_value_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          // { title: 'available_quantity', path: PATH_DASHBOARD.orders.eotd },
          // customer
        ],
      },
      {
        title: 'tax',
        path: PATH_DASHBOARD.reports.tax.taxReport,
        icon: ICONS.invoice,
        meta: {
          action: 'read',
          resource: 'reports',
        },
        children: [
          // { title: 'sales_history', path: PATH_DASHBOARD.reports.root },
          {
            title: 'tax_report',
            path: PATH_DASHBOARD.reports.tax.taxReport,
            caption: 'captions.tax_report_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'branches_tax_report',
            path: PATH_DASHBOARD.reports.tax.branchesTaxReport,
            caption: 'captions.branch_tax_report_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          // customer
        ],
      },
      {
        title: 'sales',
        path: PATH_DASHBOARD.reports.sales.customersPayments,
        icon: ICONS.invoice,
        meta: {
          action: 'read',
          resource: 'reports',
        },
        children: [
          {
            title: 'customers_payments',
            path: PATH_DASHBOARD.reports.sales.customersPayments,
            caption: 'captions.customer_payments_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'sales_report_by_branch',
            path: PATH_DASHBOARD.reports.sales.salesReportByBranch,
            caption: 'captions.sales_report_by_branch_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'sales_report_by_category',
            path: PATH_DASHBOARD.reports.sales.salesReportByCategory,
            caption: 'captions.sales_report_by_category_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'sales_report_by_user',
            path: PATH_DASHBOARD.reports.sales.salesReportByUser,
            caption: 'captions.sales_report_by_user_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'sales_report_by_payment_method',
            path: PATH_DASHBOARD.reports.sales.salesReportByPaymentMethod,
            caption: 'captions.sales_report_by_payment_method_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'sales_report_by_customer',
            path: PATH_DASHBOARD.reports.sales.salesReportByCustomer,
            caption: 'captions.sales_report_by_customer_caption', 
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          // { title: 'sales_report_by_order', path: PATH_DASHBOARD.orders.eotd },
          // { title: 'customer_transaction', path: PATH_DASHBOARD.orders.eotd },
          // { title: 'branch_transaction', path: PATH_DASHBOARD.orders.eotd },
          // customer
        ],
      },
      {
        title: 'warehouse_control',
        path: PATH_DASHBOARD.reports.warehouseControl.supplierPayment,
        icon: ICONS.invoice,
        meta: {
          action: 'read',
          resource: 'reports',
        },
        children: [
          // { title: 'sales_history', path: PATH_DASHBOARD.reports.root },
          {
            title: 'supplier_payment',
            path: PATH_DASHBOARD.reports.warehouseControl.supplierPayment,
            caption: 'captions.supplier_payment_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          {
            title: 'supplier_due',
            path: PATH_DASHBOARD.reports.warehouseControl.supplierDebits,
            caption: 'captions.supplier_due_caption',
            meta: {
              action: 'read',
              resource: 'reports',
            },
          },
          // { title: 'purchase_order_details', path: PATH_DASHBOARD.orders.eotd },
          // { title: 'branch_stocktaking', path: PATH_DASHBOARD.orders.eotd },
          // customer
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'company',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.kanban,
        meta: {
          action: 'read',
          resource: 'info',
        },
        children: [
          {
            title: 'branches',
            path: PATH_DASHBOARD.company.branches.root,
            meta: {
              action: 'read',
              resource: 'branch',
            },
          },
          {
            title: 'warehouses',
            path: PATH_DASHBOARD.company.warehouses.root,
            meta: {
              action: 'read',
              resource: 'inventories',
            },
          },
          {
            title: 'points_of_sale',
            path: PATH_DASHBOARD.company.pointsOfSale.root,
            meta: {
              action: 'read',
              resource: 'point',
            },
          },
          {
            title: 'taxes',
            path: PATH_DASHBOARD.company.taxes.root,
            meta: {
              action: 'read',
              resource: 'tax',
            },
          },
          {
            title: 'purchase_taxes',
            path: PATH_DASHBOARD.company.purchaseTaxes.root,
            meta: {
              action: 'read',
              resource: 'tax',
            },
          },
        ],
      },

      {
        title: 'users_management',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        meta: {
          action: 'read',
          resource: 'membership',
        },
        children: [
          {
            title: 'users',
            path: PATH_DASHBOARD.usersManagement.users.list,
            meta: {
              action: 'read',
              resource: 'membership',
            },
          },
          {
            title: 'roles',
            path: PATH_DASHBOARD.usersManagement.role.list,
            meta: {
              action: 'read',
              resource: 'role',
            },
          },
        ],
      },
      {
        title: 'orders',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.ecommerce,
        meta: {
          action: 'read',
          resource: 'orders',
        },
        children: [
          {
            title: 'orders_history',
            path: PATH_DASHBOARD.orders.list,
            meta: {
              action: 'read',
              resource: 'orders',
            },
          },
          {
            title: 'canceled_orders',
            path: PATH_DASHBOARD.orders.canceled,
            meta: {
              action: 'read',
              resource: 'orders',
            },
          },
          // customer
          {
            title: 'customers',
            path: PATH_DASHBOARD.orders.customer.list,
            meta: {
              action: 'read',
              resource: 'customers',
            },
          },
          // discount
          {
            title: 'offers',
            path: PATH_DASHBOARD.orders.discount.list,
            meta: {
              action: 'read',
              resource: 'discounts',
            },
          },
          {
            title: 'menu',
            path: PATH_DASHBOARD.orders.menu.list,
            meta: { action: 'read', resource: 'menu' },
          },
          {
            title: 'cancel_reasons',
            path: PATH_DASHBOARD.orders.cancelReason.root,
            meta: { action: 'read', resource: 'cancel' },
          },
          // eod
          {
            title: 'eotd',
            path: PATH_DASHBOARD.orders.eotd,
            meta: { action: 'read', resource: 'reports' },
          },
          // order transactions
          {
            title: 'order_transactions',
            path: PATH_DASHBOARD.orders.transactions.root,
            meta: { action: 'read', resource: 'transaction' },
          },
        ],
      },

      // ACCOUNTING
      // ----------------------------------------------------------------------
      {
        title: 'accounting',
        path: PATH_DASHBOARD.invoicing.root,
        icon: ICONS.banking,
        meta: {
          action: 'read',
          resource: 'sale',
        },
        children: [
          {
            title: 'invoicing',
            path: PATH_DASHBOARD.invoicing.root,
            meta: {
              action: 'read',
              resource: 'sale',
            },
          },
          {
            title: 'creditNotes',
            path: PATH_DASHBOARD.creditNotes.root,
            meta: {
              action: 'read',
              resource: 'sale',
            },
          },
        ],
      },

      // INVENTORY
      {
        title: 'inventory',
        path: PATH_DASHBOARD.inventory.root,
        icon: ICONS.menuItem,
        meta: {
          action: 'read',
          resource: 'item',
        },
        children: [
          {
            title: 'products',
            path: PATH_DASHBOARD.inventory.warehouse,
            meta: {
              action: 'read',
              resource: 'inventory',
            },
          },
          {
            title: 'inventory_items',
            path: PATH_DASHBOARD.inventory.inventoryItems,
            meta: {
              action: 'read',
              resource: 'inventory',
            },
          },
          {
            title: 'categories',
            path: PATH_DASHBOARD.inventory.categories,
            meta: {
              action: 'read',
              resource: 'category',
            },
          },
          {
            title: 'suppliers',
            path: PATH_DASHBOARD.inventory.suppliers,
            meta: {
              action: 'read',
              resource: 'supplier',
            },
          },
          {
            title: 'purchase_orders',
            path: PATH_DASHBOARD.inventory.purchaseOrders,
            meta: {
              action: 'read',
              resource: 'purchases',
            },
          },
          {
            title: 'devices',
            path: PATH_DASHBOARD.inventory.devices,
            meta: {
              action: 'read',
              resource: 'printers',
            },
          },
          {
            title: 'units',
            path: PATH_DASHBOARD.inventory.units,
            meta: {
              action: 'read',
              resource: 'units',
            },
          },
          {
            title: 'order_types',
            path: PATH_DASHBOARD.inventory.orderTypes,
            meta: {
              action: 'read',
              resource: 'order',
            },
          },
          {
            title: 'transfers_requests',
            path: PATH_DASHBOARD.inventory.transfersRequests,
            meta: {
              action: 'read',
              resource: 'transfer',
            },
          },
          {
            title: 'company_stocktaking',
            path: PATH_DASHBOARD.inventory.stockTaking,
            meta: {
              action: 'read',
              resource: 'orders',
            },
          },
        ],
      },
    ],
  },
];

export default navConfig;
