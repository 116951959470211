import { createSlice } from '@reduxjs/toolkit';
import { PurchaseOrder, PurchaseOrderState } from 'src/@types/purchaseOrder';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: PurchaseOrderState = {
  isLoading: false,
  error: null,
  purchaseOrders: [],
  purchaseOrder: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Purchase Orders Success
    getPurchaseOrdersSuccess(state, action) {
      state.isLoading = false;
      state.purchaseOrders = action.payload;
    },

    // GET Purchase Order Success
    getPurchaseOrderSuccess(state, action) {
      state.isLoading = false;
      state.purchaseOrder = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getPurchaseOrders() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { purchaseOrders: any[] } } = await axios.get(
        `/purchases/${companyId}`
      );
      dispatch(slice.actions.getPurchaseOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPurchaseOrder(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { purchaseOrder: PurchaseOrder } } = await axios.get('/purchases', {
        params: { name },
      });
      dispatch(slice.actions.getPurchaseOrderSuccess(response.data.purchaseOrder));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export add purchase order
export function addPurchaseOrder(purchaseOrder: any) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { purchaseOrder: PurchaseOrder } } = await axios.post(
        `/purchases/${companyId}`,
        purchaseOrder
      );
      dispatch(slice.actions.getPurchaseOrdersSuccess(response.data.purchaseOrder));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
