import { createSlice } from '@reduxjs/toolkit';
// utils
import { Device, DeviceModel, DeviceState } from '../../@types/device';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: DeviceState = {
  isLoading: false,
  error: null,
  devices: [],
  device: null,
  deviceModels: [],
  sortBy: null,
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Devices
    getDevicesSuccess(state, action) {
      state.isLoading = false;
      state.devices = action.payload;
    },

    // GET Device
    getDeviceSuccess(state, action) {
      state.isLoading = false;
      state.device = action.payload;
    },

    //GET DEVICE MODELS
    getDeviceModelsSuccess(state, action) {
      state.isLoading = false;
      state.deviceModels = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getDevices() {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { devices: Device[] } } = await axios.get(
        `/printers/${defaultBranchId}`
      );
      dispatch(slice.actions.getDevicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// get device by branch id
export function getDevicesByBranchId(branchId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { devices: Device[] } } = await axios.get(`/printers/${branchId}`);
      dispatch(slice.actions.getDevicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// get Device Models

export function getDeviceModels() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { deviceModels: DeviceModel[] } } = await axios.get(
        `/printers/models`
      );
      dispatch(slice.actions.getDeviceModelsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDevice(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { device: Device } } = await axios.get('/printers', {
        params: { name },
      });
      dispatch(slice.actions.getDeviceSuccess(response.data.device));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export add device
export function addDevice(device: Device) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { device: Device } } = await axios.post(
        `/printers/${defaultBranchId}`,
        device
      );
      dispatch(slice.actions.getDeviceSuccess(response.data.device));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export update device
export function updateDevice(id: string, device: Device) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { device: Device } } = await axios.patch(
        `/printers/${defaultBranchId}/${id}`,
        device
      );
      dispatch(slice.actions.getDeviceSuccess(response.data.device));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete device
export function deleteDevice(id: string) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { device: Device } } = await axios.delete(
        `/printers/${defaultBranchId}/${id}`
      );
      dispatch(slice.actions.getDeviceSuccess(response.data.device));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
