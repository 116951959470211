import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
  Tooltip,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
import useTable, { emptyRows, getComparator } from '../../../../hooks/useTable';
import useTabs from '../../../../hooks/useTabs';
// components
import { Can } from '@casl/react';
import { TransferRequest } from 'src/@types/transferRequest';
import useLocales from 'src/hooks/useLocales';
import { getTransferRequests } from 'src/redux/slices/transferRequest';
import { getPurchaseInventories } from 'src/redux/slices/warehouse';
import { useDispatch, useSelector } from 'src/redux/store';
import ability from 'src/utils/ability';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from '../../../../components/table';
import { TransfersTableToolbar } from './list';
import TransfersTableRow from './list/TransfersTableRow';

export default function TransfersRequestsList() {
  // const theme = useTheme();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  // use locales
  const { translate } = useLocales();

  const TABLE_HEAD = [
    {
      id: 'createDate',
      label: translate('inventory.transfers_requests.transfer_request_no'),
      align: 'left',
    },
    {
      id: 'fromInventory',
      label: translate('inventory.transfers_requests.from_inventory'),
      align: 'left',
    },
    {
      id: 'toInventory',
      label: translate('inventory.transfers_requests.to_inventory'),
      align: 'center',
      width: 140,
    },
    {
      id: 'NoOfItems',
      label: translate('inventory.transfers_requests.status.title'),
      align: 'left',
    },
    { id: 'status', label: translate('inventory.transfers_requests.to_inventory'), align: 'left' },
    { id: '' },
  ];

  const dispatch = useDispatch();

  const { purchaseInventories } = useSelector((state) => state.warehouses);
  const { transferRequests } = useSelector((state) => state.transferRequests);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [tableData, setTableData] = useState<TransferRequest[]>([]);

  const [filterName, setFilterName] = useState('');

  const [filterService, setFilterService] = useState('all');

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterService(event.target.value);
  };

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id || ''));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.invoice.edit(id));
  };

  const handleViewRow = (id: string) => {
    navigate(PATH_DASHBOARD.inventory.viewTransferRequest(id));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterService,
    filterStatus,
    filterStartDate,
    filterEndDate,
  });

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterService) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  const denseHeight = dense ? 56 : 76;

  // const getLengthByStatus = (status: string) =>
  //   tableData.filter((item) => item.status.toString() === status).length;

  // const getTotalPriceByStatus = (status: string) =>
  //   sumBy(
  //     tableData.filter((item) => item.status.toString() === status),
  //     'totalPrice'
  //   );

  // const getPercentByStatus = (status: string) =>
  //   (getLengthByStatus(status) / tableData.length) * 100;

  const TABS = [
    { value: 'all', label: 'All', color: 'info', count: tableData.length },
    { value: 'draft', label: 'Draft', color: 'success', count: 0 },
    { value: 'pending', label: 'Pending', color: 'success', count: 0 },
    { value: 'accepted', label: 'Accepted', color: 'success', count: 0 },
    { value: 'rejected', label: 'Rejected', color: 'error', count: 0 },
  ] as const;

  useEffect(() => {
    dispatch(getTransferRequests());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPurchaseInventories());
  }, [dispatch]);

  useEffect(() => {
    setTableData(transferRequests);
  }, [transferRequests]);

  return (
    <Page title={translate('nav.transfers_requests')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('nav.transfers_requests')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: translate('nav.transfers_requests') },
          ]}
          action={
            <Can I="write" a="transfer" ability={ability}>
              {() => (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={PATH_DASHBOARD.inventory.createTransferRequest}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  {translate('inventory.transfers_requests.create_transfer_request')}
                </Button>
              )}
            </Can>
          }
        />
        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                icon={<Label color={tab.color}> {tab.count} </Label>}
                label={translate(`inventory.transfers_requests.${tab.label}`)}
              />
            ))}
          </Tabs>

          <Divider />

          <TransfersTableToolbar
            filterName={filterName}
            filterService={filterService}
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
            warehouses={purchaseInventories || []}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                  actions={
                    <Stack spacing={1} direction="row">
                      <Tooltip title="Sent">
                        <IconButton color="primary">
                          <Iconify icon={'ic:round-send'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Download">
                        <IconButton color="primary">
                          <Iconify icon={'eva:download-outline'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Print">
                        <IconButton color="primary">
                          <Iconify icon={'eva:printer-fill'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TransfersTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id || '')}
                        onSelectRow={() => onSelectRow(row.id || '')}
                        onViewRow={() => handleViewRow(row.id || '')}
                        onEditRow={() => handleEditRow(row.id || '')}
                        onDeleteRow={() => handleDeleteRow(row.id || '')}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}: {
  tableData: TransferRequest[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterService: string;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.id.toString().toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.fromInventory.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.toInventory.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item: Record<string, any>) => item.status === filterStatus);
  }

  //   if (filterService !== 'all') {
  //     tableData = tableData.filter((item) => item.items.some((c) => c.service === filterService));
  //   }

  // if (filterStartDate && filterEndDate) {
  //   tableData = tableData.filter(
  //     (item: Record<string, any>) =>
  //       item.createdAt.getTime() >= filterStartDate.getTime() &&
  //       item.createdAt.getTime() <= filterEndDate.getTime()
  //   );
  // }

  return tableData;
}
