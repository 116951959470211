import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// redux
import { useDispatch, useSelector } from '../../../../../redux/store';
// @mui
import { LoadingButton } from '@mui/lab';
// hooks
import useLocales from '../../../../../hooks/useLocales';

import { Card, Grid, MenuItem, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
// @types
// components
import { UnitOfMeasure } from 'src/@types/uom';
import { UnitOfMeasureConversion } from 'src/@types/uomConversion';
import { getUoms } from 'src/redux/slices/uom';
import { addConversion, updateConversion } from 'src/redux/slices/uomConversion';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../../components/hook-form';

interface FormValuesProps extends Omit<UnitOfMeasureConversion, 'conversion'> {
  qty: number;
  unitOfMeasurementFromId: number | null;
  unitOfMeasurementToId: number | null;
}

type Props = {
  isEdit?: boolean;
  currentUnit?: UnitOfMeasure | null;
  currentConversion?: UnitOfMeasureConversion | null;
};

export default function ConversionNewEditForm({ isEdit, currentUnit, currentConversion }: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  // use dispatch to get suppliers from redux
  const dispatch = useDispatch();

  const { unitOfMeasures, isLoading } = useSelector((state) => state.uom);

  // removing current unit of measure from list
  const unitOfMeasureList = unitOfMeasures.filter((uom) => uom.id !== currentUnit?.id);

  const { enqueueSnackbar } = useSnackbar();

  const NewDeviceSchema = Yup.object().shape({
    qty: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      qty: currentConversion?.qty || 0,
      unitOfMeasurementToId: currentConversion?.unitOfMeasurementToId || 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentConversion]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewDeviceSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    dispatch(getUoms());
  }, [dispatch]);

  useEffect(() => {
    if (isEdit && currentConversion) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentConversion]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // add unitOfMeasurementFromId to data
      const conversion = {
        ...data,
        unitOfMeasurementFromId: currentUnit?.id,
      };

      if (isEdit && currentConversion) {
        const conversionWithoutToId = {
          qty: data.qty,
        };
        dispatch(updateConversion(currentConversion.id, conversionWithoutToId));
      } else {
        const res = await dispatch(addConversion(conversion));
        console.log(res);
      }

      console.log(data);
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(PATH_DASHBOARD.inventory.units);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mt={2}>
                <RHFSelect
                  name="unitOfMeasurementToId"
                  label={translate('inventory.conversions.convert_to')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: false,
                    sx: { textTransform: 'capitalize' },
                  }}
                  // defaultValue={isEdit ? currentConversion?.unitOfMeasurementToId : ''}
                  disabled={isEdit}
                >
                  {!isLoading &&
                    unitOfMeasureList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </RHFSelect>

                <RHFTextField
                  name="qty"
                  type="number"
                  label={translate('inventory.conversions.one_of_this_unit_equals')}
                  defaultValue={currentConversion?.qty}
                />
              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit
                ? translate('inventory.conversions.create_conversion')
                : translate('inventory.products.save_changes')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
