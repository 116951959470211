import { createSlice } from '@reduxjs/toolkit';
// utils
import { IStockValueState } from '../../../../@types/warehouseReport/stockValue';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

// ----------------------------------------------------------------------

const initialState: IStockValueState = {
  isLoading: false,
  error: null,
  stockValue: null,
  inventories: [],
};

const slice = createSlice({
  name: 'stockValue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET REPORT
    getStockValueSuccess(state, action) {
      state.isLoading = false;
      state.stockValue = action.payload;
    },
    // GET CATEGORY REPORT
    getCategoryStockValueSuccess(state, action) {
      state.isLoading = false;
      state.stockValue = action.payload;
    },
    // GET ITEM REPORT
    getItemStockValueSuccess(state, action) {
      state.isLoading = false;
      state.stockValue = action.payload;
    },

    // GET INVENTORY
    getInventorySuccess(state, action) {
      state.isLoading = false;
      state.inventories = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getInventorySuccess,
  getCategoryStockValueSuccess,
  getItemStockValueSuccess,
  getStockValueSuccess,
  hasError,
  startLoading,
} = slice.actions;

// ----------------------------------------------------------------------

export function getStockValue(inventoryId: string) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/reports/branch-stock-value/${inventoryId}`);
      dispatch(getStockValueSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategoryStockValue(inventoryId: string, categoryId: string) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/reports/category-value/${inventoryId}/${categoryId}`);
      dispatch(getCategoryStockValueSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getItemStockValue(inventoryId: string, itemId: string) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/reports/item-value/${inventoryId}/${itemId}`);
      dispatch(getItemStockValueSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInventory() {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());

    try {
      const response = await axios.get(`/inventory/purchase-inventory/${companyId}`);
      dispatch(getInventorySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function resetStockValue() {
  return async () => {
    dispatch(getStockValueSuccess(null));
  };
}
