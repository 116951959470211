import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, MenuItem, Stack } from '@mui/material';
// utils
// @types
import { OrderType } from '../../../../@types/orderType';
// components
import {
  FormProvider,
  RHFCheckbox,
  RHFSelect,
  RHFTextField,
} from '../../../../components/hook-form';
import { CustomFile } from '../../../../components/upload';
// translate
import useLocales from '../../../../hooks/useLocales';
// redux
import { createOrderType, updateOrderType } from '../../../../redux/slices/orderType';
import { useDispatch, useSelector } from '../../../../redux/store';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<OrderType, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
}

type Props = {
  isEdit: boolean;
  handleClose: VoidFunction;
  currentOrderType?: OrderType | null;
};

export default function OrderTypeCreateEditFrom({ isEdit, handleClose, currentOrderType }: Props) {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const { branches } = useSelector((state) => state.branches);

  const dispatch = useDispatch();

  const NewPointOfSaleSchema = Yup.object().shape({
    name: Yup.string().required(translate('errors.name_required')),
    nameLocalized: Yup.string().required(translate('errors.name_required')),
    isDefault: Yup.boolean(),
    branchId: Yup.number(),
    availableAtPOS: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentOrderType?.name || '',
      nameLocalized: currentOrderType?.nameLocalized || '',
      isDefault: currentOrderType?.isDefault || false,
      availableAtPOS: currentOrderType?.availableAtPOS || true,
    }),
    [currentOrderType]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPointOfSaleSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentOrderType) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentOrderType]);

  const onSubmit = async (data: FormValuesProps) => {
    if (isEdit && currentOrderType) {
      try {
        data.branchId = currentOrderType.branchId;
        data.id = currentOrderType.id;
        dispatch(updateOrderType(data));
        handleClose();
        reset();
        enqueueSnackbar(translate('messages.update_success'));
      } catch (error) {
        enqueueSnackbar(translate('messages.update_error'));
      }
    }
    if (!isEdit) {
      try {
        dispatch(await createOrderType(data));
        reset();
        enqueueSnackbar(translate('messages.create_success'));
        handleClose();
      } catch (error) {
        enqueueSnackbar(translate('messages.create_error'));
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="name" label={translate('inventory.order_types.name')} />
              <RHFTextField
                name="nameLocalized"
                label={translate('inventory.order_types.name_localized')}
              />
              <RHFSelect
                InputLabelProps={{
                  shrink: true,
                }}
                name="branchId"
                label={translate('inventory.order_types.choose_branch')}
                SelectProps={{
                  native: false,
                  sx: { textTransform: 'capitalize' },
                }}
                defaultValue={isEdit ? currentOrderType?.branchId : 0}
                disabled={isEdit}
              >
                <MenuItem>{translate('inventory.order_types.choose_branch')}</MenuItem>
                {branches &&
                  branches?.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.name}
                    </MenuItem>
                  ))}
              </RHFSelect>
              <RHFCheckbox
                disabled={isEdit}
                name="isDefault"
                label={translate('inventory.order_types.is_default')}
              />
              <RHFCheckbox
                disabled={isEdit}
                name="availableAtPOS"
                label={translate('inventory.order_types.availableAtPOS')}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? translate('general.create') : translate('general.edit')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
