import { createSlice } from '@reduxjs/toolkit';
// utils
import { Eotd, EotdState } from '../../@types/eotd';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: EotdState = {
  isLoading: false,
  error: null,
  reports: [],
  report: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'eotd',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORTs
    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.reports = action.payload;
    },

    // GET REPORT
    getReportSuccess(state, action) {
      state.isLoading = false;
      state.report = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// // Actions
// export const {
//   onGotoStep,
//   onBackStep,
//   onNextStep,
// } = slice.actions;

// ----------------------------------------------------------------------

export function getReports() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`points-of-sale/sessions/company/${companyId}`, {});
      console.log('response.data', response.data);
      dispatch(slice.actions.getReportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReport() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { report: Eotd } } = await axios.get('/products/item', {});
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
