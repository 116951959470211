import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../../utils/axios';
//
import { ICompanyStockTakingHistoryState } from 'src/@types/stockTaking';
import { dispatch } from '../../../store';

// ----------------------------------------------------------------------

const initialState: ICompanyStockTakingHistoryState = {
  isLoading: false,
  error: null,
  companyStockTakingHistory: [],
};

const slice = createSlice({
  name: 'companyStockTakingHistory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORT
    getCompanyStockTakingHistorySuccess(state, action) {
      state.isLoading = false;
      state.companyStockTakingHistory = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;
// Actions

export const { getCompanyStockTakingHistorySuccess, hasError, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getCompanyStockTakingHistory() {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/stock-takeing-reports/${companyId}`);
      dispatch(getCompanyStockTakingHistorySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// add new stock taking
export function addNewStockTaking(data: any) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/stock-takeing-reports/${companyId}`, data);
      dispatch(getCompanyStockTakingHistorySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function resetCompanyStockTakingHistory() {
  return async () => {
    dispatch(getCompanyStockTakingHistorySuccess(null));
  };
}
