import { UpdateWarehouse } from './warehouse';

// defining create transfers request state type
export type TransferRequestState = {
  isLoading: boolean;
  error: Error | string | null;
  transferRequests: TransferRequest[];
  transferRequest: TransferRequest | null;
  sortBy: string | null;
};

// export type transfers request
export type TransferRequest = {
  id?: string;
  fromInventoryId: number;
  fromInventory?: UpdateWarehouse;
  toInventoryId: number;
  toInventory?: UpdateWarehouse;
  transferItems: TransferRequestItem[];
  status: TransferRequestStatus;
};

export interface TransferRequestItem {
  itemId: string;
  createdQty: number;
}

export enum TransferRequestStatus {
  DRAFT = 'DRAFT',
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum TransferItemStatus {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  UPDATED = 'UPDATED',
}
