import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const LIGHT = '#fff';

  const DARK = '#212B36';

  const PRIMARY_BG = theme.palette.mode === 'dark' ? DARK : LIGHT;

  const PRIMARY_DOTS = theme.palette.mode === 'dark' ? LIGHT : DARK;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width: 90, ...sx }}>
      <style type="text/css">{`.b {enable-background:new 0 0 2548.64 990.51;}`}</style>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 2548.64 990.51"
        className="b"
        xmlSpace="preserve"
      >
        <g>
          <path
            style={{ display: 'none', fill: 'transparent' }}
            d="M2555.13,1124.94c-853.33,0-1706.67,0-2560,0c0-416.67,0-833.33,0-1250c853.33,0,1706.67,0,2560,0
		C2555.13,291.61,2555.13,708.27,2555.13,1124.94z M724.83,989.04c5.42,1.21,8.23-2.38,11.01-6.01
		c6.82-5.28,11.78-11.39,12.53-20.66c0.82-10.04,0.2-19.18-8.52-25.86c-3.29-2.52-6.67-4.94-10.01-7.4c-1.56-1-3.05-2.72-4.69-2.87
		c-4.25-0.41-8.64-0.58-12.84,0.04c-2.29,0.34-4.31,2.48-6.45,3.81c-0.01,0.32-0.02,0.65-0.02,0.97c-7.17,1.35-14.4,8.6-15.97,16.02
		c-0.65,0.89-1.85,1.78-1.87,2.68c-0.15,8.84-1.9,18.16,5.03,25.47c6.07,6.39,11.78,13.62,21.84,13.79
		C718.19,989.03,721.51,989.03,724.83,989.04z M1017.66,584.53c-3.96,0.01-7.93,0.01-11.89,0.02c-1.63,0.31-3.26,0.63-4.9,0.94
		c-1.37-0.37-2.74-0.74-4.11-1.12c-1.46,0.17-3.64-0.22-4.26,0.59c-5.01,6.56-10.75,12.76-12.72,21.13
		c-2.46,10.45,1.16,19.08,8.01,26.84c6.27,7.11,13.77,10.77,23.41,9.42c1.63-0.23,3.35-0.24,4.98,0c7.19,1.09,11.37-4.12,16.36-7.65
		c4.21-2.98,5.47-7.76,7.94-11.89c3.28-5.48,3.92-10.67,2.33-16.35c-0.77-2.77-3.34-5.14-3.72-7.89
		c-0.93-6.68-8.32-8.22-10.36-13.87c-0.04-0.12-1.96,0.43-3,0.67c-1.33-0.01-2.67-0.01-4-0.02
		C1020.38,585.08,1019.02,584.81,1017.66,584.53z M934.85,584.51c-1.01-0.04-2.03-0.08-3.04-0.12c-4.21,0.09-12.16,7.43-14.04,12.97
		c-7.54,7.66-7.02,23.4,1.01,30.98c3.33,2.99,6.66,5.99,9.99,8.98c1.63,1.32,3.12,3.5,4.92,3.8c9.68,1.62,19.57,3.81,28.04-3.8
		c6.15-1.24,11.52-8.39,12.01-15.99l0,0.01c4.13-10.86,1.75-20.61-5.11-29.55c-1.4-1.83-2.97-3.59-4.7-5.1
		c-1.17-1.02-2.77-1.56-4.18-2.31c-1.04,0.04-2.08,0.08-3.12,0.12c-1.65,0.01-3.3,0.02-4.95,0.03c-1.98,0.01-3.96,0.01-5.93,0.02
		c-1.98,0.32-3.95,0.64-5.93,0.95C938.16,585.17,936.5,584.84,934.85,584.51z M655.85,989.03c5.23,1.3,8.75-1.55,12.02-4.98
		c13.65-6.5,18.41-20.05,15.29-33.96c-1.41-6.26-3.96-12.7-11.27-15.02c0.69-4.74-3.89-3.64-5.96-5.24
		c-9.83-7.56-24.06-3.95-32.66,3.49c-2.14,1.85-3.61,4.48-5.39,6.75c-1.63,2.17-4.49,4.24-4.67,6.52
		c-0.55,6.77-0.63,13.7,0.21,20.43c0.94,7.47,5.35,12.99,12.52,15.97c2.32,0.99,4.63,1.98,6.95,2.97
		C647.21,986.99,651.53,988.01,655.85,989.03z M2116.3,585.38c-2.21-1.07-4.41-2.05-5.98,0.95c-0.67,0.07-1.35,0.14-2.02,0.22
		c-4.63-0.01-9.27-0.02-13.9-0.03c-1.65-0.69-3.3-1.38-4.95-2.07c-1.52,0.35-3.52,0.21-4.46,1.14c-3.41,3.39-6.47,7.14-9.66,10.75
		c-0.84,0.77-2.13,1.39-2.46,2.34c-4.32,12.31-3.42,23.3,5.13,34.32c7.39,9.53,30.92,13.89,39.32,4.33c0.81-0.1,1.93,0.1,2.37-0.35
		c3.47-3.59,6.91-7.22,10.07-11.07c0.4-0.49-0.83-2.33-1.3-3.54c0.19-1.73-0.05-3.68,0.66-5.16c2.01-4.17,1.78-8.02-0.68-11.84
		c0.62-1.98,1.24-3.97,1.87-5.95c-0.27-1.24-0.14-2.8-0.86-3.66c-2.98-3.58-6.11-7.05-9.44-10.3
		C2119.34,584.82,2117.55,585.37,2116.3,585.38z M279.01,972.06c4.87-4.1,7.29-19.13,3.77-24.25c-0.56-0.82-1.83-1.16-2.76-1.72
		c-1.94-3.88-2.93-8.4-7.57-10.41c-0.71-0.31-0.96-1.68-1.43-2.56c-3.72-2.29-7.16-5.56-11.2-6.7
		c-11.21-3.16-25.54,2.68-32.65,12.38c-0.97,1.32-1.91,2.89-3.26,3.64c-4.52,2.54-3.81,5.4-0.96,8.62c0.33,0.36,0.66,0.73,0.99,1.09
		c0,3.98,0,7.97-0.01,11.95c-0.78,2.27-2.94,5.5-2.13,6.67c4.73,6.81,10.8,12.39,18.26,16.28c4.95,0.01,9.94-0.4,14.83,0.13
		c8.42,0.91,13.59-4.32,19.09-9.13C275.66,976.04,277.34,974.05,279.01,972.06z"
          />
        </g>
        <g>
          <path
            style={{ fill: 'transparent' }}
            d="M-4.77-124.97c851.77,0,1703.54,0,2555.32-0.09c3.75,0,4.59,0.84,4.59,4.59c-0.1,413.61-0.1,827.22,0,1240.82
		c0,3.75-0.84,4.59-4.59,4.59c-850.27-0.1-1700.55-0.1-2550.82,0c-3.75,0-4.59-0.84-4.59-4.59C-4.77,705.24-4.77,290.14-4.77-124.97
		z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M1530.63,902.15c-71.13,0-142.26-0.01-213.38,0.03c-2.7,0-5.32-0.23-7.82-1.23c-1.92-0.77-4.05-1.24-5.86-0.49
		c-5.79,2.41-11.81,1.63-17.76,1.65c-26.15,0.06-52.3,0.03-78.46,0.05c-2.84,0-5.65-0.04-8.38-0.98c-2.93-1.01-5.86-1.13-8.74,0.23
		c-1.89,0.89-3.9,0.75-5.89,0.75c-29.32,0-58.63-0.01-87.95-0.01c-57.38,0-114.76-0.01-172.14-0.01c-37.4,0-74.8,0.01-112.2,0.01
		c-58.21,0-116.43,0-174.64,0c-36.73,0-73.46,0-110.19,0c-106.37,0-212.74,0.03-319.11-0.04c-21.03-0.01-41.95-2.09-61.94-8.72
		c-24.65-8.17-45.57-21.92-60.55-43.9c-16.27-23.86-22.79-50.63-24.41-78.86c-1.78-30.93-0.55-61.9-0.84-92.85
		c-0.08-8.45,4.87-13.31,13.26-13.33c7.33-0.02,14.66-0.07,21.99,0.01c7.91,0.09,12.67,4.94,12.67,12.87
		c0.01,24.31-0.04,48.62-0.02,72.92c0.01,12.82,1.09,25.62,3.85,38.1c5.16,23.36,16.62,42.73,38.44,54.32
		c13.69,7.27,28.64,11.08,44.08,11.17c53.14,0.34,106.27,0.31,159.41,0.05c12.38-0.06,23.17-4.78,29.69-16.08
		c3.71-6.42,4-13.95,5.27-21.1c1.58-8.9,1.08-17.91,1.08-26.88c0.01-36.8-0.01-73.59-0.02-110.39c0-10.27,3.95-14.19,14.17-14.14
		c7.16,0.04,14.33-0.04,21.49,0.01c7.6,0.06,12.22,4.53,12.22,12.07c0.03,46.79,0.23,93.57-0.2,140.35
		c-0.09,10.18-0.57,20.6-4.2,30.46c-1.28,3.49,0.33,5.46,3.89,5.84c1.65,0.18,3.33,0.09,4.99,0.09c20.99-0.01,41.98-0.03,62.97-0.03
		c36.27-0.01,72.54-0.36,108.8,0.16c17.45,0.25,34.88-0.09,52.32-0.25c11.68-0.11,21.18-5.39,27.42-15.48
		c3.54-5.73,4.76-12.42,5.77-18.96c1.04-6.72,1.23-13.55,1.21-20.39c-0.1-40.13-0.07-80.25-0.09-120.38
		c0-8.78,4.7-13.47,13.45-13.47c7.5,0,14.99-0.06,22.49,0.02c7.19,0.08,12.18,5.09,12.18,12.31c0.01,47.28,0.1,94.57-0.16,141.85
		c-0.05,9.27-1.78,18.5-3.96,27.55c-1.3,5.38-0.26,7.04,5.43,7.06c12.33,0.06,24.65-0.02,36.98-0.02
		c37.58-0.01,75.17-0.4,112.74,0.16c16.95,0.25,33.87-0.18,50.81-0.24c11.82-0.04,21.62-5.03,27.98-15.4
		c3.53-5.76,4.77-12.43,5.8-18.95c1.17-7.38,1.29-14.89,1.28-22.39c-0.08-39.46-0.06-78.92-0.07-118.38
		c0-8.87,4.65-13.58,13.41-13.58c7.5,0,14.99-0.07,22.49,0.02c7.08,0.09,12.24,5.24,12.24,12.35c0.01,46.62,0.09,93.24-0.18,139.85
		c-0.06,10.11-1.74,20.17-4.17,30.04c-1.15,4.67,0.08,6.46,5.11,6.53c7.49,0.11,14.99,0,22.49,0c42.24-0.01,84.48-0.02,126.72-0.02
		c37.07,0,74.15,0,111.22,0c55.24,0.01,110.47-0.05,165.71,0.08c10.1,0.02,19.02-2.76,26.76-9.16c5.9-4.88,9.86-11.3,12.46-18.33
		c4-10.81,5.84-22,5.74-33.69c-0.33-38.12-0.15-76.25-0.17-114.38c0-8.47,4.79-13.29,13.22-13.3c7.66-0.01,15.33-0.08,22.99,0.03
		c7.68,0.1,12.57,4.89,12.58,12.51c0.04,45.62,0.15,91.24-0.13,136.86c-0.06,10.6-2.09,21.13-4.27,31.54
		c-1.31,6.24-0.06,7.91,6.35,7.9c16.66-0.02,33.32,0.01,49.97-0.1c17.79-0.11,26.27-9.14,29.13-25.97
		c2.48-14.61,3.09-29.38,3.04-44.21c-0.12-35.13-0.07-70.26-0.08-105.39c0-8.9,4.21-13.15,13.05-13.17c7.5-0.02,14.99-0.03,22.49,0
		c7.22,0.03,12.37,4.86,12.38,12.13c0.06,44.45,0.31,88.91-0.2,133.36c-0.14,12.17-0.04,24.61-4.42,36.46
		c-1.74,4.7,0.04,6.84,4.69,6.85c16.66,0.02,33.32,0.24,49.97-0.2c12.36-0.32,22.95-5.02,28.62-16.68c3.7-7.62,5.2-16.03,5.51-24.64
		c1.6-43.93,0.35-87.88,0.67-131.81c0.08-11.48,3.95-15.47,15.53-15.45c6.83,0.02,13.66-0.01,20.49,0
		c8.12,0.02,13.05,4.86,13.07,12.99c0.04,13.49-0.02,26.97-0.03,40.46c0,28.46,0.13,56.93-0.04,85.39
		c-0.09,15.13-1.1,30.25-5.27,44.89c-7.51,26.38-26.38,47.01-56.08,51.27c-9.12,1.31-18.16,1.83-27.31,1.82
		c-66.96-0.02-133.93-0.01-200.89-0.01C1530.63,902.13,1530.63,902.14,1530.63,902.15z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M471.97,190.56c-0.01-48.27,0.07-96.55-0.05-144.82c-0.04-15.86,13.32-29.7,29.44-27.87
		c4.12,0.47,8.27,1.23,11.73,3.71c21.29,15.28,42.5,30.66,63.8,45.92c10.5,7.52,21.18,14.78,31.68,22.29
		c12.78,9.15,25.4,18.51,38.17,27.67c10.35,7.42,20.98,14.47,31.19,22.08c12.48,9.29,25.63,17.66,37.62,27.61
		c1.8,1.49,3.77,3.35,6.21,2.13c2.81-1.41,1.99-4.18,1.62-6.67c-0.25-1.63-0.42-3.3-0.43-4.95c-0.03-37.12,0.03-74.24-0.06-111.36
		c-0.03-11.59,5.09-20.78,14.86-25.78c11.88-6.07,24.95-3.17,33.33,7.99c4.39,5.84,5.7,12.39,5.7,19.6
		c-0.1,95.05-0.21,190.1,0.08,285.15c0.05,15.65-7.83,25.61-20.78,30.44c-8.35,3.11-15.76-0.38-22.31-5.26
		c-7.57-5.64-10.81-13.7-10.82-23.04c-0.02-29.63-0.24-59.27,0.22-88.89c0.1-6.56-2.21-10.74-7.08-14.21
		c-15.13-10.77-30.3-21.46-45.4-32.27c-13.05-9.35-25.96-18.89-39.03-28.22c-12.8-9.13-25.77-18-38.56-27.14
		c-12.78-9.14-25.37-18.56-38.17-27.68c-7.55-5.38-15.38-10.34-22.47-16.35c-1.36-1.15-3.08-2.36-4.99-1.38
		c-2.28,1.16-1.49,3.5-1.49,5.38c-0.02,57.76,0,115.53,0.01,173.29c0,19.81-0.08,39.62,0.02,59.43
		c0.07,14.25-15.44,29.67-30.27,26.98c-12.31-2.24-23.68-12.2-23.72-27.47C471.88,288.11,471.97,239.34,471.97,190.56z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M394.1,190.97c-0.12,97.99-80.07,177.89-178.35,177.4c-99.84-0.5-177.19-79.79-177.19-177.84
		c0-98.74,80.64-177.09,177.87-177.09C314.41,13.44,394.6,94.67,394.1,190.97z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M872.8,190.93c0-47.94,0.36-95.89-0.19-143.82c-0.21-18.18,14.05-32.25,33.34-29.42
		c1.31,0.19,2.66,0.1,3.99,0.1c35.64,0,71.28,0.02,106.92-0.01c4.78,0,9.5-0.63,14.36,0.03c13.29,1.8,22.33,13.17,23.21,25.04
		c0.68,9.22-3.98,16.81-11.36,22.68c-4.97,3.95-10.45,5.31-16.65,5.29c-30.81-0.09-61.62-0.03-92.43-0.05
		c-7.96,0-8.73,0.86-7.65,8.6c0.25,1.8,0.3,3.65,0.27,5.47c-0.35,19.96,0.68,39.92-0.55,59.87c-0.4,6.58,1.42,8.02,8.18,8.01
		c41.3-0.01,82.61,0.14,123.91-0.07c13.11-0.07,26.4,13.47,25.6,27.73c-0.73,13.18-14.38,25.58-26.58,25.45
		c-40.97-0.43-81.94-0.16-122.91-0.18c-8.35,0-8.96,0.67-7.87,8.82c0.24,1.8,0.22,3.65,0.22,5.48c0.01,25.14,0.01,50.27,0.01,75.41
		c0,1.5,0.08,3.01-0.07,4.49c-0.98,10.19-0.46,10.73,9.82,10.73c49.63-0.01,99.26,0.19,148.89-0.12
		c15.4-0.1,27.53,11.21,29.81,25.39c0.98,6.08-1.7,11.7-5.11,16.23c-5.99,7.95-13.87,12.67-24.61,12.63
		c-61.29-0.22-122.58-0.29-183.86,0.02c-16.04,0.08-28.95-13.73-28.83-28.97C873.03,287.48,872.8,239.2,872.8,190.93z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M1972.34,190.99c0-48.11,0.32-96.22-0.2-144.32c-0.16-14.92,12.35-28.18,27.81-27.91
		c41.46,0.72,82.94,0.7,124.4,0.01c15.4-0.25,30.48,15.02,25.8,31.71c-2.83,10.11-12.73,19.23-26.73,19.17
		c-30.14-0.13-60.29-0.04-90.43-0.06c-9.91-0.01-10.06,0.4-9.98,10.37c0.17,21.81,0.03,43.61,0.02,65.42
		c-0.01,7.85,0.75,8.58,8.55,8.57c40.97-0.02,81.94-0.02,122.91-0.06c9.61-0.01,16.48,4.24,22,12.14
		c7.42,10.62,3.76,26.36-7.31,33.75c-4.87,3.24-10.06,4.8-15.95,4.79c-40.47-0.09-80.94-0.06-121.41-0.07
		c-8.43,0-8.84,1.03-8.86,9.55c-0.09,29.94,0.93,59.89-0.53,89.83c-0.28,5.82,2.74,8.76,9.36,8.76
		c47.96-0.01,95.93-0.03,143.89-0.04c3.33,0,6.66-0.04,9.99,0c17.66,0.26,29.07,15.27,24.57,32.32
		c-2.36,8.95-12.64,17.26-22.01,17.67c-3.66,0.16-7.33,0.05-10.99,0.05c-58.62,0.01-117.25-0.37-175.87,0.25
		c-16.92,0.18-29.51-12.53-29.27-29.07C1972.78,286.21,1972.35,238.59,1972.34,190.99z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M1955.16,751.72c0-24.47-0.01-48.94,0.01-73.41c0-8.02,4.49-13.34,12.49-14.27
		c9.27-1.08,18.61-1.07,27.87-0.04c6.77,0.75,10.75,5.73,10.76,12.55c0.02,41.28-0.05,82.57,0.02,123.85
		c0.04,20.51,3,40.58,8.53,60.39c5.86,20.97,15.8,39.29,32.14,53.79c12.73,11.29,27.6,18.2,44.72,19.33
		c10.46,0.69,21.08,1.1,31.34-1.14c7.15-1.55,13.99-4.4,20.72-7.8c14.5-7.35,24.92-18.62,33.14-31.99
		c6.14-9.99,10.21-20.98,13.7-32.35c4.04-13.19,4.54-26.74,7.1-40.04c0.76-3.97,0.59-7.96,0.59-11.94
		c0.03-42.78,0.28-85.57-0.21-128.34c-0.11-9.98,6.48-17.66,17.22-16.88c7.62,0.56,15.3-0.57,22.92,0.61
		c7.32,1.13,11.9,6.34,11.91,13.97c0.03,23.14-0.01,46.28-0.01,69.42c0,21.64,0.25,43.29-0.08,64.92
		c-0.27,17.95-3.3,35.53-7.74,52.96c-5.44,21.34-14.72,40.82-26.97,58.91c-7.57,11.18-17.08,20.79-27.23,29.69
		c-8.87,7.78-18.81,14.21-29.48,19.02c-14.04,6.33-28.7,11.14-44.35,11.95c-18.07,0.93-35.96-0.07-53.17-6.01
		c-11.88-4.1-23.14-9.71-33.64-16.73c-14.25-9.53-26.18-21.61-36.15-35.34c-6.95-9.56-12.98-19.8-17.87-30.8
		c-4.95-11.12-9.01-22.49-11.97-34.12c-2.97-11.66-5.72-23.65-5.94-35.75c-0.46-24.79-0.14-49.6-0.14-74.41
		C1955.31,751.72,1955.23,751.72,1955.16,751.72z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M2287.23,190.71c0-47.94,0.22-95.88-0.13-143.82c-0.11-15.38,11.66-28.35,28.16-28.08
		c36.96,0.6,73.94,0,110.91,0.25c34.67,0.23,61.1,15.53,79.66,44.7c4.77,7.49,7.11,16.01,10.68,23.96
		c5.92,13.19,3.46,26.63,2.75,39.75c-0.32,5.88-3.63,12.58-6.21,18.69c-7.02,16.61-17.59,30.56-32.25,41.02
		c-13.2,9.41-28.07,15.39-44.33,16.47c-29.92,1.99-59.91,0.51-89.87,0.83c-8.57,0.09-9.92,1.57-8.8,10.07
		c0.17,1.31,0.17,2.65,0.17,3.98c0.01,38.12,0.1,76.24-0.06,114.36c-0.04,8.57-1.5,16.76-8.58,23.03
		c-13.25,11.74-31.53,7.22-39.14-7.4c-2.15-4.14-3.02-8.68-3.01-13.49C2287.27,286.93,2287.23,238.82,2287.23,190.71z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M2499.15,815.41c0,15.51-0.01,27.33,0,39.15c0.03,21.74-2.35,43.22-9.8,63.67
		c-5.11,14.02-12.95,26.45-24.16,37.05c-17.76,16.81-39.43,23.03-62.55,26.45c-2.95,0.44-5.92,0.98-8.89,1.04
		c-7.08,0.15-12.36-5.02-12.43-12.04c-0.08-7.32-0.04-14.65-0.02-21.97c0.03-8.26,4.91-13.31,13.23-13.56
		c11.94-0.35,22.92-3.47,32.48-10.88c9.41-7.3,15.12-16.98,18.33-28.3c0.54-1.91,1.16-3.81,1.89-5.66c0.75-1.88,1.85-3.85,0.12-5.56
		c-1.96-1.94-4.14-0.64-5.95,0.45c-3.43,2.07-6.93,3.79-10.8,4.98c-24.71,7.55-48.78,6.46-72.26-4.73
		c-24.01-11.44-39.23-30.65-49.31-54.71c-7.2-17.19-9.75-35.14-9.75-53.51c0.01-19.3,3.48-38.08,11.69-55.62
		c8.45-18.06,20.66-32.96,38.01-43.59c21.35-13.09,44.46-16.84,68.53-12.48c23.56,4.27,42.83,16.68,57.3,36.02
		c13.91,18.59,20.78,39.81,23.4,62.63C2500.33,782.52,2498.38,800.83,2499.15,815.41z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M1794.71,207.17c0,43.28,0.03,86.55-0.02,129.83c-0.01,13.02-12.95,26.59-25.42,26.9
		c-14.06,0.35-27.13-12.14-27.21-26.29c-0.12-21.47-0.04-42.94-0.03-64.41c0.01-65.25,0.03-130.49,0.05-195.74
		c0-6.05-1.17-7.3-7.28-7.3c-29.31-0.01-58.62-0.14-87.92,0.09c-12.18,0.1-20.07-6.1-25.47-16.09c-4.52-8.36-0.5-22.77,6.66-29.11
		c5.52-4.89,11.58-6.66,18.65-6.65c81.1,0.04,162.2,0.27,243.29-0.17c16.21-0.09,31.11,15.85,25.8,33.53
		c-2.89,9.63-13.78,18.35-24.22,18.37c-29.64,0.08-59.28,0.04-88.92,0.03c-7.85,0-8.01,0.25-8.01,8.2
		c0.04,42.94,0.02,85.88,0.02,128.83C1794.69,207.17,1794.7,207.17,1794.71,207.17z"
          />
          <path
            style={{ fill: PRIMARY_MAIN }}
            d="M1414.62,188.04c0-27.13-0.05-54.26,0.01-81.39c0.07-29.04,11.84-52.52,34.81-70.03
		c12.89-9.82,27.83-15.27,44.09-16.76c19.27-1.77,38.59-0.37,57.88-0.86c12.78-0.32,24.57,11.79,24.88,24.91
		c0.3,12.6-11.72,24.67-23.51,25.24c-16.15,0.77-32.28,0.39-48.41,0.38c-12.83-0.01-23.34,4.36-31.44,14.28
		c-6.79,8.32-8.26,18.27-8.27,28.56c-0.03,53.43-0.18,106.85,0.07,160.27c0.08,17.62-4.06,33.86-13.27,48.77
		c-9.81,15.88-23.42,27.23-40.92,34.09c-13.38,5.24-27.18,7.26-41.42,7.19c-13.49-0.07-26.98,0.05-40.46-0.05
		c-11.7-0.08-21.29-7.31-24.57-18.29c-3.13-10.48,1.06-21.83,10.81-28.14c3.93-2.55,8.52-3.62,13.31-3.61
		c15.15,0.04,30.31,0.28,45.46-0.1c10.27-0.26,20.23-2.52,28.13-9.73c8.41-7.67,12.62-17.58,12.72-28.83
		c0.24-28.62,0.08-57.25,0.08-85.88C1414.6,188.04,1414.61,188.04,1414.62,188.04z"
          />
          <path
            style={{ fill: PRIMARY_BG }}
            d="M215.21,319.66c-70.55,1-129.88-59.24-128.67-130.31c1.21-71.16,57.56-127.82,129.47-127.88
		c71.88-0.06,129.47,58.2,129.2,130.67C344.94,261.44,285.74,321.57,215.21,319.66z"
          />
          <path
            style={{ fill: PRIMARY_BG }}
            d="M2388.24,70.19c13.48,0,26.98,0.24,40.45-0.06c15.27-0.35,32.26,13.68,37.49,27.46
		c7.4,19.47-2.34,41.74-19.51,50.56c-6.37,3.27-13.32,5.14-20.61,5.15c-26.64,0.04-53.27,0.05-79.91,0.06
		c-5.58,0-7.55-1.91-7.56-7.45c-0.04-23.13,0.02-46.26-0.08-69.39c-0.02-4.81,2.29-6.43,6.78-6.4c14.32,0.09,28.63,0.03,42.95,0.03
		C2388.24,70.16,2388.24,70.18,2388.24,70.19z"
          />
          <path
            style={{ fill: PRIMARY_BG }}
            d="M2451.97,779.15c-0.33,17.04-2.91,32.21-11.03,46.13c-19.04,32.62-59.61,32.72-80.27,2.72
		c-10.47-15.2-14.25-32.61-14.11-50.54c0.13-17.05,3.91-33.6,13.62-48.25c16.02-24.15,47.2-27.9,67.58-9.89
		c14.14,12.49,21.1,28.72,23.19,47.16C2451.46,771.1,2452.58,775.71,2451.97,779.15z"
          />
        </g>
        <g>
          <circle style={{ fill: PRIMARY_DOTS }} cx="2100.39" cy="614.4" r="30.07" />
          <circle style={{ fill: PRIMARY_DOTS }} cx="1011.33" cy="611.91" r="30.07" />
          <circle style={{ fill: PRIMARY_DOTS }} cx="944.24" cy="611.91" r="30.07" />
          <circle style={{ fill: PRIMARY_DOTS }} cx="718.23" cy="958.54" r="30.07" />
          <circle style={{ fill: PRIMARY_DOTS }} cx="651.15" cy="958.54" r="30.07" />
          <circle style={{ fill: PRIMARY_DOTS }} cx="251.9" cy="958.54" r="30.07" />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
