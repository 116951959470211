import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
import useTable, { emptyRows, getComparator } from '../../../../hooks/useTable';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
  TableSkeleton,
} from '../../../../components/table';
// sections
import { AddonTableRow, AddonTableToolbar } from './components';
// Types
import { Addon, AddonPrice } from 'src/@types/product';
//useLocales
import { Can } from '@casl/react';
import { DialogAnimate } from 'src/components/animate';
import { getAddons } from 'src/redux/slices/addon';
import ability from 'src/utils/ability';
import useLocales from '../../../../hooks/useLocales';
import AddonNewEditForm from './AddonNewEditForm';
import AddonsPricesNewEditForm from './AddonsPricesNewEditForm';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'inventory.addons.addon_name', align: 'left' },
  { id: 'price', label: 'inventory.addons.addon_price', align: 'left' },
  { id: '' },
];

export default function AddonsTable() {
  const { themeStretch } = useSettings();

  const { translate } = useLocales();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [onCreateAndEdit, setOnCreateAndEdit] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [onCreateAndEditPrices, setOnCreateAndEditPrices] = useState(false);

  const [isPricesEdit, setIsPricesEdit] = useState(false);

  const [currentId, setCurrentId] = useState(0);

  const [currentAddon, setCurrentAddon] = useState<Addon | null>(null);

  const [currentAddOnPrices, setCurrentAddOnPrices] = useState<AddonPrice[] | null>(null);

  const handleClose = () => {
    setOnCreateAndEdit(false);
    setIsEdit(false);
    setCurrentId(0);
  };

  const handleEdit = (id: number) => {
    setCurrentId(id);
    setIsEdit(true);
    setOnCreateAndEdit(true);
  };

  const handleEditPrices = (id: number) => {
    setCurrentId(id);
    const addon = addons.find((addon) => parseInt(addon.id) === id);
    setCurrentAddOnPrices(addon?.prices || null);
    setIsPricesEdit(true);
    setOnCreateAndEditPrices(true);
  };

  const handleClosePrices = () => {
    setOnCreateAndEditPrices(false);
    setIsPricesEdit(false);
    setCurrentId(0);
  };

  const { addons, isLoading } = useSelector((state) => state.addon);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const [tableData, setTableData] = useState<Addon[]>([]);

  const [filterName, setFilterName] = useState('');

  //get id from params
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(getAddons(paramCase(id as string)));
  }, [dispatch, id]);

  useEffect(() => {
    setTableData(addons);
  }, [addons]);

  useEffect(() => {
    const filterAddon = addons.filter((addon) => parseInt(addon.id) === currentId);
    setCurrentAddon(filterAddon && filterAddon[0]);
    // console.log(filterAddon);
    setCurrentAddOnPrices(currentAddon?.prices || null);
  }, [currentId, addons, currentAddon?.prices]);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row) => row.id.toString() !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id.toString()));
    setSelected([]);
    setTableData(deleteRows);
  };

  // const handleEditRow = (id: string) => {
  //   navigate(PATH_DASHBOARD.inventory.editAddon(paramCase(id.toString())));
  // };

  const handleEditActionsRow = (id: string) => {
    navigate(PATH_DASHBOARD.inventory.addonActions(paramCase(id.toString())));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = dense ? 60 : 80;

  const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoading && !dataFiltered.length);

  return (
    <Page title="Inventory: Addon List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.products.addons')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: translate('inventory.products.addons') },
          ]}
          action={
            <Can I="write" a="addons" ability={ability}>
              {() => (
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  // component={RouterLink}
                  // to={PATH_DASHBOARD.inventory.createAddon(paramCase(id as string))}
                  onClick={() => setOnCreateAndEdit(true)}
                >
                  {translate('inventory.products.add_addon')}
                </Button>
              )}
            </Can>
          }
        />

        <Card>
          <AddonTableToolbar filterName={filterName} onFilterName={handleFilterName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id.toString())
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id.toString())
                    )
                  }
                />

                <TableBody>
                  {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                      row ? (
                        <AddonTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEdit(row.id)}
                          onEditPrices={() => handleEditPrices(row.id)}
                          onEditActions={() => handleEditActionsRow(row.id)}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
        <DialogAnimate open={onCreateAndEdit} onClose={handleClose}>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center">
              {translate(
                isEdit ? 'inventory.products.edit_addons' : 'inventory.products.add_addon'
              )}
            </Typography>
          </Box>
          <AddonNewEditForm isEdit={isEdit} currentAddon={currentAddon} id={id as string} />
        </DialogAnimate>
        <DialogAnimate open={onCreateAndEditPrices} onClose={handleClosePrices}>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center">
              {translate('inventory.products.edit_addons_prices')}
            </Typography>
          </Box>
          <AddonsPricesNewEditForm
            isEdit={isPricesEdit}
            currentBranchId={currentAddon?.itemChild.inventory.branchId || 0}
            currentAddonPrices={currentAddOnPrices}
            currentId={parseInt(currentAddon?.id || '0')}
            handleClosePrices={handleClosePrices}
          />
        </DialogAnimate>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: Addon[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
