import sum from 'lodash/sum';
import { useCallback, useEffect } from 'react';
// form
import { useFieldArray, useFormContext } from 'react-hook-form';
// @mui
import { Box, Button, Divider, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { PurchaseOrderItem } from 'src/@types/purchaseOrder';
import { PurchaseTax } from 'src/@types/purchaseTax';
import Iconify from 'src/components/Iconify';
import { RHFCheckbox } from 'src/components/hook-form';
import RHFSelect from 'src/components/hook-form/RHFSelect';
import RHFTextField from 'src/components/hook-form/RHFTextField';
import useLocales from 'src/hooks/useLocales';
import { getItems } from 'src/redux/slices/product';
import { getPurchaseTaxes } from 'src/redux/slices/purchaseTaxes';
import { getPurchaseInventories } from 'src/redux/slices/warehouse';
import { useDispatch, useSelector } from 'src/redux/store';
import { fCurrency, fNumber } from 'src/utils/formatNumber';
// utils

export default function PurchaseNewEditDetails() {
  const { control, setValue, watch, resetField } = useFormContext();

  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.product);

  const { purchaseTaxes } = useSelector((state) => state.purchaseTaxes);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const values = watch();
  const { translate } = useLocales();
  const totalOnRow = values.items?.map((item: PurchaseOrderItem) => item.qty * item.price);

  const totalPrice = sum(totalOnRow) + values.taxes;

  useEffect(() => {
    setValue('totalPrice', totalPrice);
  }, [setValue, totalPrice]);

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPurchaseTaxes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPurchaseInventories());
  }, [dispatch]);

  const handleAdd = () => {
    append({
      id: 0,
      name: '',
      qty: 1,
      price: 0,
      total: 0,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleClearService = useCallback(
    (index: number) => {
      resetField(`items[${index}].qty`);
      resetField(`items[${index}].price`);
      resetField(`items[${index}].total`);
    },
    [resetField]
  );

  const handleSelectService = useCallback(
    (index: number, option: string, inventoryId: number) => {
      // find itemChild cost using option as id and inventoryId
      const item = items.find((product) => product.id === option);

      console.log('========================================');
      console.log(item);
      console.log('========================================');

      // set item cost
      setValue(`items[${index}].price`, item?.cost.cost);

      setValue(`items[${index}].price`, items.find((service) => service.id === option)?.cost.cost);
      setValue(
        `items[${index}].total`,
        values.items.map((item: PurchaseOrderItem) => item.qty * item.price)[index]
      );
      setValue(`items[${index}].id`, item?.item.id);
    },
    [items, setValue, values.items]
  );

  const handleSelectTax = useCallback(
    (tax: PurchaseTax) => {
      let totalTax = 0;
      totalTax = (totalPrice * tax.rate) / 100;
      setValue('taxId', tax.id);
      setValue('taxes', totalTax);
      console.log('========================================');
      console.log(totalPrice);
      console.log('========================================');
    },
    [setValue, totalPrice]
  );

  const handleChangeQuantity = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      setValue(`items[${index}].qty`, Number(event.target.value));
      setValue(
        `items[${index}].total`,
        values.items.map((item: PurchaseOrderItem) => item.qty * item.price)[index]
      );
    },
    [setValue, values.items]
  );

  const handleChangePrice = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      setValue(`items[${index}].price`, Number(event.target.value));
      setValue(
        `items[${index}].total`,
        values.items.map((item: PurchaseOrderItem) => item.qty * item.price)[index]
      );
    },
    [setValue, values.items]
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        {translate('inventory.purchase_orders.actions.Details')}
      </Typography>

      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
              <RHFSelect
                name={`items[${index}].name`}
                size="small"
                label={translate('inventory.purchase_orders.actions.product')}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: 300 } }}
              >
                <MenuItem
                  value=""
                  onClick={() => handleClearService(index)}
                  sx={{
                    mx: 1,
                    borderRadius: 0.75,
                    typography: 'body2',
                    fontStyle: 'italic',
                    color: 'text.secondary',
                  }}
                >
                  None
                </MenuItem>

                <Divider />

                {items
                  .filter((product) => product.item.canBePurchased === true)
                  .map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.item.name}
                      onClick={() =>
                        handleSelectService(index, option.id, values.items[index].inventoryId)
                      }
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {option.item.name}
                    </MenuItem>
                  ))}
              </RHFSelect>

              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].qty`}
                label={translate('inventory.purchase_orders.actions.quantity')}
                placeholder="0"
                onChange={(event) => handleChangeQuantity(event, index)}
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 120 } }}
              />

              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].price`}
                label={translate('inventory.purchase_orders.actions.Price')}
                placeholder="0"
                onChange={(event) => handleChangePrice(event, index)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 120 } }}
              />

              <RHFTextField
                disabled
                size="small"
                name={`items[${index}].total`}
                label={translate('inventory.purchase_orders.total')}
                placeholder="0"
                value={fNumber(totalOnRow[index])}
                InputProps={{
                  startAdornment: <InputAdornment position="start">SR</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 120 } }}
              />
            </Stack>

            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="eva:trash-2-outline" />}
              onClick={() => handleRemove(index)}
            >
              {translate('inventory.purchase_orders.actions.remove')}
            </Button>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={2}
        direction={{ xs: 'column-reverse', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <Button
          size="small"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          {translate('inventory.purchase_orders.actions.add_details')}
        </Button>

        <Stack
          spacing={2}
          justifyContent="flex-end"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1 }}
        >
          {/* <RHFTextField
            size="small"
            label="Discount"
            name="discount"
            onChange={(event) => setValue('discount', Number(event.target.value))}
            sx={{ maxWidth: { md: 200 } }}
          /> */}

          <RHFSelect
            size="small"
            label={translate('inventory.purchase_orders.actions.tax')}
            name="taxId"
            SelectProps={{ native: false }}
            sx={{ maxWidth: { md: 200 } }}
          >
            {(purchaseTaxes &&
              purchaseTaxes?.map((tax) => (
                <MenuItem key={tax.id} value={tax.id} onClick={() => handleSelectTax(tax)}>
                  {tax.name}
                </MenuItem>
              ))) || <MenuItem value="">None</MenuItem>}
          </RHFSelect>
        </Stack>
      </Stack>

      <Stack spacing={2} sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="flex-end">
          <Typography>{translate('inventory.purchase_orders.actions.subtotal')}</Typography>
          <Typography sx={{ textAlign: 'right', width: 120 }}>
            {fCurrency(sum(totalOnRow))}
          </Typography>
        </Stack>

        {/* <Stack direction="row" justifyContent="flex-end">
          <Typography>Discount :</Typography>
          <Typography
            sx={{ textAlign: 'right', width: 120, ...(values.discount && { color: 'error.main' }) }}
          >
            {values.discount ? `- ${fCurrency(values.discount)}` : '-'}
          </Typography>
        </Stack> */}

        <Stack direction="row" justifyContent="flex-end">
          <Typography>{translate('inventory.purchase_orders.actions.tax')}</Typography>
          <Typography sx={{ textAlign: 'right', width: 120 }}>
            {values.taxes ? values.taxes : '-'}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <Typography variant="h6">
            {translate('inventory.purchase_orders.actions.total_price')}
          </Typography>
          <Typography variant="h6" sx={{ textAlign: 'right', width: 120 }}>
            {fCurrency(sum(totalOnRow))}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <RHFCheckbox
          name="updateCost"
          label={translate('inventory.purchase_orders.actions.update_cost_msg')}
          checked={values.updateCost}
        />
      </Stack>
    </Box>
  );
}
