import { createSlice } from '@reduxjs/toolkit';
// types
import { BranchState, UpdateBranch } from '../../@types/branch';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: BranchState = {
  isInitialized: false,
  branches: [],
};

const slice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
    },

    // GET BRANCHES
    getBranchesSuccess(state, action) {
      state.isInitialized = true;
      state.branches = action.payload;
    },

    // CREATE BRANCH
    createBranchSuccess(state, action) {
      state.isInitialized = true;
      state.branches = action.payload;
    },

    // UPDATE BRANCH
    updateBranchSuccess(state, action) {
      state.isInitialized = true;
      // replace updated branch
      state.branches = state.branches.map((branch) =>
        branch.id === action.payload.id ? action.payload : branch
      );
    },

    // DELETE BRANCH
    deleteBranchSuccess(state, action) {
      state.isInitialized = true;
      state.branches = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getBranchesSuccess,
  createBranchSuccess,
  updateBranchSuccess,
  deleteBranchSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getBranches() {
  const companyId = localStorage.getItem('companyId');

  return async (dispatch: any) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(`companies/${companyId}/branch`);
      dispatch(getBranchesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function createBranch(branch: UpdateBranch) {
  const companyId = localStorage.getItem('companyId');
  try {
    startLoading();
    const response = await axios.post(`companies/${companyId}/branch`, branch);
    createBranchSuccess(response.data);
  } catch (error) {
    hasError(error);
  }
}

export async function updateBranch(branch: UpdateBranch) {
  const companyId = localStorage.getItem('companyId');
  const { id, ...rest } = branch;
  try {
    startLoading();
    const response = await axios.patch(`companies/${companyId}/branch/${id}`, rest);
    updateBranchSuccess(response.data);
  } catch (error) {
    hasError(error);
  }
}

export function deleteBranch(branchId: number) {
  const companyId = localStorage.getItem('companyId');
  return async (dispatch: any) => {
    try {
      dispatch(startLoading());
      const response = await axios.delete(`companies/${companyId}/branch/${branchId}`);
      dispatch(deleteBranchSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function updateBranchSetting(branchId: number, data: any) {
  const companyId = localStorage.getItem('companyId');
  try {
    startLoading();
    const response = await axios.patch(`companies/${companyId}/branch/${branchId}/settings`, data);
    updateBranchSuccess(response.data);
  } catch (error) {
    hasError(error);
  }
}

export function setBranchAdmin(branchId: number, userId: number) {
  const companyId = localStorage.getItem('companyId');
  return async (dispatch: any) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(`companies/${companyId}/branch-admin/${branchId}/${userId}`);
      dispatch(updateBranchSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
