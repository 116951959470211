import { createSlice } from '@reduxjs/toolkit';
// utils
import { CustomersPaymentsState } from '../../../../@types/salesReport/customersPayments';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

const initialState: CustomersPaymentsState = {
  isLoading: false,
  error: null,
  payments: [],
};

const slice = createSlice({
  name: 'customersPayments',
  initialState,
  reducers: {
    getCustomersPaymentsStart(state) {
      state.isLoading = true;
    },
    getCustomersPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },
    getCustomersPaymentsError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomersPayments(from: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.getCustomersPaymentsStart());
    try {
      const response = await axios.post(`/customers-reports/${companyId}`, { from });
      dispatch(slice.actions.getCustomersPaymentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getCustomersPaymentsError(error.message));
    }
  };
}
