import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
import { useEffect } from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { getTransferRequests } from 'src/redux/slices/transferRequest';
import { dispatch, useSelector } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import TransferRequestInvoice from './details';

// ----------------------------------------------------------------------

export default function TransferRequestDetails() {
  const { themeStretch } = useSettings();

  const { id } = useParams();

  const { translate } = useLocales();

  const { transferRequests } = useSelector((state) => state.transferRequests);

  useEffect(() => {
    dispatch(getTransferRequests());
  }, []);

  const invoice = transferRequests.find(
    (transferRequest) => parseInt(transferRequest.id as string) === parseInt(id as string)
  );

  return (
    <Page title={translate('inventory.transfers_requests.transfer_request_details')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.transfers_requests.transfer_request_details')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('inventory.transfers_requests.transfer_request_details'),
              href: PATH_DASHBOARD.inventory.transfersRequests,
            },
            // { name: `INV-${invoice?.invoiceNumber}` || '' },
          ]}
        />

        <TransferRequestInvoice invoice={invoice} />
      </Container>
    </Page>
  );
}
