import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
// translations
import useLocales from '../../../../hooks/useLocales';
// components
import { Branch } from 'src/@types/branch';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------
const INPUT_WIDTH = 160;

type Props = {
  filterName: string;
  onFilterName: (value: string) => void;
  filterBranch: string;
  onFilterBranch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  branches: Branch[];
};

export default function MenuTableToolbar({
  filterName,
  onFilterName,
  filterBranch,
  onFilterBranch,
  branches,
}: Props) {
  const { translate } = useLocales();

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        select
        label={translate('nav.branches')}
        value={filterBranch}
        onChange={onFilterBranch}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        <MenuItem
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
          value="all"
        >
          {translate('orders.all')}
        </MenuItem>
        {branches.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={translate('menus.search_for_menus')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
