import { createSlice } from '@reduxjs/toolkit';
// types
import { PointOfSaleState, UpdatePointOfSale } from '../../@types/pointOfSale';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: PointOfSaleState = {
  isInitialized: false,
  pointOfSales: [],
};

const slice = createSlice({
  name: 'pointOfSales',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
    },

    // GET POINT OF SALES
    getPointOfSalesSuccess(state, action) {
      state.isInitialized = true;
      state.pointOfSales = action.payload;
    },

    // CREATE POINT OF SALE
    createPointOfSaleSuccess(state, action) {
      state.isInitialized = true;
      state.pointOfSales = [...state.pointOfSales, action.payload];
    },

    // UPDATE POINT OF SALE
    updatePointOfSaleSuccess(state, action) {
      state.isInitialized = true;
      state.pointOfSales = state.pointOfSales.map((pointOfSale) =>
        pointOfSale.id === action.payload.id ? action.payload : pointOfSale
      );
    },

    // DELETE POINT OF SALE
    deletePointOfSaleSuccess(state, action) {
      state.isInitialized = true;
      state.pointOfSales = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getPointOfSalesSuccess,
  createPointOfSaleSuccess,
  updatePointOfSaleSuccess,
  deletePointOfSaleSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPointOfSales() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await axios.get(`/points-of-sale/by-company/${companyId}`);
      dispatch(getPointOfSalesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getPointOfSaleById(branchId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/points-of-sale/${branchId}`);
      dispatch(getPointOfSalesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function createPointOfSale(pointOfSale: UpdatePointOfSale) {
  startLoading();
  const { branchId, ...rest } = pointOfSale;
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`/points-of-sale/${branchId}`, rest);
      dispatch(createPointOfSaleSuccess(response.data));
    } catch (error) {
      hasError(error);
    }
  };
}

export async function updatePointOfSale(pointOfSale: UpdatePointOfSale) {
  startLoading();
  const { id, branchId, ...rest } = pointOfSale;
  return async (dispatch: any) => {
    try {
      const response = await axios.patch(`/points-of-sale/${branchId}/${id}`, rest);
      dispatch(updatePointOfSaleSuccess(response.data));
    } catch (error) {
      hasError(error);
    }
  };
}

export function deletePointOfSale(pointOfSaleId: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());

    try {
      const response = await axios.delete(`/points-of-sale/${pointOfSaleId}`);
      dispatch(deletePointOfSaleSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
