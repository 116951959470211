import { Container } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { getMenus } from 'src/redux/slices/menu';
import { dispatch, useSelector } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import MenuPreview from './details';

export default function MenuViewDetails() {
  const { themeStretch } = useSettings();

  const { id } = useParams();

  const { translate } = useLocales();

  const { menus } = useSelector((state) => state.menus);

  useEffect(() => {
    dispatch(getMenus());
  }, []);

  const menu = menus.find((menu) => menu.id === parseInt(id as string));

  return (
    <Page title={translate('menus.title')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('menus.title')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('menus.title'),
              href: PATH_DASHBOARD.orders.menu.list,
            },
            // { name: `INV-${invoice?.invoiceNumber}` || '' },
          ]}
        />

        <MenuPreview menu={menu} />
      </Container>
    </Page>
  );
}
