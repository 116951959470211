import { createSlice } from '@reduxjs/toolkit';
// utils
import { Supplier, SupplierState } from '../../@types/supplier';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: SupplierState = {
  isLoading: false,
  error: null,
  suppliers: [],
  supplier: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Suppliers
    getSuppliersSuccess(state, action) {
      state.isLoading = false;
      state.suppliers = action.payload;
    },

    // GET Supplier
    getSupplierSuccess(state, action) {
      state.isLoading = false;
      state.supplier = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getSuppliers() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { suppliers: Supplier[] } } = await axios.get(
        `/suppliers/${companyId}`
      );
      dispatch(slice.actions.getSuppliersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSupplier(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { supplier: Supplier } } = await axios.get('/suppliers', {
        params: { name },
      });
      dispatch(slice.actions.getSupplierSuccess(response.data.supplier));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export add supplier function
export function addSupplier(supplier: Supplier) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/suppliers/${companyId}`, supplier);
      dispatch(getSuppliers());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export update supplier function
export function updateSupplier(id: string, supplier: Supplier) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/suppliers/${companyId}/${id}`, supplier);
      dispatch(getSuppliers());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete supplier function
export function deleteSupplier(id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/suppliers/${companyId}/${id}`);
      dispatch(getSuppliers());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
