import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
// utils
// @types
// components
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../../components/hook-form';
import { CustomFile } from '../../../../components/upload';
// translate
import useLocales from '../../../../hooks/useLocales';
// redux
import { CreateTax, Tax } from 'src/@types/tax';
import { addTax, updateTax } from 'src/redux/slices/tax';
import { dispatch } from '../../../../redux/store';

interface FormValuesProps extends Omit<Tax, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
  rate: number;
}

type Props = {
  isEdit: boolean;
  currentTax?: Tax | null;
  handleClose?: VoidFunction;
};

export default function TaxesCreateEditForm({ isEdit, currentTax, handleClose }: Props) {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const NewPointOfSaleSchema = Yup.object().shape({
    name: Yup.string().required(translate('errors.name_required')),
    nameLocalized: Yup.string().required(translate('errors.name_required')),
    isDefault: Yup.boolean(),
    rate: Yup.number().required(translate('errors.rate_required')),
    branchId: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentTax?.name || '',
      nameLocalized: currentTax?.nameLocalized || '',
      isDefault: currentTax?.isDefault || false,
      rate: currentTax?.rate || 0,
    }),
    [currentTax]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPointOfSaleSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentTax) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentTax, reset, defaultValues]);

  const onSubmit = async (data: FormValuesProps) => {
    if (isEdit && currentTax) {
      try {
        data.id = currentTax.id;
        dispatch(updateTax(data.id, data));
        reset();
        handleClose && handleClose();
        enqueueSnackbar(translate('messages.update_success'));
        //window.location.reload();
      } catch (error) {
        enqueueSnackbar(translate('messages.update_error'));
      }
    }
    if (!isEdit) {
      try {
        const tax: CreateTax = {
          name: data.name,
          nameLocalized: data.nameLocalized,
          isDefault: data.isDefault,
          rate: data.rate,
        };
        dispatch(addTax(tax));
        reset();
        handleClose && handleClose();
        enqueueSnackbar(translate('messages.create_success'));
        //window.location.reload();
      } catch (error) {
        enqueueSnackbar(translate('messages.create_error'));
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="name" label={translate('company.taxes.name')} />
              <RHFTextField
                name="nameLocalized"
                label={translate('company.taxes.name_localized')}
              />
              <RHFTextField type="number" name="rate" label={translate('company.taxes.rate')} />
              <RHFCheckbox name="isDefault" label={translate('company.taxes.is_default')} />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? translate('general.create') : translate('general.edit')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
