// @mui
import { Container, Grid } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// _mock_
// components
import Page from '../../../components/Page';
// sections
import { AppAreaInstalled, AppCurrentDownload } from '../../../sections/@dashboard/general/app';
// assets
import { Key, useEffect, useState } from 'react';

import { getBranches } from '../../../redux/slices/branch';
import { getPointOfSaleById } from '../../../redux/slices/pointOfSale';
import { getBranchReports, getPointOfSaleReports } from '../../../redux/slices/report';
import { useDispatch, useSelector } from '../../../redux/store';

import useLocales from '../../../hooks/useLocales';
import SalesReportsViewToolbar from '../../../sections/@dashboard/general/app/SalesReportsViewToolbar';
import { BankingExpensesCategories } from '../../../sections/@dashboard/general/banking';

// ----------------------------------------------------------------------

export default function SalesReports() {
  const { themeStretch } = useSettings();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { branchReports, pointOfSaleReports } = useSelector((state) => state.report);

  const { branches } = useSelector((state) => state.branches);

  const { pointOfSales } = useSelector((state) => state.pointsOfSale);

  console.log(pointOfSales);

  const [filterBranch, setFilterBranch] = useState<number | null>(null);

  const [filterPos, setFilterPos] = useState<number | null>(null);

  const [to, setTo] = useState<Date | null>(new Date());

  const [from, setFrom] = useState<Date | null>(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  function onFilterBranchChange(event: React.ChangeEvent<{ value: unknown }>) {
    setFilterBranch(event.target.value as number);
  }

  function onFilterPosChange(event: React.ChangeEvent<{ value: unknown }>) {
    setFilterPos(event.target.value as number);
  }

  useEffect(() => {
    new Promise((resolve) => {
      if (!filterBranch) dispatch(getBranches());
      resolve(true);
    }).then(() => {
      if (!filterBranch) setFilterBranch(branches ? branches[0].id : 0);
    });
  }, [branches, dispatch, filterBranch]);

  useEffect(() => {
    dispatch(getPointOfSaleById(filterBranch + ''));
  }, [dispatch, filterBranch]);

  useEffect(() => {
    getBranchReports(filterBranch, from, to);
  }, [dispatch, filterBranch, from, to]);

  useEffect(() => {
    if (filterPos) {
      getPointOfSaleReports(filterPos, from, to);
    }
  }, [filterPos, from, to]);

  console.log(pointOfSaleReports);

  return (
    <Page title="General: App">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <SalesReportsViewToolbar
              branchesOptions={branches}
              posOptions={pointOfSales}
              filterBranch={filterBranch}
              filterPos={filterPos}
              onFilterBranch={onFilterBranchChange}
              onFilterPos={onFilterPosChange}
              filterStartDate={from}
              filterEndDate={to}
              onFilterStartDate={(newValue) => {
                setFrom(newValue);
              }}
              onFilterEndDate={(newValue) => {
                setTo(newValue);
              }}
            />
          </Grid>

          {branchReports.salesSummaryReport &&
            branchReports.salesSummaryReport.map(
              (
                item: {
                  name: string | undefined;
                  data: {
                    chartLabels: string[];
                    data: { label: string; data: { name: string; data: number[] }[] }[];
                  };
                },
                index: Key | null | undefined
              ) => (
                <Grid key={index} item xs={6} md={4} lg={4}>
                  <AppAreaInstalled
                    title={item.name}
                    // get Total of items.data.data
                    subheader={` `}
                    chartLabels={item.data.chartLabels}
                    chartData={item.data.data}
                  />
                </Grid>
              )
            )}

          {branchReports.categorySalesReport && (
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentDownload
                title={translate('reports.general.category_sales')}
                chartData={branchReports.categorySalesReport.categories.map(
                  (item: { name: any; income: any }) => ({
                    label: item.name,
                    value: item.income,
                  })
                )}
              />
            </Grid>
          )}

          {branchReports.itemSalesReport && (
            <Grid item xs={12} md={12} lg={12}>
              <BankingExpensesCategories
                title={translate('reports.general.item_sales')}
                chartData={branchReports.itemSalesReport.items.map(
                  (item: { name: any; income: any }) => ({
                    label: item.name,
                    value: item.income,
                  })
                )}
                chartColors={[]}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
