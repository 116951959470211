import { createSlice } from '@reduxjs/toolkit';
// types
import { UserAccount, UsersState } from '../../@types/user';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: UsersState = {
  isInitialized: false,
  error: null,
  users: [],
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isInitialized = true;
      state.users = action.payload;
    },

    // CREATE USER
    createUserSuccess(state, action) {
      state.isInitialized = true;
      state.users = action.payload;
    },

    // UPDATE USER
    updateUserSuccess(state, action) {
      state.isInitialized = true;
      state.users = action.payload;
    },

    // DELETE USER
    deleteUserSuccess(state, action) {
      state.isInitialized = true;
      state.users = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getUsersSuccess,
  createUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await axios.get(`companies/${companyId}/memberships`);
      dispatch(getUsersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function createUser(user: UserAccount) {
  startLoading();
  const companyId = localStorage.getItem('companyId');
  delete user.prefersEmail;
  delete user.profilePictureUrl;
  delete user.active;
  try {
    const response = await axios.post(`companies/${companyId}/memberships`, user);
    createUserSuccess(response.data);
  } catch (error) {
    hasError(error);
    return error;
  }
}

export async function updateUser(data: UserAccount, membershipsId: number | undefined) {
  startLoading();
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await axios.patch(`companies/${companyId}/memberships/${membershipsId}`, {
      branchId: data.branchId,
      scopRoleId: data.scopRoleId,
    });
    updateUserSuccess(response.data);
  } catch (error) {
    hasError(error);
    return error;
  }
}

export function deleteUser(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/users/${id}`);
      dispatch(deleteUserSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
