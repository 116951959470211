import { useState } from 'react';
// @mui
import { Checkbox, MenuItem, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// @types
// components
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';
// translations
import { Can } from '@casl/react';
import { Branch } from 'src/@types/branch';
import { Menu } from 'src/@types/menu';
import ability from 'src/utils/ability';
import useLocales from '../../../../hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  id: string;
  row: Menu;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onViewRow: VoidFunction;
  branches: Branch[];
};

export default function MenuTableRow({
  id,
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  onViewRow,
  branches,
}: Props) {
  const theme = useTheme();

  const { translate } = useLocales();

  const { name, isActive, branchId, url } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected} id={id}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ alignItems: 'center' }}>{name}</TableCell>

      {/* <TableCell align="center">{url}</TableCell> */}

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(isActive === false && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {(isActive === false && translate('users_management.users.inactive')) ||
            translate('users_management.users.active')}
        </Label>
      </TableCell>

      {/* get branch name */}
      <TableCell align="center">
        {branches.find((branch) => branch.id === branchId)?.name}
      </TableCell>

      <Can I="write" a="menu" ability={ability}>
        {() => (
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {/* <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                {translate('users_management.users.delete')}
              </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      onEditRow();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} />
                    {translate('users_management.users.edit')}
                  </MenuItem>
                  {url !== null ? (
                    <MenuItem
                      onClick={() => {
                        onViewRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:eye-fill'} />
                      {translate('users_management.users.view')}
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <Iconify icon={'eva:cloud-upload-outline'} />
                      {translate('users_management.users.loading')}
                    </MenuItem>
                  )}
                </>
              }
            />
          </TableCell>
        )}
      </Can>
    </TableRow>
  );
}
