import { Product } from './product';
import { Supplier } from './supplier';
import { Warehouse } from './warehouse';

// defining create purchase state type
export type PurchaseOrderState = {
  isLoading: boolean;
  error: Error | string | null;
  purchaseOrders: PurchaseOrder[] | [];
  purchaseOrder: PurchaseOrder | null;
  sortBy: string | null;
};

// export type purchase order
export type PurchaseOrder = {
  id: string;
  date: Date;
  number: number;
  expiredDate: Date;
  supplierId: String;
  supplier?: Supplier;
  warehouseId: number;
  warehouse?: Warehouse;
  inventoryId: number;
  inventory?: Warehouse;
  purchaseItems: PurchaseOrderItem[];
  status: PurchaseOrderStatus;
  term: PurchaseOrderTerm;
  purchaseInvoice: PurchaseOrderInvoice;
  total: number;
  totalTax?: number;
  totalWithTax: number;
};

export interface PurchaseOrderItem {
  id: string;
  productId: string;
  item: Product;
  qty: number;
  price: number;
  total: number;
}

export enum PurchaseOrderStatus {
  INITIAL = 'INITIAL',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export enum PurchaseOrderTerm {
  IMMEDIATE = 'IMMEDIATE',
  AFTER_7_DAYS = 'AFTER_7_DAYS',
  AFTER_10_DAYS = 'AFTER_10_DAYS',
  AFTER_30_DAYS = 'AFTER_30_DAYS',
  AFTER_60_DAYS = 'AFTER_60_DAYS',
  AFTER_90_DAYS = 'AFTER_90_DAYS',
}

export type PurchaseOrderInvoice = {
  id: number;
  invoiceDate: Date;
  invoiceNumber: number;
  purchaseItems: PurchaseOrderItem[];
  status: PurchaseOrderInvoiceStatus;
  term: PurchaseOrderTerm;
  total: number;
  totalTax?: number;
};

export enum PurchaseOrderInvoiceStatus {
  INITIAL = 'INITIAL',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  PENDING = 'PENDING',
}
