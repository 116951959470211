import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import {
  isValidToken,
  setAbility,
  setCompanyId,
  setDefaultBranchId,
  setDefaultWarehouseId,
  setRefreshToken,
  setSession,
  setUserId,
} from '../utils/jwt';
// @types
import LogRocket from 'logrocket';
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Refresh = 'REF',
  Login = 'LOGIN',
  Forgot = 'FORGOT',
  Verify = 'VERIFY',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Update = 'UPDATE',
  Image = 'IMAGE',
  Password = 'PASSWORD',
  Resend = 'RESEND',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Refresh]: undefined;
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Forgot]: undefined;
  [Types.Resend]: undefined;
  [Types.Verify]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.Update]: {
    user: AuthUser;
  };
  [Types.Image]: {
    user: AuthUser;
  };
  [Types.Password]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'REF':
      return {
        ...state,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'FORGOT':
      return {
        ...state,
      };
    case 'RESEND':
      return {
        ...state,
      };
    case 'VERIFY':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case 'REGISTER':
      return {
        ...state,
        // isAuthenticated: true,
        user: action.payload.user,
      };
    case 'UPDATE':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'IMAGE':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'PASSWORD':
      return {
        ...state,
        user: action.payload.user,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`users/${userId}`);
          const { data } = response;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: data,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const refresh = async () => {
    try {
      const oldRefreshToken = localStorage.getItem('refreshToken');
      const response = await axios.post(`auth/refresh`, { token: oldRefreshToken });

      const { accessToken, refreshToken, scopes } = response.data;

      setSession(accessToken);
      setRefreshToken(refreshToken);
      setAbility(scopes);
    } catch (err) {
      console.error(err);
    }
  };

  const login = async (email: string, password: string) => {
    const response = await axios.post('auth/login', {
      email,
      password,
    });
    const { accessToken, userData, refreshToken, scopes } = response.data;

    setSession(accessToken);
    setRefreshToken(refreshToken);
    setUserId(userData.id);
    setAbility(scopes);

    if (userData?.companyMemberships[0]?.company) {
      // This is an example script - don't forget to change it!
      LogRocket.identify(userData?.id, {
        name: userData?.name,
        email: userData?.email,

        // Add your own custom user variables here, ie:
        company: userData?.companyMemberships[0]?.company.name,
      });
      setCompanyId(userData?.companyMemberships[0]?.companyId);
      setDefaultBranchId(userData?.companyMemberships[0].company.branches[0].id);
      // setDefaultWarehouseId(userData?.companyMemberships[0]?.company.branchs[0]?.warehouses[0]?.id);
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } else {
      window.location.reload();
    }

    dispatch({
      type: Types.Login,
      payload: {
        user: userData,
      },
    });
  };

  const register = async (email: string, password: string, name: string, phone: string) => {
    await axios.post('auth/register', {
      email,
      password,
      name,
      phone,
    });

    // const response = await axios.post('auth/login', {
    //   email,
    //   password,
    // });

    // const { accessToken, userData, scopes } = response.data;

    // setSession(accessToken);
    // setUserId(userData.id);
    // setAbility(scopes);

    dispatch({
      type: Types.Register,
      payload: {
        user: null,
      },
    });
  };

  const forgotPassword = async (email: string) => {
    await axios.post('auth/forgot-password', {
      email,
    });
  };

  const verifyCode = async (email: string, password: string, code: number) => {
    const response = await axios.post('auth/reset-password', {
      email,
      password,
      code,
    });

    const { accessToken, userData, refreshToken } = response.data;

    setSession(accessToken);
    setRefreshToken(refreshToken);
    setUserId(userData.id);

    if (userData?.companyMemberships[0]?.company) {
      // This is an example script - don't forget to change it!
      LogRocket.identify(userData?.id, {
        name: userData?.name,
        email: userData?.email,

        // Add your own custom user variables here, ie:
        company: userData?.companyMemberships[0]?.company.name,
      });
      setCompanyId(userData?.companyMemberships[0]?.companyId);
      setDefaultBranchId(userData?.companyMemberships[0].company.branches[0].id);
      // setDefaultWarehouseId(userData?.companyMemberships[0]?.company.branchs[0]?.warehouses[0]?.id);
    } else {
      window.location.reload();
    }

    dispatch({
      type: Types.Register,
      payload: {
        user: userData,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    setRefreshToken(null);
    setUserId(null);
    setCompanyId(null);
    setDefaultBranchId(null);
    setDefaultWarehouseId(null);
    setAbility(null);
    dispatch({ type: Types.Logout });
  };

  const updateProfile = async (name: string, phone: string | null) => {
    const id = localStorage.getItem('userId');
    const response = await axios.patch(`users/${id}`, { name, phone });
    const { data } = response;
    dispatch({
      type: Types.Register,
      payload: {
        user: data,
      },
    });
  };

  const updateProfilePicture = async (files: FormData) => {
    const id = localStorage.getItem('userId');
    console.log(files.get('files'));
    const response = await axios.patch(`users/${id}/profile-picture`, files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const { data } = response;
    dispatch({
      type: Types.Register,
      payload: {
        user: data,
      },
    });
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    const id = localStorage.getItem('userId');

    const response = await axios.patch(`users/${id}`, { currentPassword, newPassword });
    const { data } = response;
    dispatch({
      type: Types.Register,
      payload: {
        user: data,
      },
    });
  };

  const resendVerificationEmail = async (email: string) => {
    await axios.post('auth/resend-email-verification', {
      email,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        refresh,
        login,
        forgotPassword,
        resendVerificationEmail,
        verifyCode,
        logout,
        register,
        updateProfile,
        updateProfilePicture,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
