// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Button, Divider, Stack, Typography } from '@mui/material';
// hooks
import { useEffect } from 'react';
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import useToggle from 'src/hooks/useToggle';
import { getSuppliers } from 'src/redux/slices/supplier';
import { getPurchaseInventories } from 'src/redux/slices/warehouse';
import { useDispatch, useSelector } from 'src/redux/store';
import PurchaseSupplierListDialog from './PurchaseSupplierListDialog';
import PurchaseWarehouseListDialog from './PurchaseWarehouseListDialog';

export default function PurchaseNewEditAddress() {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();

  const { suppliers } = useSelector((state) => state.supplier);

  const { purchaseInventories } = useSelector((state) => state.warehouses);
  const upMd = useResponsive('up', 'md');

  const values = watch();

  const { toggle: openFrom, onOpen: onOpenFrom, onClose: onCloseFrom } = useToggle();

  const { toggle: openTo, onOpen: onOpenTo, onClose: onCloseTo } = useToggle();

  const { fromSupplier, toWarehouse } = values;

  const { translate } = useLocales();

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPurchaseInventories());
  }, [dispatch]);

  return (
    <Stack
      spacing={{ xs: 2, md: 5 }}
      direction={{ xs: 'column', md: 'row' }}
      divider={
        <Divider
          flexItem
          orientation={upMd ? 'vertical' : 'horizontal'}
          sx={{ borderStyle: 'dashed' }}
        />
      }
      sx={{ p: 3 }}
    >
      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            {translate('inventory.purchase_orders.actions.from_supplier')}
          </Typography>

          <Button size="small" startIcon={<Iconify icon="eva:edit-fill" />} onClick={onOpenFrom}>
            {translate('inventory.purchase_orders.actions.Change')}
          </Button>

          {suppliers && (
            <PurchaseSupplierListDialog
              open={openFrom}
              onClose={onCloseFrom}
              selected={(selectedId: string) => fromSupplier?.id === selectedId}
              onSelect={(address) => setValue('fromSupplier', address)}
              addressOptions={suppliers}
            />
          )}
        </Stack>

        {fromSupplier && (
          <AddressInfo
            name={fromSupplier.name}
            address={fromSupplier.address}
            phone={fromSupplier.phone || 'N/A'}
          />
        )}
      </Stack>

      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            {translate('inventory.purchase_orders.actions.to_warehouse')}
          </Typography>

          <Button
            size="small"
            startIcon={<Iconify icon={toWarehouse ? 'eva:edit-fill' : 'eva:plus-fill'} />}
            onClick={onOpenTo}
          >
            {toWarehouse
              ? translate('inventory.purchase_orders.actions.Change')
              : translate('inventory.purchase_orders.actions.Add')}
          </Button>

          <PurchaseWarehouseListDialog
            open={openTo}
            onClose={onCloseTo}
            selected={(selectedId: string) => toWarehouse?.id === selectedId}
            onSelect={(address) => setValue('toWarehouse', address)}
            addressOptions={purchaseInventories || []}
          />
        </Stack>

        {toWarehouse ? (
          <AddressInfo
            name={toWarehouse.name}
            address={toWarehouse.address}
            phone={toWarehouse.phone}
          />
        ) : (
          <Typography typography="caption" sx={{ color: 'error.main' }}>
            {(errors.toWarehouse as any)?.message}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

type AddressInfoProps = {
  name: string;
  address: string;
  phone: string;
};

function AddressInfo({ name, address, phone }: AddressInfoProps) {
  return (
    <>
      <Typography variant="subtitle2">{name}</Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
        {address}
      </Typography>
      <Typography variant="body2">{phone}</Typography>
    </>
  );
}
