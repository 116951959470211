import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  useTheme,
} from '@mui/material';
// redux
import { getReports } from '../../../../redux/slices/eotd';
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
import useTable, { emptyRows, getComparator } from '../../../../hooks/useTable';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
  TableSkeleton,
} from '../../../../components/table';
// sections

//useLocales
import { Eotd } from 'src/@types/eotd';
import useLocales from '../../../../hooks/useLocales';
import EotdTableRow from '../components/EotdTableRow';
import EotdTableToolbar from '../components/EotdTableToolbar';
import Joyride, { Step } from 'react-joyride';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'createdAt', label: 'eotd.openedAt', align: 'left' },
  { id: 'closedAt', label: 'eotd.closedAt', align: 'left' },
  { id: 'cashIn', label: 'eotd.cashIn', align: 'left' },
  { id: 'cashOut', label: 'eotd.cashOut', align: 'left' },
  { id: 'expectedCashInClose', label: 'eotd.expectedCashInClose', align: 'left' },
  { id: 'cashDiff', label: 'eotd.difference', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function EndOfDayReports() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch , firstLogin } = useSettings();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { translate } = useLocales();

  const [{ run, steps }, setState] = useState({
    run: firstLogin,
    steps: [
      {
        title: translate('tourSteps.eotd.title') as string,
        content: <h2>{
          translate('tourSteps.eotd.description')
          }</h2>,
          locale: { skip: <strong>{
            translate('tourSteps.general.skip')
            }</strong>, 
            next: <strong>{
              translate('tourSteps.general.next')
              }</strong>, 
              last: <strong>{
                translate('tourSteps.general.finish')
                }</strong>, 
                back: <strong>{
                  translate('tourSteps.general.back')
                  }</strong>,
          },
        target: "body",
        placement: "center",
      },
      {
        content: (
          <h2>
            {translate('tourSteps.eotd.table')}
          </h2>
        ),
        target: '#step-1',
        title: translate('tourSteps.eotd.title') as string,
        locale: { skip: <strong>{
          translate('tourSteps.general.skip')
          }</strong>, 
          next: <strong>{
            translate('tourSteps.general.next')
            }</strong>, 
            last: <strong>{
              translate('tourSteps.general.finish')
              }</strong>, 
              back: <strong>{
                translate('tourSteps.general.back')
                }</strong>,
        },
      },
      {
        content: <h2>{
          translate('tourSteps.eotd.viewEotd')
          }</h2>,
        target: "#step-2",
        title: translate('tourSteps.eotd.title') as string,
        locale: { skip: <strong>{
          translate('tourSteps.general.skip')
          }</strong>, 
          next: <strong>{
            translate('tourSteps.general.next')
            }</strong>, 
            last: <strong>{
              translate('tourSteps.general.finish')
              }</strong>, 
              back: <strong>{
                translate('tourSteps.general.back')
                }</strong>,
        },
      },
    ]
  });


  const [tourStep, setTourStep] = useState(0);

  const { reports, isLoading } = useSelector((state) => state.eotd);

  const [tableData, setTableData] = useState<any[]>([]);

  const [filterName, setFilterName] = useState('');

  const theme = useTheme();

  useEffect(() => {
    dispatch(getReports());
  }, [dispatch]);

  useEffect(() => {
    setTableData(reports);
  }, [reports]);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleViewRow = (id: string) => {
    navigate(PATH_DASHBOARD.orders.viewEotd(paramCase(id.toString())));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = dense ? 60 : 80;

  const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoading && !dataFiltered.length);

  return (
    <Page title={translate('nav.eotd')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('nav.eotd')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: translate('nav.eotd') },
          ]}
          // action={
          //   <Button
          //     variant="contained"
          //     startIcon={<Iconify icon="eva:plus-fill" />}
          //     component={RouterLink}
          //     to={PATH_DASHBOARD.inventory.createCategory}
          //   >
          //     {translate('inventory.categories.creat')}
          //   </Button>
          // }
        />

        <Card>
          <EotdTableToolbar filterName={filterName} onFilterName={handleFilterName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody id="step-1">
                  {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                      row ? (
                        <EotdTableRow
                          id="step-2"
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleViewRow(row.id)}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
      <Joyride
        steps={steps as Step[]} // Ensure the type compatibility
        run={run}
        stepIndex={tourStep}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={(data) => {
          console.log(data)
          const { status, type } = data;
          if (status === 'finished' || status === 'skipped' || type === 'tour:end') {
            setState({ run: false, steps });
          }
          if (status === 'running' && type === 'step:after') {
            setTourStep((prevIndex) => prevIndex + 1);
          }
  }}

  styles={{
    options: {
      arrowColor: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: theme.palette.primary.main,
      textColor: theme.palette.text.secondary,
      width: 700,
      zIndex: 1000,
    },
  }}
/>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: Eotd[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  // if (filterName) {
  //   tableData = tableData.filter((row) => row.id === filterName);
  // }

  return tableData;
}
