import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
// utils
// components
import { FormProvider } from '../../../../../components/hook-form';
// translate
import useLocales from '../../../../../hooks/useLocales';
// redux
import { TransferRequest } from 'src/@types/transferRequest';
import { paySaleOrder } from '../../../../../redux/slices/saleOrder';
import { useDispatch } from '../../../../../redux/store';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
  paymentMethod: string;
  paid: number;
}

type Props = {
  id: string;
  currentInvoice?: TransferRequest;
};

export default function ApproveTransfer({ id, currentInvoice }: Props) {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({
      transferItems: currentInvoice?.transferItems,
    }),
    [currentInvoice?.transferItems]
  );

  const methods = useForm<FormValuesProps>({
    // resolver: yupResolver(NewPointOfSaleSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      console.log(data);
      await dispatch(paySaleOrder(id, data));
      reset();
      enqueueSnackbar(translate('messages.create_success'));
      //window.location.reload();
    } catch (error) {
      enqueueSnackbar(translate('messages.create_error'));
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              Working on this part
              {/* <RHFTextField
                name="paid"
                type="number"
                label={translate('accounting.invoicing.paid_amount')}
                disabled
              /> */}
              {/* <RHFSelect
                InputLabelProps={{
                  shrink: true,
                }}
                name="paymentMethod"
                label={translate('accounting.invoicing.payment_method')}
                SelectProps={{
                  native: false,
                  sx: { textTransform: 'capitalize' },
                }}
              >
                <MenuItem>{translate('accounting.invoicing.choose_payment_method')}</MenuItem>
                {PAYMENT_METHODS &&
                  PAYMENT_METHODS?.map((method) => (
                    <MenuItem key={method} value={method}>
                      {translate(`accounting.invoicing.${method}`)}
                    </MenuItem>
                  ))}
              </RHFSelect> */}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {translate('general.create')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
