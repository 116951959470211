import { createSlice } from '@reduxjs/toolkit';
import { SaleOrder, SaleOrderState } from 'src/@types/saleOrder';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: SaleOrderState = {
  isLoading: false,
  error: null,
  saleOrders: [],
  saleOrder: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'saleOrder',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Sale Orders Success
    getSaleOrdersSuccess(state, action) {
      state.isLoading = false;
      state.saleOrders = action.payload;
    },

    // GET Sale Order Success
    getSaleOrderSuccess(state, action) {
      state.isLoading = false;
      state.saleOrder = action.payload;
    },

    // UPDATE Sale Order Success
    updateSaleOrderSuccess(state, action) {
      state.isLoading = false;
      state.saleOrders = state.saleOrders.map((saleOrder) => {
        if (saleOrder.id === action.payload.id) {
          return action.payload;
        }
        return saleOrder;
      });
    },
    // CREATE Sale Order Success
    createSaleOrderSuccess(state, action) {
      state.isLoading = false;
      state.saleOrders = [...state.saleOrders, action.payload];
    },
  },
});
// Reducer
export default slice.reducer;

// Actions

const {
  startLoading,
  hasError,
  getSaleOrdersSuccess,
  getSaleOrderSuccess,
  updateSaleOrderSuccess,
  createSaleOrderSuccess,
} = slice.actions;

export function getSaleOrders(filterStartDate: Date | null, filterEndDate: Date | null) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(startLoading());
    try {
      const response: { data: { saleOrder: SaleOrder[] } } = await axios.post(
        `/sale-orders/get/${defaultBranchId}`,
        {
          fromDate: filterStartDate,
          toDate: filterEndDate,
        }
      );
      dispatch(getSaleOrdersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getSaleOrdersByBranchId(defaultBranchId: number) {
  return async () => {
    dispatch(startLoading);
    try {
      const response: { data: { saleOrder: SaleOrder[] } } = await axios.get(
        `/sale-orders/get/${defaultBranchId}/`
      );
      dispatch(getSaleOrdersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSaleOrder(name: string) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { saleOrder: SaleOrder } } = await axios.get('/sale-orders', {
        params: { name },
      });
      dispatch(getSaleOrderSuccess(response.data.saleOrder));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}
// export add sale order
export function addSaleOrder(saleOrder: any) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(startLoading());
    try {
      const response = await axios.post(`/sale-orders/${defaultBranchId}`, saleOrder);
      dispatch(createSaleOrderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

export function paySaleOrder(
  id: string,
  payload: {
    paymentMethod: string;
    paid: number;
  }
) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/sale-orders/${defaultBranchId}/pay/${id}`, payload);
      dispatch(updateSaleOrderSuccess(response.data.saleOrder));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

export function cancelSaleOrder(id: string) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { saleOrder: SaleOrder } } = await axios.get(
        `/sale-orders/${defaultBranchId}/cancelled/${id}`
      );
      dispatch(updateSaleOrderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}
