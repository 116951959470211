// @mui
import { Box, Card, Divider, Grid, TableContainer, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import QRCode from 'react-qr-code';
import { Menu } from 'src/@types/menu';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import Image from '../../../../../components/Image';
import MenuPreviewToolbar from './MenuPreviewToolbar';

// ----------------------------------------------------------------------

type Props = {
  menu?: Menu;
};

export default function MenuPreview({ menu }: Props) {
  const theme = useTheme();
  const { user } = useAuth();
  const { translate } = useLocales();

  if (!menu) {
    return null;
  }

  const { url } = menu;

  return (
    <>
      <MenuPreviewToolbar menu={menu} />
      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'left', mb: 2 } }}>
              <Image
                src={user?.companyMemberships[0].company.profilePictureUrl}
                sx={{ height: 60, width: 60 }}
              />
            </Box>
            <Typography sx={{ ml: 4 }} variant="overline">
              {user?.companyMemberships[0].company.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }} />
          </Grid>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }} />
          {/* Scan me text with arrow  */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Typography variant="h6">{translate('menus.for_menu_pls_scan_code')}</Typography>
          </Box>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Iconify icon="ic:outline-keyboard-double-arrow-down" width={50} height={50} />
          </Box>
          {url && (
            <Box sx={{ textAlign: 'center', mb: 10 }}>
              <QRCode
                name="qrCode"
                value={url}
                size={200}
                bgColor={theme.palette.background.paper}
                fgColor={theme.palette.text.primary}
                level="L"
              />
            </Box>
          )}
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} md={7} sx={{ py: 3, textAlign: 'left' }}>
            <Typography variant="subtitle2">
              {translate('menus.generated_using')}{' '}
              {translate('accounting.invoicing.onestep_system')}{' '}
            </Typography>
            <Typography variant="body2">info@onestep.com.sa</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
