import { createSlice } from '@reduxjs/toolkit';
// types
import { CompanyState, UpdateCompany } from '../../@types/company';
// utils
import axios from '../../utils/axios';
//

// ----------------------------------------------------------------------

const initialState: CompanyState = {
  isInitialized: false,
  company: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
    },

    // GET COMPANY
    getCompanySuccess(state, action) {
      state.isInitialized = true;
      state.company = action.payload;
    },

    // UPDATE COMPANY
    updateCompanySuccess(state, action) {
      state.isInitialized = true;
      state.company = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getCompanySuccess, updateCompanySuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getCompany() {
  const companyId = localStorage.getItem('companyId');
  return async (dispatch: any) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(`companies/${companyId}`);
      dispatch(getCompanySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateCompany(company: UpdateCompany) {
  return async (dispatch: any) => {
    try {
      const { id, ...rest } = company;
      dispatch(startLoading());
      const response = await axios.patch(`companies/${id}`, rest);
      dispatch(updateCompanySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateProfilePicture(id: number, files: FormData) {
  return async (dispatch: any) => {
    try {
      dispatch(startLoading());
      const response = await axios.patch(`companies/${id}/profile-picture`, files);
      dispatch(updateCompanySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
