// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { PurchaseOrderStatus, PurchaseOrderTerm } from 'src/@types/purchaseOrder';
import RHFSelect from 'src/components/hook-form/RHFSelect';
import RHFTextField from 'src/components/hook-form/RHFTextField';
import useLocales from 'src/hooks/useLocales';
// components

// ----------------------------------------------------------------------

export default function PurchaseNewEditStatusDate() {
  const { control, watch } = useFormContext();

  const values = watch();

  const { translate } = useLocales();

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ p: 3, bgcolor: 'background.neutral' }}
    >
      <RHFTextField
        disabled
        name="invoiceNumber"
        label={translate('inventory.purchase_orders.actions.invoice_number')}
        value={`INV-${values.invoiceNumber}`}
      />

      <RHFSelect
        fullWidth
        name="status"
        label={translate('inventory.purchase_orders.actions.status')}
        InputLabelProps={{ shrink: true }}
        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
      >
        {Object.keys(PurchaseOrderStatus).map((status) => (
          <MenuItem key={status} value={status}>
            {translate(`inventory.purchase_orders.status.${status}`)}
          </MenuItem>
        ))}
      </RHFSelect>

      <Controller
        name="createDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label={translate('inventory.purchase_orders.actions.date_created')}
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
            )}
          />
        )}
      />

      {/* <Controller
        name="dueDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label="Due date"
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
            )}
          />
        )}
      /> */}

      <RHFSelect
        fullWidth
        name="term"
        label={translate('inventory.purchase_orders.actions.payment_terms')}
        InputLabelProps={{ shrink: true }}
        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
      >
        {Object.keys(PurchaseOrderTerm).map((term) => (
          <MenuItem key={term} value={term}>
            {translate(`inventory.purchase_orders.terms.${term}`)}
          </MenuItem>
        ))}
      </RHFSelect>
    </Stack>
  );
}
