import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// @mui
import { LoadingButton } from '@mui/lab';
// hooks
import useLocales from '../../../../hooks/useLocales';

import { Card, Grid, MenuItem, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// @types
// components
import { Category } from 'src/@types/category';
import { DeviceType } from 'src/@types/device';
import { getBranches } from 'src/redux/slices/branch';
import { addCategory, updateCategory } from 'src/redux/slices/category';
import { getDevicesByBranchId } from 'src/redux/slices/device';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';

interface FormValuesProps extends Omit<Category, 'category'> {
  name: string;
}

type Props = {
  isEdit?: boolean;
  currentCategory?: Category;
};

export default function CategoryNewEditForm({ isEdit, currentCategory }: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  // use dispatch to get suppliers from redux
  const dispatch = useDispatch();

  const { devices, isLoading: isLoadingDevice } = useSelector((state) => state.device);

  const { branches } = useSelector((state) => state.branches);

  const { enqueueSnackbar } = useSnackbar();

  const NewDeviceSchema = Yup.object().shape({
    name: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentCategory?.name || '',
      defaultPrinterId: currentCategory?.defaultPrinterId || null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCategory]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewDeviceSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleFilterBranch = (event: any) => {
    console.log(event.target.value);
    dispatch(getDevicesByBranchId(event.target.value));
    console.log(devices);
  };

  useEffect(() => {
    // dispatch(getDevices());
    dispatch(getBranches());
  }, [dispatch]);

  const subKitchenDevices = devices.filter((device) => device.type === DeviceType.Subkitchen);

  useEffect(() => {
    if (isEdit && currentCategory) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentCategory]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // convert data to product type
      const category: Category = {
        ...data,
      };

      if (isEdit && currentCategory) {
        dispatch(updateCategory(currentCategory.id, category));
      } else {
        const res = await dispatch(addCategory(category));
        console.log(res);
      }

      console.log(data);
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(PATH_DASHBOARD.inventory.categories);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mt={2}>
                <RHFTextField
                  name="name"
                  type="string"
                  label={translate('inventory.categories.category_name')}
                  defaultValue={currentCategory?.name}
                />
                <RHFSelect
                  name="BranchId"
                  label={translate('inventory.categories.category_branch')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: false,
                    sx: { textTransform: 'capitalize' },
                  }}
                  defaultValue={isEdit ? currentCategory?.branchId : ''}
                  onChange={(e) => {
                    handleFilterBranch(e);
                  }}
                >
                  <MenuItem value="">{translate('inventory.categories.category_branch')} </MenuItem>
                  {!isLoadingDevice &&
                    branches?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </RHFSelect>
                <RHFSelect
                  name="defaultPrinterId"
                  label={translate('inventory.categories.category_printer')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: false,
                    sx: { textTransform: 'capitalize' },
                  }}
                  defaultValue={isEdit ? currentCategory?.defaultPrinterId : ''}
                >
                  <MenuItem value="">
                    {translate('inventory.categories.category_printer')}{' '}
                  </MenuItem>
                  {!isLoadingDevice &&
                    subKitchenDevices.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </RHFSelect>
              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit
                ? translate('inventory.categories.create_category')
                : translate('inventory.products.save_changes')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
