import { createSlice } from '@reduxjs/toolkit';
// types
import { UpdateWarehouse, WarehouseState } from '../../@types/warehouse';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: WarehouseState = {
  isInitialized: false,
  warehouses: [],
  purchaseInventories: [],
};

const slice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
    },

    // GET WAREHOUSES
    getWarehousesSuccess(state, action) {
      state.isInitialized = true;
      state.warehouses = action.payload;
    },

    // CREATE WAREHOUSE
    createWarehouseSuccess(state, action) {
      state.isInitialized = true;
      state.warehouses = action.payload;
    },

    // UPDATE WAREHOUSE
    updateWarehouseSuccess(state, action) {
      state.isInitialized = true;
      state.warehouses = action.payload;
    },

    // DELETE WAREHOUSE
    deleteWarehouseSuccess(state, action) {
      state.isInitialized = true;
      state.warehouses = action.payload;
    },

    getPurchaseInventoriesSuccess(state, action) {
      state.isInitialized = true;
      state.purchaseInventories = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getWarehousesSuccess,
  createWarehouseSuccess,
  updateWarehouseSuccess,
  deleteWarehouseSuccess,
  getPurchaseInventoriesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getWarehouses() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await axios.get(`/inventory/${companyId}`);
      console.log(response);
      dispatch(getWarehousesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getWarehouseById(id: string) {
  startLoading();
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/inventory/${id}`);
      dispatch(getWarehousesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function createWarehouse(data: UpdateWarehouse) {
  startLoading();

  const companyId = localStorage.getItem('companyId');
  //const { ...rest } = data;
  try {
    const response = await axios.post(`/inventory/${companyId}`, data);
    createWarehouseSuccess(response.data);
  } catch (error) {
    hasError(error);
  }
}

export async function updateWarehouse(data: UpdateWarehouse) {
  startLoading();
  const companyId = localStorage.getItem('companyId');
  try {
    const { id, ...rest } = data;
    const response = await axios.patch(`/inventory/${companyId}/${id}`, rest);
    updateWarehouseSuccess(response.data);
  } catch (error) {
    hasError(error);
  }
}

export function deleteWarehouse(id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/warehouses/${id}`);
      dispatch(deleteWarehouseSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getPurchaseInventories() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await axios.get(`inventory/purchase-inventory/${companyId}`);
      dispatch(getPurchaseInventoriesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
