import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// redux
import { useDispatch } from '../../../../redux/store';
// @mui
import { LoadingButton } from '@mui/lab';
// hooks
import useLocales from '../../../../hooks/useLocales';

import { Box, Card, Grid, Stack } from '@mui/material';
// routes
// @types
// components
import { Addon } from 'src/@types/product';
import { createAddon, editAddon } from 'src/redux/slices/addon';
import { FormProvider, RHFSwitch, RHFTextField } from '../../../../components/hook-form';

interface FormValuesProps extends Omit<Addon, 'addon'> {
  name: string;
  nameLocalized: string;
  price: number;
  variance: number;
  isTaxIncluded: boolean;
}

type Props = {
  isEdit?: boolean;
  currentAddon?: Addon | null;
  id: string;
};

export default function AddonNewEditForm({ isEdit, currentAddon, id }: Props) {
  const { translate } = useLocales();
  // use dispatch to get suppliers from redux
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const NewVariantSchema = Yup.object().shape({
    name: Yup.string(),
    nameLocalized: Yup.string(),
    price: Yup.number(),
    isTaxIncluded: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentAddon?.name || '',
      nameLocalized: currentAddon?.nameLocalized || '',
      price: currentAddon?.price || 0,
      isTaxIncluded: currentAddon?.isTaxIncluded || true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAddon]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewVariantSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentAddon) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentAddon]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // convert data to product type
      const addon: Addon = {
        ...data,
        price: data.price,
      };

      if (isEdit) {
        await dispatch(editAddon(currentAddon && currentAddon.id, data));
      } else {
        await dispatch(createAddon(parseInt(id), addon));
      }

      new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(
        !isEdit ? translate('messages.cerate_success') : translate('messages.update_success')
      );
      // window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField
                  name="name"
                  type="string"
                  label={translate('inventory.addons.addon_name')}
                />
                <RHFTextField
                  name="nameLocalized"
                  type="string"
                  label={translate('inventory.addons.addon_nameLocalized')}
                />
                <RHFTextField
                  name="price"
                  type="number"
                  label={translate('inventory.addons.addon_price')}
                />
                <RHFSwitch
                  name="isTaxIncluded"
                  label={translate('inventory.products.isTaxIncluded')}
                />
              </Box>
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                {!isEdit
                  ? translate('inventory.products.add_addon')
                  : translate('inventory.products.save_changes')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
