import { createSlice } from '@reduxjs/toolkit';
// utils
import { Order, OrderState } from '../../@types/order';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: OrderState = {
  isLoading: false,
  error: null,
  orders: [],
  order: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET orders
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // GET order
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

export function getOrders(filterStartDate: Date | null, filterEndDate: Date | null) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { orders: Order[] } } = await axios.post(
        `reports/orders/${companyId}`,
        {
          from: filterStartDate,
          to: filterEndDate,
        }
      );
      dispatch(slice.actions.getOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrder(branchId: string, orderId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`orders/${branchId}/get-one/${orderId}/`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
