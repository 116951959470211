// @mui
import {
  Box,
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// utils
import { fDate } from '../../../../../../utils/formatTime';
// components
import Image from '../../../../../../components/Image';
import Label from '../../../../../../components/Label';
import Scrollbar from '../../../../../../components/Scrollbar';
//
import { useEffect, useState } from 'react';
import { Order } from 'src/@types/order';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import InvoiceToolbar from './InvoiceToolbar';

// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  order: Order;
};

export default function OrderInvoice({ order }: Props) {
  const theme = useTheme();
  const { user } = useAuth();
  const { translate } = useLocales();
  const [status, statusSet] = useState<string>('');

  const { orderItems, totalWithTax, orderStatus, orderDate, total, orderNumber } = order;

  useEffect(() => {
    function checkOrderStatus() {
      if (orderStatus === 'CANCELLED') {
        // check if all item isReturned or not
        const isReturned = orderItems.every((item) => item.isReturned);
        if (isReturned) {
          statusSet('PARTLY_CANCELLED');
        } else {
          statusSet('CANCELLED');
        }
      } else {
        statusSet(orderStatus);
      }
    }

    checkOrderStatus();
  }, [orderStatus, orderItems, statusSet]);

  if (!order) {
    return null;
  }

  return (
    <>
      <InvoiceToolbar order={order} />

      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src={user?.companyMemberships[0].company.profilePictureUrl}
              sx={{ maxWidth: 120 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (orderStatus === 'DONE' && 'success') ||
                  (orderStatus === 'PENDING' && 'warning') ||
                  (orderStatus === 'CANCELLED' && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {translate(`orders.status.${status}`)}
              </Label>

              <Typography variant="h6">{`Order-${orderNumber}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('orders.invoice_from')}
            </Typography>
            <Typography variant="body2">{user?.companyMemberships[0].company.name}</Typography>
            <Typography variant="body2">
              {user?.companyMemberships[0].company.address || ''}
            </Typography>
            <Typography variant="body2">
              {user?.companyMemberships[0].company.vatRegistrationNumber || ''}
            </Typography>
          </Grid>

          {/* <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice to
            </Typography>
            <Typography variant="body2">{invoiceTo.name}</Typography>
            <Typography variant="body2">{invoiceTo.address}</Typography>
            <Typography variant="body2">Phone: {invoiceTo.phone}</Typography>
          </Grid> */}

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('orders.create_date')}
            </Typography>
            <Typography variant="body2">{fDate(orderDate)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              {translate('orders.due_date')}
            </Typography>
            <Typography variant="body2">{fDate(orderDate)}</Typography>
          </Grid>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">{translate('orders.description')}</TableCell>
                  <TableCell align="left">{translate('orders.qty')}</TableCell>
                  <TableCell align="right">{translate('orders.unit_price')}</TableCell>
                  <TableCell align="right">{translate('orders.total')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderItems.map((row, index) => (
                  // make cross line if item is returned
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                      textDecoration:
                        row.isReturned && row.returnedQty !== 0 ? 'line-through' : 'none',
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{row.itemChild.item.name}</Typography>
                        <Typography variant="subtitle2">
                          {row.variance?.price ? row.variance.name : ''}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {row.addons && `(${row.addons.map((addon) => addon.name).join(', ')})`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.qty}</TableCell>
                    <TableCell align="right">
                      {row.variance?.price ? row.variance.price : row.itemChild.item.price}
                    </TableCell>
                    <TableCell align="right">
                      {(row.variance?.price ? row.variance.price : row.itemChild.item.price) *
                        parseInt(row.qty)}
                    </TableCell>
                  </TableRow>
                ))}

                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Box sx={{ mt: 2 }} />
                    <Typography>{translate('orders.subtotal')}</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Box sx={{ mt: 2 }} />
                    <Typography>
                      {total} {translate('orders.SR')}
                    </Typography>
                  </TableCell>
                </RowResultStyle>

                {/* <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography>Discount</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography sx={{ color: 'error.main' }}>
                      {discount && fCurrency(-discount)}
                    </Typography>
                  </TableCell>
                </RowResultStyle> */}

                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography>{translate('orders.total_with_tax')}</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography>
                      {totalWithTax && totalWithTax} {translate('orders.SR')}
                    </Typography>
                  </TableCell>
                </RowResultStyle>

                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography variant="h6">{translate('orders.total')}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="h6">
                      {total} {translate('orders.SR')}
                    </Typography>
                  </TableCell>
                </RowResultStyle>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} md={9} sx={{ py: 3 }}>
            <Typography variant="subtitle2">{translate('orders.notes')}</Typography>
            <Typography variant="body2">{translate('orders.thank_u_note')}</Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">{translate('orders.have_aq')}</Typography>
            <Typography variant="body2">{user?.emails[0].email}</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
