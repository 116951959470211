import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// @mui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { StockTaking } from 'src/@types/stockTaking';
import Iconify from 'src/components/Iconify';
import { DialogAnimate } from 'src/components/animate';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useToggle from 'src/hooks/useToggle';
import { cancelSaleOrder } from 'src/redux/slices/saleOrder';
import { dispatch } from 'src/redux/store';
import RecordPayment from './RecordPaymentForm';
import StockTakingPDF from './StockTakingPDF';

// ----------------------------------------------------------------------

type Props = {
  stockTaking: StockTaking;
};

export default function StockTakingToolbar({ stockTaking }: Props) {
  const { translate } = useLocales();

  const { user } = useAuth();

  const { toggle: open, onOpen, onClose } = useToggle();

  const [onCreateAndEdit, setOnCreateAndEdit] = useState(false);

  const [cancelSaleOrderDialog, setCancelSaleOrderDialog] = useState(false);

  const handleClose = () => {
    setOnCreateAndEdit(false);
  };
  const handleEdit = () => {
    setOnCreateAndEdit(true);
  };

  const handleCancel = () => {
    setCancelSaleOrderDialog(true);
  };

  const handleCancelSaleOrder = async (id: string) => {
    setCancelSaleOrderDialog(false);
    await dispatch(cancelSaleOrder(id));
    // window.location.reload();
  };

  return (
    <>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ sm: 'center' }}
        sx={{ mb: 5 }}
      >
        <DialogAnimate open={onCreateAndEdit} onClose={handleClose}>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center">
              {translate('accounting.invoicing.record_payment')}
            </Typography>
          </Box>
          <RecordPayment
            id={stockTaking.id.toString()}
            currentStockTaking={stockTaking}
            handleClose={handleClose}
          />
        </DialogAnimate>

        <Stack direction="row" spacing={1}>
          <Tooltip title="View">
            <IconButton onClick={onOpen}>
              <Iconify icon={'eva:eye-fill'} />
            </IconButton>
          </Tooltip>

          <PDFDownloadLink
            document={<StockTakingPDF stockTaking={stockTaking} user={user} />}
            fileName={stockTaking.id.toString()}
            style={{ textDecoration: 'none' }}
          >
            {({ loading }) => (
              <Tooltip title="Download">
                <IconButton>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Iconify icon={'eva:download-fill'} />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </PDFDownloadLink>

          <Tooltip title="Print">
            <IconButton>
              <Iconify icon={'eva:printer-fill'} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Send">
            <IconButton>
              <Iconify icon={'ic:round-send'} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Share">
            <IconButton>
              <Iconify icon={'eva:share-fill'} />
            </IconButton>
          </Tooltip>
        </Stack>

        <Stack>
          {stockTaking.status === 'PENDING' && (
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon={'eva:checkmark-fill'} />}
              sx={{ alignSelf: 'flex-end' }}
              onClick={() => handleEdit()}
            >
              {translate('general.markAsPaid')}
            </Button>
          )}

          {stockTaking.status === 'PAID' && (
            <Button
              color="error"
              variant="outlined"
              startIcon={<Iconify icon={'ic:round-error'} />}
              sx={{ alignSelf: 'flex-end' }}
              onClick={() => handleCancel()}
            >
              {translate('general.refund')}
            </Button>
          )}
        </Stack>
      </Stack>

      <Dialog fullScreen open={open}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={onClose}>
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <StockTakingPDF stockTaking={stockTaking} user={user} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>

      <DialogAnimate open={cancelSaleOrderDialog} onClose={() => setCancelSaleOrderDialog(false)}>
        <Box sx={{ my: 5 }}>
          <Typography variant="h4" align="center">
            {translate('accounting.invoicing.cancel_sale_order')}
          </Typography>
        </Box>
        <Box sx={{ my: 5 }}>
          <Typography variant="body1" align="center">
            {translate('accounting.invoicing.cancel_sale_order_confirmation')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mb: 8 }}>
          <Button variant="contained" color="error" onClick={() => setCancelSaleOrderDialog(false)}>
            {translate('general.no')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCancelSaleOrder(stockTaking.id.toString())}
          >
            {translate('general.yes')}
          </Button>
        </Box>
      </DialogAnimate>
    </>
  );
}
