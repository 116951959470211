import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack } from '@mui/material';
// routes
import { PurchaseOrder, PurchaseOrderItem, PurchaseOrderStatus } from 'src/@types/purchaseOrder';
import { Supplier } from 'src/@types/supplier';
import { UpdateWarehouse } from 'src/@types/warehouse';
import FormProvider from 'src/components/hook-form/FormProvider';
import useLocales from 'src/hooks/useLocales';
import { addPurchaseOrder } from 'src/redux/slices/purchaseOrder';
import { getSuppliers } from 'src/redux/slices/supplier';
import { useDispatch, useSelector } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import PurchaseNewEditAddress from './PurchaseNewEditAddress';
import PurchaseNewEditDetails from './PurchaseNewEditDetails';
import PurchaseNewEditStatusDate from './PurchaseNewEditStatusDate';

// ----------------------------------------------------------------------

type IFormValuesProps = Omit<PurchaseOrder, 'createDate' | 'dueDate' | 'invoiceFrom' | 'invoiceTo'>;

interface FormValuesProps extends IFormValuesProps {
  createDate: Date;
  expiredDate: Date;
  fromSupplier: Supplier | null;
  toWarehouse: UpdateWarehouse | null;
  items: PurchaseOrderItem[];
  supplierId: string;
  updateCost: boolean;
  total: number;
  totalTax: number;
  taxId: string | undefined;
}

type Props = {
  isEdit?: boolean;
  currentPurchaseOrder?: FormValuesProps;
};

export default function PurchaseNewEditForm({ isEdit, currentPurchaseOrder }: Props) {
  const navigate = useNavigate();

  const [loadingSend, setLoadingSend] = useState(false);

  const { suppliers } = useSelector((state) => state.supplier);

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const NewUserSchema = Yup.object().shape({
    createDate: Yup.string().nullable().required('Create date is required'),
  });

  const defaultValues = useMemo(
    () => ({
      date: currentPurchaseOrder?.createDate,
      expiredDate: currentPurchaseOrder?.expiredDate,
      fromSupplier: currentPurchaseOrder?.fromSupplier || null,
      toWarehouse: currentPurchaseOrder?.toWarehouse || null,
      supplierId: currentPurchaseOrder?.fromSupplier?.id || '',
      inventoryId: currentPurchaseOrder?.toWarehouse?.id || 0,
      purchaseItems: currentPurchaseOrder?.items || [],
      status: currentPurchaseOrder?.status || PurchaseOrderStatus.INITIAL,
      term: currentPurchaseOrder?.term || undefined,
      total: currentPurchaseOrder?.total || 0,
      totalTax: currentPurchaseOrder?.totalTax || 0,
      totalWithTax: currentPurchaseOrder?.totalWithTax || 0,
      updateCost: currentPurchaseOrder?.updateCost || false,
      taxId: currentPurchaseOrder?.taxId || undefined,
    }),
    [currentPurchaseOrder]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    getSuppliers();
  }, [suppliers]);

  useEffect(() => {
    if (isEdit && currentPurchaseOrder) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentPurchaseOrder]);

  const handleCreateAndSend = async (data: FormValuesProps) => {
    setLoadingSend(true);

    const items = data.items.map((item) => ({
      itemId: item.id,
      qty: item.qty,
      price: item.price,
    }));

    let purchaseTotal = 0;

    items.forEach((item) => {
      purchaseTotal += item.qty * item.price;
    });

    try {
      const purchaseOrder = {
        // date: data.createDate,
        expiredDate: data.expiredDate,
        supplierId: data.fromSupplier?.id || 'X',
        inventoryId: data.toWarehouse?.id || 0,
        items: items,
        status: data.status,
        term: data.term,
        updateCost: data.updateCost,
        taxId: data.taxId,
        total: purchaseTotal,
      };

      dispatch(addPurchaseOrder(purchaseOrder));
      reset();
      setLoadingSend(false);
      navigate(PATH_DASHBOARD.inventory.purchaseOrders);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods}>
      <Card>
        <PurchaseNewEditAddress />

        <PurchaseNewEditStatusDate />

        <PurchaseNewEditDetails />
      </Card>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        {/* <LoadingButton
          color="inherit"
          size="large"
          variant="contained"
          loading={loadingSave && isSubmitting}
          onClick={handleSubmit(handleSaveAsDraft)}
        >
          Save as Draft
        </LoadingButton> */}

        <LoadingButton
          size="large"
          variant="contained"
          loading={loadingSend && isSubmitting}
          onClick={handleSubmit(handleCreateAndSend)}
        >
          {isEdit
            ? translate('inventory.purchase_orders.actions.Change')
            : translate('inventory.purchase_orders.actions.Add')}{' '}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
