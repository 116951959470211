import { createSlice } from '@reduxjs/toolkit';
// utils
import { MenuState } from '../../@types/menu';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState: MenuState = {
  isLoading: false,
  error: null,
  menus: [],
  items: [],
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET menus
    getMenusSuccess(state, action) {
      state.isLoading = false;
      state.menus = action.payload;
    },

    // CREATE menu
    createMenuSuccess(state, action) {
      state.isLoading = false;
      state.menus = [...state.menus, action.payload];
    },

    // UPDATE menu
    updateMenuSuccess(state, action) {
      state.isLoading = false;
      state.menus = state.menus.map((menu) => {
        if (menu.id === action.payload.id) {
          return action.payload;
        }
        return menu;
      });
    },

    // DELETE menu
    deleteMenuSuccess(state, action) {
      state.isLoading = false;
      state.menus = state.menus.filter((menu) => menu.id !== action.payload);
    },
  },
});

// Reducer
// // Actions
export const {
  startLoading,
  hasError,
  getMenusSuccess,
  createMenuSuccess,
  updateMenuSuccess,
  deleteMenuSuccess,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------

export function getMenus() {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/menu/${defaultBranchId}`);
      dispatch(slice.actions.getMenusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createMenu(data: any) {
  return async (dispatch: any) => {
    const { branchId, ...rest } = data;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/menu/${branchId}`, rest);
      dispatch(slice.actions.createMenuSuccess(response.data));
      console.log(response.data);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMenu(data: any) {
  return async (dispatch: any) => {
    const defaultBranchId = data.branchId;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/menu/${defaultBranchId}/${data.id}`, data);
      dispatch(slice.actions.updateMenuSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadMenuPDF(files: FormData, id: string, branchId: string) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/menu/${defaultBranchId}/${id}/url`, files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(slice.actions.updateMenuSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function deleteMenu(id: string) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  dispatch(slice.actions.startLoading());
  try {
    await axios.delete(`/menu/${defaultBranchId}/${id}`);
    dispatch(slice.actions.deleteMenuSuccess(id));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}
