import { createSlice } from '@reduxjs/toolkit';
// utils
import { Category, CategoryState } from '../../@types/category';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: CategoryState = {
  isLoading: false,
  error: null,
  categories: [],
  category: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET categories
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET category
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getCategories() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { categories: Category[] } } = await axios.get(
        `/categories/${companyId}`
      );
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategory(name: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { category: Category } } = await axios.get(
        `/categories/${companyId}`,
        {
          params: { name },
        }
      );
      dispatch(slice.actions.getCategorySuccess(response.data.category));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//add category
export function addCategory(category: Category) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { category: Category } } = await axios.post(
        `/categories/${companyId}`,
        category
      );
      dispatch(slice.actions.getCategorySuccess(response.data.category));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//update category
export function updateCategory(id: string, category: Category) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { category: Category } } = await axios.patch(
        `/categories/${companyId}/${id}`,
        category
      );
      dispatch(slice.actions.getCategorySuccess(response.data.category));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//delete category
export function deleteCategory(id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { category: Category } } = await axios.delete(
        `/categories/${companyId}/${id}`
      );
      dispatch(slice.actions.getCategorySuccess(response.data.category));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
