import { createSlice } from '@reduxjs/toolkit';
// utils
import { ProductIngredient, ProductIngredientState } from '../../@types/productIngredient';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: ProductIngredientState = {
  isLoading: false,
  error: null,
  ingredients: [],
  ingredient: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'productIngredient',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET productIngredients
    getProductIngredientsSuccess(state, action) {
      state.isLoading = false;
      state.ingredients = action.payload;
    },

    // GET productIngredient
    getProductIngredientSuccess(state, action) {
      state.isLoading = false;
      state.ingredient = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getProductIngredients(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredients: ProductIngredient[] } } = await axios.get(
        `/ingredients/${defaultBranchId}/item/${id}`
      );
      dispatch(slice.actions.getProductIngredientsSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductIngredient(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: ProductIngredient } } = await axios.get(
        `/ingredients/${defaultBranchId}/item/${id}`
      );
      dispatch(slice.actions.getProductIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createProductIngredient(data: ProductIngredient) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: ProductIngredient } } = await axios.post(
        `/ingredients/${defaultBranchId}/item`,
        data
      );
      dispatch(slice.actions.getProductIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function updateProductIngredient(data: ProductIngredient, id: number) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: { ingredient: ProductIngredient } } = await axios.patch(
      `/ingredients/${defaultBranchId}/item/${id}`,
      data
    );
    dispatch(slice.actions.getProductIngredientSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

// ----------------------------------------------------------------------

export function deleteProductIngredient(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: ProductIngredient } } = await axios.delete(
        `/ingredients/${defaultBranchId}/item/${id}`
      );
      dispatch(slice.actions.getProductIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
