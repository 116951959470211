import { createSlice } from '@reduxjs/toolkit';
// utils
import { UpdateVariantPrice, Variant, VariantState } from '../../@types/product';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: VariantState = {
  isLoading: false,
  error: null,
  variants: [],
  variant: null,
};

const slice = createSlice({
  name: 'variant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Variants
    getVariantsSuccess(state, action) {
      state.isLoading = false;
      state.variants = action.payload;
    },

    // GET PRODUCT
    getVariantSuccess(state, action) {
      state.isLoading = false;
      state.variant = action.payload;
    },

    // CREATE PRODUCT
    createVariantSuccess(state, action) {
      state.isLoading = false;
      state.variants?.push(action.payload);
    },

    // UDPATE PRODUCT
    updateVariantSuccess(state, action) {
      state.isLoading = false;
      state.variants = state.variants.map((variant) => {
        if (variant.id === action.payload.id) {
          return action.payload;
        }
        return variant;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// // Actions
// export const {
//   onGotoStep,
//   onBackStep,
//   onNextStep,
// } = slice.actions;

// ----------------------------------------------------------------------

export function getVariants(id: string) {
  const defaultWarehouseId = localStorage.getItem('defaultWarehouseId');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { variants: Variant[] } } = await axios.get(
        `/variants/${defaultWarehouseId}/${id}`
      );
      dispatch(slice.actions.getVariantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// edit variant function
export function editVariant(id: string | undefined, variant: Variant) {
  return async () => {
    const defaultWarehouseId = localStorage.getItem('defaultWarehouseId');
    const body = {
      ...variant,
    };
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { variant: Variant } } = await axios.patch(
        `/variants/${defaultWarehouseId}/${id}`,
        body
      );
      dispatch(slice.actions.updateVariantSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function updateVariantPrice(branchId: number, data: UpdateVariantPrice) {
  dispatch(slice.actions.startLoading());
  try {
    await axios.post(`/variants/${branchId}/update-price`, { ...data });
    dispatch(slice.actions.updateVariantSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
}

// ----------------------------------------------------------------------

export function getVariant(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { variant: Variant } } = await axios.get('/products/item', {
        params: { id },
      });
      dispatch(slice.actions.getVariantSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// // add variant function
export function addVariant(id: number, variant: Variant) {
  const defaultWarehouseId = localStorage.getItem('defaultWarehouseId');
  // add itemId to variant
  const variantWithItemId = {
    ...variant,
    itemId: id,
  };

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: Variant } = await axios.post(
        `/variants/${defaultWarehouseId}`,
        variantWithItemId
      );
      dispatch(slice.actions.createVariantSuccess(response.data));
      return response?.data;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
