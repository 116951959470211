import { useState } from 'react';
// @mui
import { Checkbox, MenuItem, TableCell, TableRow, useTheme } from '@mui/material';
// @types
import { Branch } from '../../../../../@types/branch';
import { OrderType } from '../../../../../@types/orderType';
// components
import { Can } from '@casl/react';
import Label from 'src/components/Label';
import ability from 'src/utils/ability';
import Iconify from '../../../../../components/Iconify';
import { TableMoreMenu } from '../../../../../components/table';
import useLocales from '../../../../../hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  id: string;
  row: OrderType;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  branches: Branch[];
};

export default function OrderTypesTableRow({
  id,
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  branches,
}: Props) {
  const theme = useTheme();

  const { translate } = useLocales();

  const { name, nameLocalized, isActive, isDefault, branchId } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected} id={id}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">{nameLocalized}</TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(isDefault === true && 'success') || (isDefault === false && 'error') || 'default'}
          sx={{ textTransform: 'capitalize' }}
        >
          {isDefault ? translate('general.yes') : translate('general.no')}
        </Label>
      </TableCell>
      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(isActive === true && 'success') || (isActive === false && 'error') || 'default'}
          sx={{ textTransform: 'capitalize' }}
        >
          {isActive ? translate('general.yes') : translate('general.no')}
        </Label>
      </TableCell>

      {/* get branch name */}
      <TableCell align="center">
        {branches.find((branch) => branch.id === branchId)?.name}
      </TableCell>

      <Can I="write" a="order" ability={ability}>
        {() => (
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {/* <MenuItem
                          onClick={() => {
                            onDeleteRow();
                            handleCloseMenu();
                          }}
                          sx={{ color: 'error.main' }}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                          Delete
                        </MenuItem> */}

                  {/* <MenuItem
                          onClick={() => {
                            onViewRow();
                            handleCloseMenu();
                          }}
                        >
                          <Iconify icon={'eva:eye-fill'} />
                          {translate('general.view')}
                        </MenuItem> */}

                  <MenuItem
                    onClick={() => {
                      onEditRow();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} />
                    {translate('general.edit')}
                  </MenuItem>
                </>
              }
            />
          </TableCell>
        )}
      </Can>
    </TableRow>
  );
}
