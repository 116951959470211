import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import addonReducer from './slices/addon';
import addonIngredientReducer from './slices/addonIngredient';
import branchesReducer from './slices/branch';
import calendarReducer from './slices/calendar';
import cancelReasonReducer from './slices/cancelReason';
import categoryReducer from './slices/category';
import chatReducer from './slices/chat';
import companyReducer from './slices/company';
import customerReducer from './slices/customer';
import deviceReducer from './slices/device';
import discountReducer from './slices/discount';
import eotdReducer from './slices/eotd';
import kanbanReducer from './slices/kanban';
import mailReducer from './slices/mail';
import membershipReducer from './slices/membership';
import menusReducer from './slices/menu';
import orderReducer from './slices/order';
import orderTypeReducer from './slices/orderType';
import pointOfSaleReducer from './slices/pointOfSale';
import productReducer from './slices/product';
import productIngredientReducer from './slices/productIngredient';
import purchaseOrderReducer from './slices/purchaseOrder';
import PurchaseTaxesReducer from './slices/purchaseTaxes';
import reportReducer from './slices/report';
import rolesReducer from './slices/role';
import saleOrderReducer from './slices/saleOrder';
import supplierReducer from './slices/supplier';
import taxesReducer from './slices/tax';
import transferRequestsReducer from './slices/transferRequest';
import uomReducer from './slices/uom';
import conversionReducer from './slices/uomConversion';
import variantReducer from './slices/variant';
import variantIngredientReducer from './slices/variantIngredient';
import warehouseReducer from './slices/warehouse';

// reports
import creditNoteReducer from './slices/creditNote';
import taxReportReducer from './slices/reports/taxReport/taxReport';
// warehouse report
import IdleStockReducer from './slices/reports/warehouseReport/idleStock';
import InventoryActionReducer from './slices/reports/warehouseReport/inventoryAction';
import StockValueReducer from './slices/reports/warehouseReport/stockValue';
// warehouse control report
import supplierPaymentReducer from './slices/reports/warehouseControlReport/supplierPayment';
import suppliersDebitsReducer from './slices/reports/warehouseControlReport/suppliersDebits';
// sales report
import customersPaymentsReducer from './slices/reports/salesReport/customersPayments';
import salesReportByBranchReducer from './slices/reports/salesReport/salesReportByBranch';
import companyStockTaking from './slices/reports/warehouseControlReport/companyStockTaking';
import transactionsReducer from './slices/transactions';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  company: companyReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  supplier: supplierReducer,
  category: categoryReducer,
  variant: variantReducer,
  addon: addonReducer,
  report: reportReducer,
  branches: branchesReducer,
  warehouses: warehouseReducer,
  pointsOfSale: pointOfSaleReducer,
  device: deviceReducer,
  order: orderReducer,
  transactions: transactionsReducer,
  eotd: eotdReducer,
  uom: uomReducer,
  conversion: conversionReducer,
  productIngredient: productIngredientReducer,
  variantIngredient: variantIngredientReducer,
  addonIngredient: addonIngredientReducer,
  memberships: membershipReducer,
  roles: rolesReducer,
  customers: customerReducer,
  orderTypes: orderTypeReducer,
  discounts: discountReducer,
  purchaseOrders: purchaseOrderReducer,
  taxes: taxesReducer,
  purchaseTaxes: PurchaseTaxesReducer,
  menus: menusReducer,
  saleOrder: saleOrderReducer,
  transferRequests: transferRequestsReducer,
  cancelReason: cancelReasonReducer,
  // reports
  creditNote: creditNoteReducer,
  taxReport: taxReportReducer,
  // warehouse report
  idleStock: IdleStockReducer,
  inventoryAction: InventoryActionReducer,
  stockValue: StockValueReducer,
  // warehouse control report
  suppliersDebits: suppliersDebitsReducer,
  supplierPayment: supplierPaymentReducer,
  // sales report
  customersPayments: customersPaymentsReducer,
  salesReportByBranch: salesReportByBranchReducer,
  companyStockTaking: companyStockTaking,
});

export { rootPersistConfig, rootReducer };
