import { createSlice } from '@reduxjs/toolkit';
// utils
import { IIdleStockState } from '../../../../@types/warehouseReport/idleStock';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

// ----------------------------------------------------------------------

const initialState: IIdleStockState = {
  isLoading: false,
  error: null,
  idleStock: [],
  inventories: [],
};

const slice = createSlice({
  name: 'idleStock',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORT
    getIdleStockSuccess(state, action) {
      state.isLoading = false;
      state.idleStock = action.payload;
    },

    // GET INVENTORY
    getInventorySuccess(state, action) {
      state.isLoading = false;
      state.inventories = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;

// Actions

export const { getIdleStockSuccess, getInventorySuccess, hasError, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getIdleStock(id: string, from: Date | null, to: Date | null) {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/reports/idle-stock/${companyId}/${id}`, {
        from,
        to,
      });
      dispatch(getIdleStockSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getInventory() {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/inventory/purchase-inventory/${companyId}`);
      dispatch(getInventorySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function resetIdleStock() {
  return async () => {
    dispatch(getIdleStockSuccess([]));
  };
}
