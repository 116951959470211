import { paramCase } from 'change-case';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
// hooks
import useSettings from '../../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import Page from '../../../../../components/Page';
// sections
import useLocales from 'src/hooks/useLocales';
import { getConversations } from 'src/redux/slices/chat';
import { getUoms } from 'src/redux/slices/uom';
import ConversionNewEditForm from './ConversionNewEditForm';

// ----------------------------------------------------------------------

export default function ConversionEdit() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { id = '' } = useParams();

  const { translate } = useLocales();

  const { unitOfMeasureConversions } = useSelector((state) => state.conversion);

  const currentConversion = unitOfMeasureConversions.find(
    (conversion) => paramCase(conversion.id.toString()) === id
  );

  const { unitOfMeasures } = useSelector((state) => state.uom);
  const currentUnit = unitOfMeasures.find((uom) => uom.id === parseInt(id));

  useEffect(() => {
    dispatch(getUoms());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getConversations());
  }, [dispatch]);

  return (
    <Page title="Conversions: Edit Conversion">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.conversions.edit_conversion')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: currentConversion?.qty.toString() as string },
          ]}
        />

        <ConversionNewEditForm
          isEdit
          currentConversion={currentConversion}
          currentUnit={currentUnit}
        />
      </Container>
    </Page>
  );
}
