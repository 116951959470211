// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  routes: {
    root: 'الرئيسية',
    general: {
      app: 'التطبيق',
      account: 'الحساب',
      user: 'المستخدم',
    },
    user: {
      profile: 'الملف الشخصي',
    },
  },
  auth: {
    login: {
      title: `تسجيل الدخول`,
      not_have_account: `ليس لديك حساب؟`,
      get_started: `ابدأ`,
      welcome_back: `مرحبا بعودتك`,
      sing_in_to_one_step: `تسجيل الدخول إلى ون ستيب`,
      enter_your_details_below: `أدخل تفاصيلك أدناه`,
      email: `البريد الإلكتروني`,
      password: `كلمة المرور`,
      remember_me: `تذكرنى`,
      forgot_password: `هل نسيت كلمة المرور`,
      login_button: `تسجيل الدخول`,
    },
    forgot_password: {
      title: `نسيت كلمة المرور`,
      forgot_your_password: `هل نسيت كلمة المرور ؟`,
      enter_your_email_below: `الرجاء إدخال عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل إليك رمزًا عبر البريد الإلكتروني
لإعادة تعيين كلمة المرور الخاصة بك.`,
      email: `البريد الإلكتروني`,
      send_code: `إرسال الرمز`,
      back: `العودة`,
    },
    new_password: {
      title: `إعادة تعيين كلمة المرور`,
      check_your_email: `تحقق من بريدك الإلكتروني`,
      request_send_successfully: `تم إرسال الطلب بنجاح!`,
      we_have_sent_password_reset_code: `لقد أرسلنا رمزًا لإعادة تعيين كلمة المرور إلى عنوان بريدك الإلكتروني. \n`,
      please_enter_the_code_below: `الرجاء إدخال الرمز أدناه .`,
      dont_receive_the_email: `لم تتلق البريد الإلكتروني؟`,
      email: `البريد الإلكتروني`,
      password: `كلمة المرور`,
      confirm_password: `تأكيد كلمة المرور`,
      change_password: `تغيير كلمة المرور`,
      resend: `إعادة إرسال`,
    },
    register: {
      title: `انشاء حساب`,
      have_account: `هل لديك حساب؟`,
      sign_in: `تسجيل الدخول`,
      register_message: `ابق متحكمًا في أي وقت وفي أي مكان.`,
      register_to_one_step: `انشئ حسابك في ون ستيب`,
      enter_your_details_below: `أدخل تفاصيلك أدناه`,
      name: `الاسم`,
      phone: `الهاتف`,
      email: `البريد الإلكتروني`,
      password: `كلمة المرور`,
      confirm_password: `تأكيد كلمة المرور`,
      register_button: `تسجيل`,
      by_clicking_register: `بالنقر فوق "تسجيل" ، فإنك توافق على`,
      terms_and_conditions: `الشروط والأحكام`,
      and: `و`,
      privacy_policy: `سياسة الخصوصية`,
      continue_register: 'استكمال انشاء الحساب',
    },
    errors: {
      is_required: `مطلوب`,
      name_is_required: `الاسم مطلوب`,
      name_is_too_long: `50 حرف على الاكثر للاسم `,
      name_is_too_short: `3 حرف على الاقل الاسم مطلوب`,
      email_is_required: `البريد الإلكتروني مطلوب`,
      email_is_invalid: `البريد الإلكتروني غير صالح`,
      roles_is_empty: `الصلاحيات مطلوبة`,
      phone_is_required: `الهاتف مطلوب`,
      role_is_required: `الدور مطلوب`,
      branch_is_required: `الفرع مطلوب`,
      phone_is_invalid: `الهاتف غير صالح`,
      password_is_required: `كلمة المرور مطلوبة`,
      password_is_too_short: `كلمة المرور قصيرة جدًا`,
      password_is_too_long: `كلمة المرور طويلة جدًا`,
      password_is_invalid: `كلمة المرور غير صالحة يجب ان يحتوى على حرف كبير واحد على الاقل وحرف صغير واحد على الاقل ورقم واحد على الاقل`,
      confirm_password_is_required: `تأكيد كلمة المرور مطلوب`,
      password_does_not_match: `كلمة المرور غير متطابقة`,
      old_password_is_required: `كلمة المرور القديمة مطلوبة`,
      new_password_is_required: `كلمة المرور الجديدة مطلوبة`,
      new_password_is_too_short: `كلمة المرور الجديدة قصيرة جدًا`,
      invalid_code: 'الرمز غير صحيح',
      code_is_required: `الرمز مطلوب`,
      error_occurred: `حدث خطأ`,
      invalid_credentials: `كلمة المرور غير صالحة`,
      ' User not found': `المستخدم غير موجود`,
      ' Email is not verified': `البريد الإلكتروني غير مؤكد`,
      ' Invalid credentials': `كلمة المرور غير صالحة`,
      ' User with this email already exists': `المستخدم بهذا البريد الإلكتروني موجود بالفعل`,
      ' User already exists': `المستخدم موجود بالفعل`,
    },
    messages: {
      verify_code_success: `تم التحقق من الرمز بنجاح`,
      update_password_success: `تم تحديث كلمة المرور بنجاح`,
      update_profile_success: `تم تحديث الملف الشخصي بنجاح`,
      update_image_success: `تم تحديث الصورة بنجاح`,
      send_code_success: `تم إرسال الرمز بنجاح`,
    },
  },
  account: {
    title: `حسابي`,
    general: `الملف الشخصي`,
    company_details: `تفاصيل الشركة`,
    allowed_picture_types: `الصور المسموح بها: jpg ، jpeg ، png`,
    max_file_size: `الحد الأقصى لحجم الملف:  `,
    update_profile_picture: `تحديث صورة الملف الشخصي`,
    name: `الاسم`,
    email: `البريد الإلكتروني`,
    phone: `الهاتف`,
    save_changes: `حفظ التغييرات`,
    old_password: `كلمة المرور القديمة`,
    new_password: `كلمة المرور الجديدة`,
    confirm_new_password: `تأكيد كلمة المرور الجديدة`,
    change_password: `تغيير كلمة المرور`,
    company: {
      name: `الاسم `,
      address: `العنوان `,
      city: `المدينة `,
      state: `الولاية `,
      zip: `الرمز البريدي `,
      tax: `قيمة الضريبة `,
      vatRegistrationNumber: `الرقم الضريبي `,
    },
    stepper: {
      title: 'قم بإنشاء حساب مؤسستك الخاصة',
      subtitle: 'سيتم استخدام هذه المعلومات لإنشاء حساب مؤسستك الخاصة',
      step1: 'المعلومات الأساسية',
      step2: 'تفاصيل الفرع',
      step3: 'تفاصيل نقطة البيع',
      next: 'التالي',
      back: 'السابق',
      confirm: 'تأكيد',
      notSet: 'لم يتم تعيين',
    },
  },
  navigator: {
    account_popover: {
      logout: `تسجيل الخروج`,
      profile: `الملف الشخصي`,
      settings: `الإعدادات`,
      home: `الصفحة الرئيسية`,
    },
  },
  company: {
    title: `الشركة`,
    branches: {
      title: `الفروع`,
      add_branch: `إضافة فرع`,
      edit_branch: `تعديل الفرع`,
      delete_branch: `حذف الفرع`,
      search: `بحث عن فرع`,
      name: `الاسم`,
      address: `العنوان`,
      city: `المدينة`,
      state: `الولاية`,
      zip: `الرمز البريدي`,
      setting: `الإعدادات`,
      cashierInvoiceLanguage: `لغة الفاتورة للكاشير`,
      kitchenInvoiceLanguage: `لغة الفاتورة للمطبخ`,
      subKitchenInvoiceLanguage: `لغة الفاتورة للمطبخ الفرعي`,
      invoiceMessage: `رسالة الفاتورة`,
      sellAfterZeroQuantity: `البيع بعد الكمية الصفرية`,
      choose_admin: `اختر المسؤول`,
      admin: `المسؤول`,
      noAdmin: `لا يوجد مسؤول`,
    },
    warehouses: {
      title: `المخازن`,
      choose_branch: `اختر الفرع`,
      choose_warehouse: `اختر المخزن`,
      add_warehouse: `إضافة مخزن`,
      edit_warehouse: `تعديل المخزن`,
      delete_warehouse: `حذف المخزن`,
      search: `بحث عن مخزن`,
      name: `الاسم`,
      address: `العنوان`,
      city: `المدينة`,
      state: `الولاية`,
      zip: `الرمز البريدي`,
    },
    points_of_sale: {
      title: `نقاط البيع`,
      choose_branch: `اختر الفرع`,
      search: `بحث عن نقطة بيع`,
      add_point_of_sale: `إضافة نقطة بيع`,
      edit_point_of_sale: `تعديل نقطة بيع`,
      name: `الاسم`,
      code: `الكود`,
      status: `الحالة`,
      branch: `الفرع`,
    },
    taxes: {
      title: `الضرائب`,
      add_tax: `إضافة ضريبة`,
      edit_tax: `تعديل الضريبة`,
      delete_tax: `حذف الضريبة`,
      search: `بحث عن ضريبة`,
      name: `الاسم`,
      name_localized: `الاسم باللغة الانجليزية`,
      value: `القيمة`,
      type: `النوع`,
      status: `الحالة`,
      rate: `المعدل`,
      fixed: `ثابت`,
      percentage: `نسبة مئوية`,
      default: `الافتراضي`,
      is_default: `الافتراضي`,
    },
    purchase_taxes: {
      title: `ضرائب الشراء`,
      add_purchase_tax: `إضافة ضريبة شراء`,
      edit_purchase_tax: `تعديل ضريبة شراء`,
      delete_purchase_tax: `حذف ضريبة شراء`,
      search: `بحث عن ضريبة شراء`,
      name: `الاسم`,
      name_localized: `الاسم باللغة الانجليزية`,
      value: `القيمة`,
      type: `النوع`,
      status: `الحالة`,
      rate: `المعدل`,
      fixed: `ثابت`,
      percentage: `نسبة مئوية`,
      default: `الافتراضي`,
      is_default: `الافتراضي`,
    },
  },

  reports: {
    title: `التقارير`,
    general: {
      title: `التقارير العامة`,
      totalSales: `إجمالي المبيعات`,
      ordersNumber: `عدد الطلبات`,
      cardSales: `مبيعات البطاقات`,
      cashSales: `مبيعات النقدية`,
      salesProfit: `صافي الربح`,
      item_sales: `مبيعات الأصناف`,
      category_sales: `مبيعات الفئات`,
      total: `الإجمالي`,
      amount: `المبلغ`,
      date: `التاريخ`,
      profit: `الربح`,
      qty: `الكمية`,
      price: `السعر`,
      name: `الاسم`,
      item: `الصنف`,
      tax: `الضريبة`,
      payment_method: `طريقة الدفع`,
      order_number: `رقم الطلب`,
      invoce_number: `رقم الفاتورة`,
      order_date: `تاريخ الطلب`,
      invoice_date: `تاريخ الفاتورة`,
      order_status: `حالة الطلب`,
      invoice_status: `حالة الفاتورة`,
      customer_name: `اسم العميل`,
      supplier_name: `اسم المورد`,
      supplier_details: `تفاصيل المورد`,
      invoice_number: `رقم الفاتورة`,
      payments: `المعاملات`,
      items: `الاصناف`,
      from_date: `من تاريخ`,
      to_date: `إلى تاريخ`,
      generated_using: 'تم إنشاء هذا التقرير باستخدام',
      onestep_system: 'نظام ون ستيب',
      chose_inventory_or_branch: 'يرجى اختيار المخزن أو الفرع',
      chose_item: 'يرجى اختيار الصنف',
      all: 'الكل',
    },
    purchase: {
      title: `تقارير المشتريات`,
      details: `تفاصيل الطلب`,
    },
    tax_report: {
      title: `تقارير الضرائب`,
      total_sales: `إجمالي المبيعات`,
      total_purchases: `إجمالي المشتريات`,
      total_sales_tax: `إجمالي ضريبة المبيعات`,
      total_purchases_tax: `إجمالي ضريبة المشتريات`,
      total_tax: `إجمالي الضرائب`,
      total_profit: `إجمالي الربح`,
      branches_tax_report: {
        name: `الاسم`,
        from: `من`,
        to: `إلى`,
        total_sales: `إجمالي المبيعات`,
        total_purchases: `إجمالي المشتريات`,
        total_sales_tax: `إجمالي ضريبة المبيعات`,
        total_purchases_tax: `إجمالي ضريبة المشتريات`,
        total: `الإجمالي`,
      },
    },
    sales_tax_report: {
      title: `تقارير ضريبة المبيعات`,
      total_sales: `إجمالي المبيعات`,
      total_sales_tax: `إجمالي ضريبة المبيعات`,
      total_tax: `إجمالي الضرائب`,
    },
    warehouse_report: {
      idle_stock: {
        name: `الاسم`,
        sku: `SKU`,
        qty: `الكمية`,
        idle_days: `أيام الخمول`,
        first_add_date: `تاريخ الإضافة الأول`,
        first_remove_date: `تاريخ الإزالة الأول`,
        download_csv: `تحميل ملف CSV`,
      },
      inventories_actions: {
        id: `الرقم`,
        branch_name: `اسم الفرع او المخزن`,
        name: `الاسم`,
        qty: `الكمية`,
        type: `النوع`,
        reason: `السبب`,
        date: `التاريخ`,
        ADD: `إضافة`,
        REMOVE: `إزالة`,
        RECEIVED: `استلام`,
        SENT: `إرسال`,
        SOLD: `بيع`,
        RECOUNT: `إعادة العد`,
        DAMAGE: `تلف`,
        THEFT: `سرقة`,
        LOSS: `فقدان`,
        RETURN: `إرجاع`,
        OTHER: `أخرى`,
        PURCHASED: `شراء`,
        TRANSFERRED: `نقل`,
        CONSUMED: `استهلاك`,
      },
      stock_value: {
        name: `الاسم`,
        qty: `الكمية`,
        cost: `التكلفة`,
        value: `القيمة`,
        total: `الإجمالي`,
      },
    },
    warehouse_control: {
      supplier_debits: {
        name: `الاسم`,
        vatRegistrationNumber: `الرقم الضريبي`,
        debit: `المدفوع`,
        credit: `المتبقي له`,
        total: `الإجمالي`,
      },
    },
    sales_report: {
      customers_payments: {
        name: `الاسم`,
        amount: `المبلغ`,
        payment_method: `طريقة الدفع`,
        date: `التاريخ`,
      },
      sales_report_by_branch: {
        name: `الاسم`,
        from: `تاريخ البداية`,
        to: `تاريخ النهاية`,
        total: `الإجمالي`,
        profit: `الربح`,
      },
    },
    shared: {
      last_30_days: `آخر 30 يوم`,
      this_month: `هذا الشهر`,
      last_3_months: `آخر 3 أشهر`,
    },
  },

  order_transactions: {
    voucher: `قسيمة`,
    bankTransfer: `تحويل بنكي`,
    compensation: `تعويض`,
    number: `الرقم`,
    date: `التاريخ`,
    type: `النوع`,
    status: `الحالة`,
    name: `الاسم`,
    phone: `الهاتف`,
    code: `الكود`,
    value: `القيمة`,
    value_type: `القيمة`,
    is_applied: `تم التطبيق`,
    FIXED: `ثابت`,
    PERCENTAGE: `نسبة مئوية`,
    account_name: `اسم الحساب`,
    account_no: `رقم الحساب`,
    bank_name: `اسم البنك`,
    created_at: `تاريخ الإنشاء`,
  },

  users_management: {
    title: `إدارة المستخدمين`,
    users: {
      title: `المستخدمين`,
      new: `مستخدم جديد`,
      add: `إضافة مستخدم`,
      edit: `تعديل`,
      delete: `حذف`,
      view: `عرض`,
      search: `بحث عن مستخدم`,
      name: `الاسم`,
      email: `البريد الإلكتروني`,
      role: `الدور`,
      branch: `الفرع`,
      password: `كلمة المرور`,
      confirm_password: `تأكيد كلمة المرور`,
      phone: `رقم الهاتف`,
      status: `الحالة`,
      active: `مفعل`,
      inactive: `غير مفعل`,
      owner: `مالك`,
      code: `الكود`,
      loading: `جاري التحميل...`,
    },
    roles: {
      title: `الأدوار`,
      add: `إضافة دور`,
      edit: `تعديل`,
      delete: `حذف`,
      search: `بحث عن دور`,
      name: `الاسم`,
      number: `عدد المستخدمين`,
      status: `الحالة`,
    },
  },
  customers: {
    title: `العملاء`,
    new: `عميل جديد`,
    add: `إضافة عميل`,
    edit: `تعديل عميل`,
    view: `عرض عميل`,
    search: `بحث عن عميل`,
    name: `الاسم`,
    email: `البريد الإلكتروني`,
    phone: `رقم الهاتف`,
    vatRegistrationNumber: `الرقم الضريبي`,
    status: `الحالة`,
    active: `مفعل`,
    inactive: `غير مفعل`,
    createdInBranch: `تم إنشاؤه في الفرع`,
    stats: {
      orders_count: `عدد الطلبات`,
      total_paid: `إجمالي المدفوع`,
      last_order_date: `تاريخ آخر طلب`,
      favorite_product: `المنتج المفضل`,
      favorite_branch: `الفرع المفضل`,
      no_favorite_product: `لا يوجد منتج مفضل`,
      no_favorite_branch: `لا يوجد فرع مفضل`,
      no_last_order_date: `لا يوجد طلبات سابقة`,
    },
  },
  roles: {
    ADMIN: `مشرف`,
    USER: `مستخدم`,
    SUDO: `مطور`,
  },

  nav: {
    app: `الرئسية`,
    dashboard: 'لوحة التحكم',
    general: `العام`,
    management: `إدارة`,
    company: `الشركة`,
    branches: `الفروع`,
    warehouses: `المخازن`,
    points_of_sale: `نقاط البيع`,
    inventory: `المخزون`,
    products: 'المنتجات',
    categories: 'الفئات',
    addons: 'إضافات',
    suppliers: 'الموردين',
    quantities: 'الكميات',
    orders: 'الطلبات',
    customers: 'العملاء',
    reports: 'التقارير',
    sales: 'المبيعات',
    sales_history: 'سجل المبيعات',
    settings: 'الإعدادات',
    users_management: 'إدارة المستخدمين',
    users: 'المستخدمين',
    roles: 'الأدوار',
    permissions: 'الصلاحيات',
    devices: 'الأجهزة',
    orders_history: 'سجل الطلبات',
    eotd: 'تقارير نهاية اليوم',
    units: 'وحدات القياس',
    unitsConversion: 'تحويل الوحدات',
    order_types: 'أنواع الطلبات',
    discounts: 'الخصومات',
    offers: 'العروض',
    purchase_orders: 'طلبات الشراء',
    taxes: 'ضرائب المبيعات',
    purchase_taxes: 'ضرائب الشراء',
    transfers_requests: 'طلبات النقل',
    menu: 'المنيو',
    accounting: 'المحاسبة',
    expenses: 'المصروفات',
    expenses_categories: 'فئات المصروفات',
    expenses_payments: 'مدفوعات المصروفات',
    expenses_reports: 'تقارير المصروفات',
    expenses_payments_reports: 'تقارير مدفوعات المصروفات',
    expenses_categories_reports: 'تقارير فئات المصروفات',
    invoicing: 'الفوترة',
    invoices: 'الفواتير',
    invoices_payments: 'مدفوعات الفواتير',
    invoices_reports: 'تقارير الفواتير',
    invoices_payments_reports: 'تقارير مدفوعات الفواتير',
    inventory_items: 'ادارة المنتجات',
    idle_stock: 'المخزون الراكد',
    inventory_action: 'حركة المخزون',
    stock_value: 'قيمة المخزون',
    available_quantity: 'الكميات المتاحة',
    tax_report: 'إقرار الضريبة ',
    branches_tax_report: 'إقرار الضريبة حسب الفرع',
    tax: 'الضريبة',
    creditNotes: 'اشعارات الخصم',
    cancel_reasons: 'أسباب الإلغاء',
    customers_payments: 'مدفوعات العملاء',
    sales_report_by_branch: ' المبيعات حسب الفرع',
    sales_report_by_category: ' المبيعات حسب الفئة',
    sales_report_by_order: ' المبيعات حسب الطلب',
    sales_report_by_user: ' المبيعات حسب الموظف',
    sales_report_by_payment_method: ' المبيعات حسب طريقة الدفع',
    sales_report_by_customer: ' المبيعات حسب العميل',
    customer_transaction: 'معاملات العملاء',
    branch_transaction: 'معاملات الفروع',
    warehouse_control: 'التحكم في المخزون',
    supplier_payment: 'معاملات الموردين',
    supplier_due: 'استحقاقات الموردين',
    purchase_order_details: 'تفاصيل طلبات الشراء',
    company_stocktaking: 'جرد المخزون',
    branch_stocktaking: 'جرد المخزون حسب الفرع',
    sales_tax_report: 'تقرير ضريبة المبيعات',
    purchases_tax_report: 'تقرير ضريبة الشراء',
    order_transactions: 'معاملات الطلبات',
    canceled_orders: 'الطلبات الملغاة',
  },

  general: {
    no_data: `لا توجد بيانات`,
    view: `عرض`,
    create: `إضافة`,
    edit: `تعديل`,
    delete: `حذف`,
    active: `تفعيل`,
    dense: `متوسط`,
    OPEN: `مفتوح`,
    CLOSED: `مغلق`,
    arabic: `عربي`,
    english: `إنجليزي`,
    both: `عربي و إنجليزي`,
    numbers: `كميات`,
    income: `الإيرادات`,
    yes: `نعم`,
    no: `لا`,
    apply: `تطبيق`,
    unApply: `إلغاء التطبيق`,
    markAsPaid: `تحديد كمدفوع`,
    paid: `مدفوع`,
    pending: `معلق`,
    SR: 'ريال',
    approve: `موافقة`,
    reject: `رفض`,
    receive: `استلام`,
    cancel: `إلغاء`,
    refund: `إسترجاع`,
    editAdmin: `تعديل المسؤول`,
    all: `الكل`,
    search: `بحث`,
    mark_as_done: `تحديد كمنتهي`,
    mode: `الوضع`,
    layout: `التصميم`,
    color: `لون الهوية`,
    stretch: `تمديد`,
    assistant: `المساعد`,
    show_assistant: `إظهار المساعد`,
    fullscreen: `ملء الشاشة`,
    exit_fullscreen: `خروج من ملء الشاشة`,
  },

  errors: {
    _too_long: `50 حرف على الاكثر للاسم `,
    zip_too_long: `5 حرف على الاكثر للاسم `,
    invalid_zip: ` الرمز البريدى خطأ `,
    _too_short: `3 حرف على الاقل`,
    name_required: `الاسم مطلوب`,
    image_required: `الصورة مطلوب`,
    branch_required: `الفرع مطلوب`,
    address_required: `العنوان مطلوب`,
    state_required: `الولاية مطلوبة`,
    city_required: `المدينة مطلوبة`,
    zip_required: `الرمز البريدي مطلوب`,
    vatRegistrationNumber_required: `الرقم الضريبى مطلوب`,
    cashierInvoiceLanguage_required: `لغة الفاتورة للكاشير مطلوبة`,
    kitchenInvoiceLanguage_required: `لغة الفاتورة للمطبخ مطلوبة`,
    subKitchenInvoiceLanguage_required: `لغة الفاتورة للمطبخ الفرعي مطلوبة`,
    no_data_found: `لا توجد بيانات`,
    no_permission: `لا توجد صلاحيات`,
    no_permission_description_1: `ليس لديك الصلاحية للوصول إلى هذه الصفحة`,
    no_permission_description_2: `يرجى الاتصال بالمسؤول للحصول على مساعدة`,
    back_home: `العودة للصفحة الرئيسية`,
    roles_has_users: `لا يمكن حذف الدور لارتباط مستخدمين به`,
    roles_is_branch_manager: `لا يمكن حذف دور مدير فرع`,
  },

  messages: {
    create_success: `تم إنشاء بنجاح`,
    update_success: `تم التحديث بنجاح`,
    delete_success: `تم الحذف بنجاح`,
    create_failed: `فشل في الإنشاء`,
    update_failed: `فشل في التحديث`,
    delete_failed: `فشل في الحذف`,
    send_code_success: `تم إرسال الكود بنجاح`,
  },

  inventory: {
    title: `المخزون`,
    products: {
      title: 'المنتجات',
      add_product: 'إضافة منتج',
      product_name: 'اسم المنتج',
      product_name_en: 'اسم المنتج بالإنجليزية',
      product_description: 'وصف المنتج',
      product_price: 'سعر المنتج',
      product_image: 'صورة المنتج',
      product_type: 'نوع المنتج',
      product_category: 'فئة المنتج',
      product_quantity: 'كمية المنتج',
      product_supplier: 'مورد المنتج',
      product_tax: 'ضريبة المنتج',
      select_product_tax: 'اختر ضريبة المنتج',
      costing_method: 'طريقة حساب التكلفة',
      select_method: 'اختر طريقة',
      FIXED: 'ثابت',
      FROM_INGREDIENTS: 'من المكونات',
      inventory_location: 'موقع المخزون',
      available_at: 'متوفر في',
      allBranches: 'كل الفروع',
      all_now_and_future_branches: 'كل الفروع الحالية و المستقبلية',
      in_stock: 'تحديد كمية',
      has_variants: 'تحديد الاختلافات',
      select_category: 'اختر فئة',
      select_supplier: 'اختر مورد',
      select_uom: 'اختر وحدة قياس',
      isTaxIncluded: 'تشمل الضريبة',
      canBeStored: 'يمكن تخزينه',
      canBeSold: 'يمكن بيعه',
      canBePurchased: 'يمكن شراؤه',
      can_be_used_in_purchase: 'يمكن استخدامه في طلبات الشراء',
      cost: 'التكلفة',
      price: 'السعر',
      active: `مفعل`,
      activate: `تفعيل`,
      inactive: `غير مفعل`,
      create_product: 'إضافة منتج',
      save_changes: 'حفظ التغييرات',
      edit_product: 'تعديل المنتج',
      prices_include_tax: 'الأسعار تشمل الضريبة',
      tax: 'الضريبة',
      tax_rates: 'معدلات الضريبة',
      tax_categories: 'فئات الضريبة',
      product: 'منتج',
      addon: 'إضافة',
      item: 'عنصر',
      variants: 'الاختلافات',
      addons: 'الإضافات',
      add: 'أضف',
      search_products: 'بحث عن منتج',
      search_items: 'بحث عن عنصر',
      search_variants: 'بحث عن اختلاف',
      search_users: 'بحث عن مستخدم',
      edit_variants: 'تعديل الاختلافات',
      edit_variants_prices: 'تعديل ألاسعار ',
      edit_addons_prices: 'تعديل ألاسعار ',
      edit_product_quantity: 'تعديل الكمية ',
      add_variant: 'إضافة اختلاف',
      edit_addons: 'تعديل الإضافات',
      inventory_actions: 'إجراءات المخزون',
      product_ingredients: 'مكونات المنتج',
      add_ingredient: 'إضافة مكون',
      ingredient_name: 'اسم المكون',
      choose_ingredient: 'اختر مكون',
      qty: 'الكمية',
      status: 'الحالة',
      ingridient: 'مكون',
      search_ingredients: 'بحث عن مكون',
      edit_ingredients: 'تعديل المكونات',
      qty_to_reduce: 'الكمية المراد خصمها',

      edit: 'تعديل',
      delete: 'حذف',
      save: 'حفظ',
      name: 'الاسم',
      cancel: 'إلغاء',
      update_product_picture: 'تحديث صورة المنتج',
      add_addon: 'إضافة جديدة',
      edit_product_prices: 'تعديل ألاسعار',
    },
    inventory_items: {
      add_inventory_item: 'إضافة عنصر',
      edit_inventory_item: 'تعديل عنصر',
      search_inventory_items: 'بحث عن عنصر',
      inventory_item_name: 'اسم العنصر',
      inventory_item_name_en: 'اسم العنصر بالإنجليزية',
      inventory_item_description: 'وصف العنصر',
      inventory_item_status: 'حالة العنصر',
      inventory_item_unit: 'وحدة العنصر',
      inventory_item_cost: 'تكلفة العنصر',
      inventory_item_quantity: 'كمية العنصر',
      inventory_item_supplier: 'مورد العنصر',
      inventory_item_branch_name: 'اسم الفرع',
      is_active: 'مفعل',
      choose_reason: 'اختر سبب',
      actions_type: 'نوع الإجراء',
      choose_actions_type: 'اختر نوع الإجراء',
      new_item_quantity: 'الكمية الجديدة',
      actions: {
        add: 'أضافة',
        remove: 'خصم',
      },
      reasons: {
        title: 'الأسباب',
        received: 'استلم',
        purchased: 'شراء',
        recount: 'إعادة العد',
        return: 'إرجاع',
        other: 'أخرى',
        sent: 'أرسل',
        sold: 'بيع',
        damage: 'تلف',
        theft: 'سرقة',
        loss: 'فقدان',
        transferred: 'تحويل',
        consumed: 'استهلاك',
      },
    },
    reports: {
      early_access: 'أنت مستخدم مبكر. نحن نعمل بجد لإعداد المنتج لك',
      welcome: 'مرحباً بك',
      total_orders: 'إجمالي الطلبات',
      no_of_orders: 'عدد الطلبات',
      total_profit: 'إجمالي الربح',
      total_cash: 'إجمالي النقدية',
      total_card: 'إجمالي البطاقات',
    },
    addons: {
      addon_name: 'اسم الإضافة',
      addon_nameLocalized: 'اسم الإضافة الانجليزي',
      addon_description: 'وصف الإضافة',
      addon_price: 'سعر الإضافة',
      search_addons: 'بحث عن إضافة',
      addon_ingredients: 'مكونات الإضافة',
    },
    variants: {
      variant_name: 'اسم الاختلاف',
      variant_nameLocalized: 'اسم الاختلاف الانجليزي',
      variant_description: 'وصف الاختلاف',
      variant_price: 'سعر الاختلاف',
      variant_variance: 'نسبة الاختلاف',
      search_variants: 'بحث عن اختلاف',
      variant_ingredients: 'مكونات الاختلاف',
      add_ingredient: 'إضافة مكون',
    },
    suppliers: {
      supplier: 'مورد',
      create_supplier: 'إضافة مورد',
      search_suppliers: 'بحث عن مورد',
      edit_supplier: 'تعديل مورد',
      supplier_name: 'اسم المورد',
      supplier_description: 'وصف المورد',
      supplier_phone: 'هاتف المورد',
      supplier_email: 'بريد المورد',
      supplier_address: 'عنوان المورد',
      supplier_city: 'مدينة المورد',
      supplier_state: 'ولاية المورد',
      supplier_country: 'بلد المورد',
      supplier_zip: 'رمز المورد',
      supplier_image: 'صورة المورد',
      supplier_website: 'موقع المورد',
      supplier_notes: 'ملاحظات المورد',
      supplier_tax_number: 'رقم ضريبة المورد',
      supplier_tax_type: 'نوع ضريبة المورد',
      supplier_tax_rate: 'معدل ضريبة المورد',
      supplier_tax_category: 'فئة ضريبة المورد',
      supplier_tax_name: 'اسم ضريبة المورد',
      supplier_tax_description: 'وصف ضريبة المورد',
      supplier_tax_amount: 'مبلغ ضريبة المورد',
      supplier_tax_rate_name: 'اسم معدل ضريبة المورد',
      supplier_tax_rate_description: 'وصف معدل ضريبة المورد',
      supplier_tax_rate_amount: 'مبلغ معدل ضريبة المورد',
      supplier_tax_rate_priority: 'أولوية معدل ضريبة المورد',
      supplier_tax_rate_compound: 'معدل ضريبة المورد مركب',
      supplier_tax_rate_tax_category: 'فئة معدل ضريبة المورد',
      supplier_tax_rate_tax_zone: 'منطقة معدل ضريبة المورد',
      supplier_tax_rate_tax_type: 'نوع معدل ضريبة المورد',
      supplier_tax_rate_tax_code: 'رمز معدل ضريبة المورد',
      supplier_tax_rate_tax_rate: 'معدل معدل ضريبة المورد',
      supplier_tax_rate_tax_name: 'اسم معدل ضريبة المورد',
      supplier_tax_rate_tax_description: 'وصف معدل ضريبة المورد',
      supplier_tax_rate_tax_amount: 'مبلغ معدل ضريبة المورد',
      supplier_tax_rate_tax_rate_name: 'اسم معدل معدل ضريبة المورد',
      supplier_bank_account_info: 'معلومات حساب المورد البنكي',
      supplier_bank_name: 'اسم بنك المورد',
      supplier_bank_branch: 'فرع بنك المورد',
      supplier_bank_account_number: 'رقم حساب بنك المورد',
      choose_supplier: 'اختر مورد',
    },
    categories: {
      category_name: 'اسم الفئة',
      category_printer: 'الطابعة الافتراضية',
      add_category: 'إضافة فئة',
      search_categories: 'بحث عن فئة',
      create_category: 'إضافة فئة',
      edit_category: 'تعديل فئة',
      category_branch: 'فرع الفئة',
    },
    devices: {
      device_name: 'اسم الجهاز',
      device_description: 'وصف الجهاز',
      device_model: 'نموذج الجهاز',
      device_type: 'نوع الجهاز',
      create_device: 'إضافة جهاز',
      device_ip: 'IP الجهاز',
      search_devices: 'بحث عن جهاز',
      edit_device: 'تعديل جهاز',
      cashier: 'كاشير',
      kitchen: 'مطبخ',
      subkitchen: 'مطبخ فرعي',
      device_branch: 'فرع الجهاز',
      device_pos: 'نقطة البيع الخاصة بالجهاز',
      categories: 'الفئات',
      types: {
        CASHIER: 'كاشير',
        KITCHEN: 'مطبخ',
        SUB_KITCHEN: 'مطبخ فرعي',
      },
    },
    uom: {
      uom: 'وحدة القياس',
      uom_plural: 'وحدات القياس',
      uom_list: 'قائمة وحدات القياس',
      uom_details: 'تفاصيل وحدة القياس',
      uom_create: 'إضافة وحدة قياس',
      uom_edit: 'تعديل وحدة قياس',
      uom_search: 'بحث عن وحدة قياس',
      uom_delete: 'حذف وحدة قياس',
      uom_delete_message: 'هل أنت متأكد من حذف وحدة القياس؟',
      uom_name: 'اسم وحدة القياس',
      uom_description: 'وصف وحدة القياس',
      uom_plural_name: 'اسم وحدات القياس',
      uom_plural_description: 'وصف وحدات القياس',
      uom_plural_create: 'إضافة وحدات قياس',
      uom_plural_edit: 'تعديل وحدات قياس',
      uom_plural_delete: 'حذف وحدات قياس',
    },
    conversions: {
      conversion: 'تحويل',
      create_conversion: 'إضافة تحويل',
      edit_conversion: 'تعديل تحويل',
      from: 'من',
      to: 'إلى',
      convert_to: 'تحويل إلى',
      conversion_rate: 'معدل التحويل',
      one_of_this_unit_equals: 'واحد من هذه الوحدة يساوي',
    },
    order_types: {
      title: 'انواع الطلبات',
      name: 'اسم نوع الطلب',
      name_localized: 'اسم نوع الطلب الانجليزي',
      is_default: 'الافتراضي',
      is_not_default: 'غير الافتراضي',
      is_active: 'نشط',
      is_not_active: 'غير نشط',
      add_order_type: 'إضافة نوع طلب',
      edit_order_type: 'تعديل نوع طلب',
      search: 'بحث عن نوع طلب',
      create_order_type: 'إضافة نوع طلب',
      choose_branch: 'اختر فرع',
      branch: 'الفرع',
      availableAtPOS: 'متاح في نقاط البيع',
    },
    purchase_orders: {
      purchase_order: 'طلب شراء',
      purchase_orders: 'طلبات الشراء',
      purchase_order_no: 'رقم طلب الشراء',
      purchase_order_date: 'تاريخ طلب الشراء',
      purchase_due_date: 'تاريخ استحقاق طلب الشراء',
      purchase_order_time: 'وقت طلب الشراء',
      purchase_order_status: 'حالة طلب الشراء',
      purchase_order_type: 'نوع طلب الشراء',
      purchase_order_total: 'مجموع طلب الشراء',
      payment_terms: 'شروط الدفع',
      purchase_order_subtotal: 'المجموع الفرعي لطلب الشراء',
      purchase_order_tax: 'ضريبة طلب الشراء',
      purchase_order_discount: 'خصم طلب الشراء',
      purchase_order_discount_type: 'نوع خصم طلب الشراء',
      purchase_order_discount_amount: 'مبلغ خصم طلب الشراء',
      purchase_order_discount_percentage: 'نسبة خصم طلب الشراء',
      purchase_order_discount_reason: 'سبب خصم طلب الشراء',
      purchase_order_discount_reason_placeholder: 'سبب خصم طلب الشراء',
      purchase_order_discount_amount_placeholder: 'مبلغ خصم طلب الشراء',
      purchase_order_discount_percentage_placeholder: 'نسبة خصم طلب الشراء',
      purchase_order_discount_type_placeholder: 'نوع خصم طلب الشراء',
      purchase_order_subtotal_placeholder: 'المجموع الفرعي لطلب الشراء',
      purchase_order_tax_placeholder: 'ضريبة طلب الشراء',
      purchase_order_total_placeholder: 'مجموع طلب الشراء',
      purchase_order_type_placeholder: 'نوع طلب الشراء',
      purchase_order_status_placeholder: 'حالة طلب الشراء',
      purchase_order_time_placeholder: 'وقت طلب الشراء',
      purchase_order_date_placeholder: 'تاريخ طلب الشراء',
      purchase_order_no_placeholder: 'رقم طلب الشراء',
      purchase_order_create: 'إضافة طلب شراء',
      purchase_order_edit: 'تعديل طلب شراء',
      purchase_order_search: 'بحث عن طلب شراء',
      purchase_order_delete: 'حذف طلب شراء',
      purchase_order_delete_message: 'هل أنت متأكد من حذف طلب الشراء؟',
      purchase_order_delete_success: 'تم حذف طلب الشراء بنجاح',
      purchase_order_delete_fail: 'حدث خطأ أثناء حذف طلب الشراء',
      purchase_order_create_success: 'تم إضافة طلب الشراء بنجاح',
      purchase_order_create_fail: 'حدث خطأ أثناء إضافة طلب الشراء',
      purchase_order_edit_success: 'تم تعديل طلب الشراء بنجاح',
      purchase_order_edit_fail: 'حدث خطأ أثناء تعديل طلب الشراء',
      purchase_order_details: 'تفاصيل طلب الشراء',
      purchase_order_details_placeholder: 'تفاصيل طلب الشراء',
      purchase_order_details_required: 'تفاصيل طلب الشراء مطلوبة',
      purchase_order_details_min: 'يجب أن تكون تفاصيل طلب الشراء أكثر من 3 أحرف',
      purchase_order_details_max: 'يجب أن تكون تفاصيل طلب الشراء أقل من 255 حرف',
      purchase_order_details_unique: 'تفاصيل طلب الشراء موجودة بالفعل',
      purchase_order_details_exists: 'تفاصيل طلب الشراء غير موجودة',
      purchase_order_details_not_exists: 'تفاصيل طلب الشراء غير موجودة',
      purchase_order_details_not_found: 'تفاصيل طلب الشراء غير موجودة',
      purchase_order_details_not_valid: 'تفاصيل طلب الشراء غير صالحة',
      purchase_order_details_not_allowed: 'تفاصيل طلب الشراء غير مسموح بها',
      All: 'الكل',
      Paid: 'مدفوع',
      Unpaid: 'غير مدفوع',
      PartiallyPaid: 'مدفوع جزئيا',
      Canceled: 'ملغي',
      Returned: 'مرتجع',
      Delivered: 'تم التسليم',
      Pending: 'معلق',
      Received: 'تم الاستلام',
      InProgress: 'قيد التنفيذ',
      Shipped: 'تم الشحن',
      Draft: 'مسودة',
      Overdue: 'متأخر',
      total: 'المجموع',
      total_with_tax: 'المجموع شامل الضريبة',
      status: {
        title: 'حالة طلب الشراء',
        INITIAL: 'مبدئي',
        DONE: 'مكتمل',
        CANCELLED: 'ملغي',
        PENDING: 'معلق',
        PAID: 'مدفوع',
        PARTLY_CANCELLED: 'ملغي جزئيا',
      },
      terms: {
        IMMEDIATE: 'فوري',
        AFTER_7_DAYS: 'بعد 7 أيام',
        AFTER_10_DAYS: 'بعد 10 أيام',
        AFTER_15_DAYS: 'بعد 15 يوم',
        AFTER_30_DAYS: 'بعد 30 يوم',
        AFTER_45_DAYS: 'بعد 45 يوم',
        AFTER_60_DAYS: 'بعد 60 يوم',
        AFTER_90_DAYS: 'بعد 90 يوم',
        AFTER_120_DAYS: 'بعد 120 يوم',
      },
      actions: {
        from_supplier: 'من المورد',
        to_warehouse: 'إلى المستودع',
        Add: 'إضافة',
        Change: 'تعديل',
        Details: 'المنتجات',
        add_details: 'إضافة منتج',
        status: 'الحالة',
        invoice_number: 'رقم الفاتورة',
        product: 'المنتج',
        quantity: 'الكمية',
        Price: 'السعر',
        remove: 'حذف',
        create: 'إنشاء',
        tax: 'الضريبة',
        tax_amount: 'مبلغ الضريبة',
        subtotal: 'المجموع الفرعي',
        total_price: 'المجموع الكلي',
        payment_terms: 'شروط الدفع',
        date_created: 'تاريخ الإنشاء',
        update_cost_msg: 'هل تريد تحديث تكلفة المنتج؟',
      },
    },

    transfers_requests: {
      transfer_request: 'طلب نقل',
      transfer_requests: 'طلبات النقل',
      transfer_request_no: 'رقم طلب النقل',
      transfer_request_date: 'تاريخ طلب النقل',
      transfer_request_due_date: 'تاريخ التنفيذ',
      transfer_request_time: 'وقت طلب النقل',
      transfer_request_status: 'حالة طلب النقل',
      transfer_request_type: 'نوع طلب النقل',
      create_transfer_request: 'إنشاء طلب نقل',
      search_transfer_request: 'بحث عن طلب نقل',
      edit_transfer_request: 'تعديل طلب نقل',
      from_inventory: 'من المستودع',
      to_inventory: 'إلى المستودع',
      no_of_items: 'عدد العناصر',
      transfer_request_edit_success: 'تم تعديل طلب النقل بنجاح',
      transfer_request_details: 'تفاصيل طلب النقل',
      All: 'الكل',
      Draft: 'مسودة',
      Pending: 'معلق',
      Accepted: 'مقبول',
      Rejected: 'مرفوض',
      Canceled: 'ملغي',
      warehouse: 'المستودع',
      status: {
        title: 'الحالة',
        DRAFT: 'مسودة',
        INITIAL: 'مبدئي',
        PENDING: 'معلق',
        APPROVED: 'مقبول',
        REJECTED: 'مرفوض',
      },
    },
    stock_taking: {
      stock_taking: 'جرد المخزون',
      stock_takings: 'جرد المخزون',
      stock_taking_no: 'رقم جرد المخزون',
      stock_taking_date: 'تاريخ جرد المخزون',
      stock_taking_time: 'وقت جرد المخزون',
      stock_taking_status: 'حالة جرد المخزون',
      stock_taking_type: 'نوع جرد المخزون',
      create_stock_taking: 'إنشاء جرد مخزون',
      search_stock_taking: 'بحث عن جرد مخزون',
      edit_stock_taking: 'تعديل جرد مخزون',
      stock_taking_edit_success: 'تم تعديل جرد المخزون بنجاح',
      stock_taking_details: 'تفاصيل جرد المخزون',
      stock_taking_warehouse: 'مستودع جرد المخزون',
      All: 'الكل',
      Draft: 'مسودة',
      Pending: 'معلق',
      Accepted: 'مقبول',
      item_name: 'اسم العنصر',
      item_code: 'رمز العنصر',
      old_quantity: 'الكمية القديمة',
      new_quantity: 'الكمية الجديدة',
      difference: 'الفرق',
      warehouse: 'المستودع',
      item_cost: 'تكلفة العنصر',
      item_price: 'سعر العنصر',
      no_of_items: 'عدد العناصر المجرودة',
      status: {
        INITIAL: 'مبدئي',
        APPROVED: 'مقبول',
        REJECTED: 'مرفوض',
      },
      actions: {
        oldQty: 'الكمية القديمة',
        newQty: 'الكمية الجديدة',
        difference: 'الفرق',
      },
    },
  },
  accounting: {
    accounting: 'المحاسبة',
    chart_of_accounts: 'دليل الحسابات',
    account: 'حساب',
    accounts: 'حسابات',
    account_no: 'رقم الحساب',
    account_name: 'اسم الحساب',
    account_type: 'نوع الحساب',
    account_balance: 'رصيد الحساب',
    account_balance_type: 'نوع رصيد الحساب',
    account_currency: 'عملة الحساب',
    account_details: 'تفاصيل الحساب',
    account_edit_success: 'تم تعديل الحساب بنجاح',
    account_delete_success: 'تم حذف الحساب بنجاح',
    account_create_success: 'تم إنشاء الحساب بنجاح',
    account_transfer: 'تحويل الحساب',
    account_transfer_success: 'تم تحويل الحساب بنجاح',
    account_transfer_error: 'حدث خطأ أثناء تحويل الحساب',
    account_transfer_confirmation: 'هل تريد تحويل الحساب؟',
    account_transfer_amount: 'مبلغ التحويل',
    account_transfer_from: 'من حساب',
    account_transfer_to: 'إلى حساب',
    account_transfer_date: 'تاريخ التحويل',
    account_transfer_time: 'وقت التحويل',
    account_transfer_note: 'ملاحظة التحويل',
    account_transfer_type: 'نوع التحويل',
    account_transfer_status: 'حالة التحويل',
    account_transfer_details: 'تفاصيل التحويل',
    account_transfer_no: 'رقم التحويل',
    account_transfer_edit_success: 'تم تعديل التحويل بنجاح',
    invoicing: {
      invoices: 'الفواتير',
      invoice: 'فاتورة',
      invoice_create: 'إنشاء فاتورة',
      tax_invoice: 'فاتورة ضريبية',
      tax_invoice_return: 'مرتجع فاتورة ضريبية',
      simplified_tax_invoice_return: 'مرتجع فاتورة ضريبية مبسطة',
      invoice_no: 'رقم الفاتورة',
      number_of_invoices: 'عدد الفواتير',
      paid: 'مدفوع',
      unpaid: 'غير مدفوع',
      overdue: 'متأخر',
      all: 'الكل',
      company_details: 'تفاصيل المنشأة',
      invoice_date: 'تاريخ الفاتورة',
      invoice_time: 'وقت الفاتورة',
      invoice_status: 'حالة الفاتورة',
      invoice_type: 'نوع الفاتورة',
      invoice_total: 'مجموع الفاتورة',
      invoice_subtotal: 'المجموع الفرعي للفاتورة',
      invoice_tax: 'ضريبة الفاتورة',
      start_date: 'تاريخ البدء',
      end_date: 'تاريخ الانتهاء',
      customer: 'العميل',
      from: 'من',
      invoice_discount: 'خصم الفاتورة',
      invoice_discount_type: 'نوع خصم الفاتورة',
      invoice_discount_amount: 'مبلغ خصم الفاتورة',
      invoice_discount_percentage: 'نسبة خصم الفاتورة',
      invoice_details: 'تفاصيل الفاتورة',
      invoice_edit_success: 'تم تعديل الفاتورة بنجاح',
      invoice_delete_success: 'تم حذف الفاتورة بنجاح',
      invoice_create_success: 'تم إنشاء الفاتورة بنجاح',
      invoice_confirmation: 'هل تريد إنشاء الفاتورة؟',
      invoice_send: 'إرسال الفاتورة',
      invoice_send_success: 'تم إرسال الفاتورة بنجاح',
      invoice_send_error: 'حدث خطأ أثناء إرسال الفاتورة',
      invoice_send_confirmation: 'هل تريد إرسال الفاتورة؟',
      invoice_send_date: 'تاريخ إرسال الفاتورة',
      invoice_send_time: 'وقت إرسال الفاتورة',
      invoice_send_note: 'ملاحظة إرسال الفاتورة',
      invoice_send_type: 'نوع إرسال الفاتورة',
      invoice_send_status: 'حالة إرسال الفاتورة',
      invoice_send_details: 'تفاصيل إرسال الفاتورة',
      invoice_send_no: 'رقم إرسال الفاتورة',
      invoice_send_edit_success: 'تم تعديل إرسال الفاتورة بنجاح',
      invoice_send_delete_success: 'تم حذف إرسال الفاتورة بنجاح',
      invoice_send_create_success: 'تم إنشاء إرسال الفاتورة بنجاح',
      generated_using: 'تم إنشاء هذه الفاتورة باستخدام',
      onestep_system: 'نظام ون ستيب',
      select_customer: 'اختر العميل',
      order_type: 'نوع الطلب',
      order_date: 'تاريخ الطلب',
      invoice_items: 'عناصر الفاتورة',
      products: 'المنتجات',
      quantity: 'الكمية',
      price: 'السعر',
      total: 'المجموع',
      price_before_tax: 'السعر قبل الضريبة',
      add_item: 'إضافة عنصر',
      remove_item: 'حذف عنصر',
      tax: 'الضريبة',
      record_payment: 'تسجيل دفعة',
      paid_amount: 'المبلغ المدفوع',
      payment_method: 'طريقة الدفع',
      choose_payment_method: 'اختر طريقة الدفع',
      CASH: 'نقداً',
      CARD: 'بطاقة',
      BANK_TRANSFER: 'تحويل بنكي',
      CHECK: 'شيك',
      simplified_tax_invoice: 'فاتورة ضريبية مبسطة',
      cancel_sale_order: 'إلغاء الفاتورة',
      cancel_sale_order_confirmation: 'هل تريد إلغاء الفاتورة؟',
      cancel_sale_order_success: 'تم إلغاء الفاتورة بنجاح',
      cancel_sale_order_error: 'حدث خطأ أثناء إلغاء الفاتورة',
      cancel_sale_order_details: 'تفاصيل إلغاء الفاتورة',
      cancel_sale_order_no: 'رقم إلغاء الفاتورة',
      cancel_sale_order_date: 'تاريخ إلغاء الفاتورة',
      cancel_sale_order_time: 'وقت إلغاء الفاتورة',
      cancel_sale_order_note: 'ملاحظة إلغاء الفاتورة',
      cancel_sale_order_status: 'حالة إلغاء الفاتورة',
      cancel_sale_order_edit_success: 'تم تعديل إلغاء الفاتورة بنجاح',
      cancel_sale_order_delete_success: 'تم حذف إلغاء الفاتورة بنجاح',
      branch_name: ' الفرع',
    },
    creditNotes: {
      invoice: 'فاتورة',
      title: 'اشعارات الخصم',
      academic: 'اشعار دائن',
      create: 'انشاء اشعار خصم',
      credit_note_no: 'رقم الاشعار',
      credit_note_date: 'تاريخ الاشعار',
      credit_note_total: 'اجمالي الاشعار',
      credit_note_status: 'حالة الاشعار',
      credit_note_details: 'تفاصيل اشعار الخصم',
      credit_note_branch: 'فرع اشعار الخصم',
    },
  },
  orders: {
    order: 'طلب',
    orders: 'طلبات',
    order_no: 'رقم الطلب',
    order_date: 'تاريخ الطلب',
    order_time: 'وقت الطلب',
    order_status: 'حالة الطلب',
    order_type: 'نوع الطلب',
    order_total: 'مجموع الطلب',
    order_subtotal: 'المجموع الفرعي للطلب',
    order_tax: 'ضريبة الطلب',
    order_discount: 'خصم الطلب',
    order_discount_type: 'نوع خصم الطلب',
    order_discount_amount: 'مبلغ خصم الطلب',
    order_discount_percentage: 'نسبة خصم الطلب',
    order_discount_name: 'اسم خصم الطلب',
    order_discount_description: 'وصف خصم الطلب',
    order_discount_code: 'رمز خصم الطلب',
    order_discount_reason: 'سبب خصم الطلب',
    order_discount_notes: 'ملاحظات خصم الطلب',
    order_discount_tax: 'ضريبة خصم الطلب',
    order_discount_tax_type: 'نوع ضريبة خصم الطلب',
    order_amount: 'مبلغ الطلب',
    order_list: 'قائمة الطلبات',
    order_details: 'تفاصيل الطلب',
    order_notes: 'ملاحظات الطلب',
    price_without_tax: 'السعر بدون ضريبة',
    SR: 'ريال',
    PENDING: 'قيد الانتظار',
    PAID: 'مدفوع',
    DONE: 'مكتمل',
    CANCELLED: 'ملغي',
    PROCESSING: 'قيد التنفيذ',
    INITIAL: 'مبدئي',
    all: 'الكل',
    search_orders: 'بحث عن طلب',
    paid: 'مدفوع',
    unpaid: 'غير مدفوع',
    overdue: 'متأخر',
    draft: 'مسودة',
    create_order: 'إضافة طلب',
    edit_order: 'تعديل طلب',
    order_customer: 'عميل الطلب',
    order_numbers: 'عدد الطلبات',
    start_date: 'تاريخ البدء',
    end_date: 'تاريخ الانتهاء',
    create_date: 'تاريخ الإنشاء',
    due_date: 'تاريخ الاستحقاق',
    invoices: 'فواتير',
    pos: 'نقاط البيع',
    invoice_from: 'فاتورة من',
    invoice_to: 'فاتورة إلى',
    description: 'الوصف',
    qty: 'الكمية',
    unit_price: 'سعر الوحدة',
    total: 'المجموع',
    subtotal: 'المجموع الفرعي',
    total_with_tax: 'المجموع مع الضريبة',
    total_without_tax: 'المجموع بدون الضريبة',
    notes: 'الملاحظات',
    vat: 'الرقم الضريبي',
    thank_u_note: 'شكرا لك على زيارتنا',
    have_aq: 'هل لديك أسئلة؟',
    mark_as_paid: 'تعليم كمدفوع',
    order_invoice: 'فاتورة الطلب',
    view: 'عرض',
    status: {
      INITIAL: 'مبدئي',
      DONE: 'مكتمل',
      CANCELLED: 'ملغي',
      PENDING: 'معلق',
      PAID: 'مدفوع',
      PARTLY_CANCELLED: 'ملغي جزئيا',
    },
    cancel_reasons: {
      title: 'أسباب الإلغاء',
      name: 'اسم',
      name_localized: 'اسم الانجليزي',
      stock_action: 'سبب مرجع الإلغاء',
      choose_stock_action: 'اختر مرجع الإلغاء',
      orders_number: 'عدد الطلبات',
      is_active: 'نشط',
      branch: 'الفرع',
      add: 'إضافة',
      edit: 'تعديل',
      view: 'عرض',
      search: 'بحث عن',
      damage: 'تلف',
      theft: 'سرقة',
      loss: 'فقدان',
      return: 'إرجاع',
      other: 'أخرى',
      statistics: 'إحصائيات',
    },
    discount: {
      title: 'الخصومات',
      name: 'اسم الخصم',
      name_localized: 'اسم الخصم الانجليزي',
      value: 'قيمة الخصم',
      type: 'نوع الخصم',
      fixed: 'ثابت',
      percentage: 'نسبة',
      is_default: 'الافتراضي',
      is_not_default: 'غير الافتراضي',
      is_active: 'نشط',
      is_not_active: 'غير نشط',
      is_applied: 'مطبق',
      is_not_applied: 'غير مطبق',
      add: 'إضافة',
      edit: 'تعديل خصم',
      view: 'عرض خصم',
      search: 'بحث عن خصم',
      create: 'إنشاء خصم',
      remove: 'حذف',
      apply_success: 'تم تطبيق الخصم بنجاح',
      apply_error: 'حدث خطأ أثناء تطبيق الخصم',
      unApply_success: 'تم إلغاء تطبيق الخصم بنجاح',
      unApply_error: 'حدث خطأ أثناء إلغاء تطبيق الخصم',
      discount_applied_branches: 'تخصيص فروع للعرض',
      discount_applied_categories: 'تخصيص فئات للعرض',
      discount_applied_items: 'تخصيص منتجات للعرض',
      discount_applied_customers: 'تخصيص عملاء للعرض',
      add_branches: 'إضافة فروع',
      add_categories: 'إضافة فئات',
      add_items: 'إضافة منتجات',
      add_customers: 'إضافة عملاء',
      name_required: 'اسم الخصم مطلوب',
      value_required: 'قيمة الخصم مطلوبة',
      type_required: 'نوع الخصم مطلوب',
    },
    offers: {
      title: 'العروض',
      name: 'اسم العرض',
      name_localized: 'اسم العرض الانجليزي',
      value: 'قيمة العرض',
      type: 'نوع العرض',
      fixed: 'ثابت',
      percentage: 'نسبة',
      is_default: 'الافتراضي',
      is_not_default: 'غير الافتراضي',
      is_active: 'نشط',
      is_not_active: 'غير نشط',
      is_applied: 'مطبق',
      is_not_applied: 'غير مطبق',
      add: 'إضافة',
      edit: 'تعديل عرض',
      view: 'عرض',
      search: 'بحث عن عرض',
      create: 'إنشاء عرض',
      remove: 'حذف',
      apply_success: 'تم تطبيق العرض بنجاح',
      apply_error: 'حدث خطأ أثناء تطبيق العرض',
      unapply_success: 'تم إلغاء تطبيق العرض بنجاح',
      unApply_error: 'حدث خطأ أثناء إلغاء تطبيق العرض',
      discount_applied_branches: 'تخصيص فروع للعرض',
      discount_applied_categories: 'تخصيص فئات للعرض',
      discount_applied_items: 'تخصيص منتجات للعرض',
      discount_applied_customers: 'تخصيص عملاء للعرض',
      add_branches: 'إضافة فروع',
      add_categories: 'إضافة فئات',
      add_items: 'إضافة منتجات',
      add_customers: 'إضافة عملاء',
    },
  },
  eotd: {
    openedAt: 'تاريخ الفتح',
    closedAt: 'تاريخ الإغلاق',
    cashIn: 'النقد عند الفتح',
    cashOut: 'النقد عند الإغلاق',
    difference: 'الفرق',
    search_eotd: 'بحث عن تقرير',
    expectedCashInClose: 'النقد المتوقع عند الإغلاق',
    report_from: 'تقرير من',
    orders_count: 'عدد الطلبات',
    refunded_orders_number: 'عدد الطلبات المرتجعة',
    orders_cash: 'إجمالي النقدي',
    orders_card: 'إجمالي البطاقات',
    orders_refund: 'إجمالي المرتجعات',
    orders_cash_refund: 'إجمالي المرتجعات النقدي',
    orders_card_refund: 'إجمالي المرتجعات البطاقات',
    orders_total: 'إجمالي الطلبات',
    orders_profit: 'إجمالي الربح',
    eotd_details: 'تفاصيل تقرير اليوم',
    orders_number: 'عدد الطلبات',
    total_income: 'إجمالي الدخل',
    sales_profit: 'ربح المبيعات',
    cash_sales: 'المبيعات النقدية',
    card_sales: 'المبيعات البطاقات',
    cash_refunds: 'المرتجعات النقدية',
    card_refunds: 'مرتجعات البطاقة',
    cash_in_opening: 'النقد عند الفتح',
    cash_in_closing: 'النقد عند الإغلاق',
    expected_cash: 'النقد المتوقع',
    deference: 'الفرق',
    sales_summary_report: 'تقرير ملخص المبيعات',
    payment_method_report: 'تقرير طريقة الدفع',
    order_types_sales_report: 'تقرير أنواع الطلبات',
    category_sales_report: 'تقرير فئات المبيعات',
    item_sales_report: 'تقرير مبيعات المنتجات',
    value: 'القيمة',
  },
  scopes: {
    main: {
      COMPANY: 'الشركة',
      BRANCH: 'الفروع',
      WAREHOUSE: 'المستودعات',
    },
    parents: {
      COMPANY: 'الشركة',
      BRANCH: 'الفرع',
      WAREHOUSE: 'المستودعات',
      ITEM: 'الاصناف',
      VARIANT: 'المتغيرات',
      ORDER_TYPE: 'انوع الطلب',
      ORDER: 'الطلبات',
      POINT_OF_SALE: 'نقطات البيع',
      DEVICE: 'الاجهازة',
      PRINTER: 'الطابعات',
      SUPPLIER: 'الموردين',
      PURCHASE: 'المشتريات',
      CATEGORY: 'اقسام الاصناف',
      UNIT_OF_MEASUREMENT: 'وحدات القياس',
      REPORT: 'التقارير',
      ROLE: 'الصلاحيات',
      ADDON: 'الاضافات',
      CUSTOMER: 'العملاء',
      INGREDIENT: 'المكونات',
      DISCOUNT: 'الخصومات',
      CANCEL_REASON: 'أسباب الإلغاء',
      TAX: 'الضرائب',
      TRANSFER_REQUEST: 'طلبات النقل',
      MENU: 'القائمة',
      SALE_ORDER: 'طلبات البيع',

      in: 'في',
    },
    children: {
      'Delete company': 'حذف الشركة',
      'Update company details': 'تحديث بيانات الشركة',
      'Read company details': 'قراءة بيانات الشركة',
      'Create and update memberships': 'إنشاء وتحديث العضويات',
      'Read memberships': 'قراءة العضويات',
      'Delete memberships': 'حذف العضويات',
      'Create and update branches': 'إنشاء وتحديث الفروع',
      'Read branches': 'قراءة الفروع',
      'Delete branches': 'حذف الفروع',
      'Create and update warehouse info': 'إنشاء وتحديث المستودعات',
      'Read warehouse info': 'قراءة المستودعات',
      'Delete warehouse': 'حذف المستودعات',
      'Read warehouses': 'قراءة جميع المستودعات',
      'Create and update items': 'إنشاء وتحديث الاصناف',
      'Read items': 'قراءة الاصناف',
      'Delete items': 'حذف الاصناف',
      'Create and update variants': 'إنشاء وتحديث المتغيرات',
      'update variants': 'تحديث المتغيرات ',
      'Read variants': 'قراءة المتغيرات',
      'Delete variants': 'حذف المتغيرات',
      'Create and update orders': 'إنشاء وتحديث الطلبات',
      'Read orders': 'قراءة الطلبات',
      'Delete orders': 'حذف الطلبات',
      'Cancel orders': 'إلغاء الطلبات',
      'Create and update Point of sale info': 'إنشاء وتحديث نقاط البيع',
      'Read Point of sale info': 'قراءة نقاط البيع',
      'Delete Point of sale info': 'حذف نقاط البيع',
      'Read point of sales': 'قراءة جميع نقاط البيع',
      'Create and update devices': 'إنشاء وتحديث الاجهزة',
      'Read devices': 'قراءة الاجهزة',
      'Delete devices': 'حذف الاجهزة',
      'Create and update printers': 'إنشاء وتحديث الطابعات',
      'Read printers': 'قراءة الطابعات',
      'Delete printers': 'حذف الطابعات',
      'Create and update suppliers': 'إنشاء وتحديث الموردين',
      'Read suppliers': 'قراءة الموردين',
      'Delete suppliers': 'حذف الموردين',
      'Create and update purchases': 'إنشاء وتحديث المشتريات',
      'Read purchases': 'قراءة المشتريات',
      'Delete purchases': 'حذف المشتريات',
      'Create and update categories': 'إنشاء وتحديث اقسام الاصناف',
      'Read categories': 'قراءة اقسام الاصناف',
      'Delete categories': 'حذف اقسام الاصناف',
      'Create and update units of measurement': 'إنشاء وتحديث وحدات القياس',
      'Read units of measurement': 'قراءة وحدات القياس',
      'Delete units of measurement': 'حذف وحدات القياس',
      'Create and update reports': 'إنشاء وتحديث التقارير',
      'Read reports': 'قراءة التقارير',
      'Delete reports': 'حذف التقارير',
      'Create and update roles': 'إنشاء وتحديث الصلاحيات',
      'Read roles': 'قراءة الصلاحيات',
      'Delete roles': 'حذف الصلاحيات',
      'Read customers': 'قراءة العملاء',
      'Delete customers': 'حذف العملاء',
      'Create and update customers': 'إنشاء وتحديث العملاء',
      'Read addons': 'قراءة الاضافات',
      'Create and update addons': 'إنشاء وتحديث الاضافات',
      'Delete addons': 'حذف الاضافات',
      'Delete ingredients': 'حذف المكونات',
      'Create and update ingredients': 'إنشاء وتحديث المكونات',
      'Read ingredients': 'قراءة المكونات',
      'Read order types': 'قراءة انواع الطلبات',
      'Create and update order types': 'إنشاء وتحديث انواع الطلبات',
      'Delete order types': 'حذف انواع الطلبات',
      'Read Discount': 'قراءة الخصومات',
      'Create and update discounts': 'إنشاء وتحديث الخصومات',
      'delete discount': 'حذف الخصومات',
      'Read cancel reasons': 'قراءة أسباب الإلغاء',
      'Create and update cancel reasons': 'إنشاء وتحديث أسباب الإلغاء',
      'Delete cancel reasons': 'حذف أسباب الإلغاء',
      'Update branch details': 'تحديث بيانات الفرع',
      'Read branch memberships': 'قراءة عضويات الفرع',
      'Create and update branch memberships': 'إنشاء وتحديث عضويات الفرع',
      'Read inventory items': 'قراءة الاصناف في المستودع',
      'Create and update inventory items': 'إنشاء وتحديث الاصناف في المستودع',
      'Delete inventory items': 'حذف الاصناف في المستودع',
      'Read discounts': 'قراءة الخصومات',
      'Delete discounts': 'حذف الخصومات',
      'Read taxes': 'قراءة الضرائب',
      'Create and update taxes': 'إنشاء وتحديث الضرائب',
      'Delete taxes': 'حذف الضرائب',
      'Read transfer requests': 'قراءة طلبات النقل',
      'Create and update transfer requests': 'إنشاء وتحديث طلبات النقل',
      'Delete transfer requests': 'حذف طلبات النقل',
      'Delete warehouses': 'حذف المستودعات',
      'Create and update warehouses': 'إنشاء وتحديث المستودعات',
      'Read menus': 'قراءة القائمة',
      'Create and update menus': 'إنشاء وتحديث القائمة',
      'Delete menus': 'حذف القائمة',
      'Read sale orders': 'قراءة طلبات البيع',
      'Create and update sale orders': 'إنشاء وتحديث طلبات البيع',
      'Delete sale orders': 'حذف طلبات البيع',
      'Cancel sale orders': 'إلغاء طلبات البيع',
    },
  },
  menus: {
    title: 'المنيو',
    add: 'اضافة منيو',
    name: 'اسم المنيو',
    nameLocalized: 'اسم المنيو باللغة الانجليزية',
    description: 'وصف المنيو',
    image: 'صورة المنيو',
    qr: 'QR المنيو',
    items: 'الاصناف',
    item: 'الصنف',
    search_for_menus: 'ابحث عن منيو',
    select_branch: 'اختر فرع',
    status: 'الحالة',
    source: 'مصدر المنيو',
    ready_file: 'ملف جاهز',
    auto_generated: 'انشاء تلقائي',
    choose_template: 'اختر قالبا جاهزا',
    upload_pdf_file: 'ارفع ملف بصيغة PDF',
    generated_using: 'تم انشاء المنيو باستخدام',
    for_menu_pls_scan_code: 'لعرض المنيو الرجاء مسح الكود',
    for_menu: 'لعرض المنيو',
    scan_code: 'مسح الكود',
  },

  captions: {
    idle_stock_caption:
      'يوضح لك هذا التقرير المخزون الموجود خلال فترة زمنية معينة دون استخدامه في عمليات الإنتاج أو البيع',
    inventory_action_caption:
      'يوضح انتقال المنتجات وحركتها، أي يوضع عمليات البيع ومن اي قناة تمت هذه العملية',
    inventory_value_caption: 'يوضح لك هذا التقرير قيمة المنتجات الموجودة في المستودعات',
    tax_report_caption: 'يوضح لك هذا التقرير قيمة الضريبة المدفوعة والمستحقة',
    branch_tax_report_caption: 'يوضح لك هذا التقرير قيمة الضريبة المدفوعة والمستحقة لكل فرع',
    customer_payments_caption: 'يوضح لك هذا التقرير قيمة المدفوعات التي تم تسجيلها لكل عميل',
    supplier_payment_caption: 'يوضح لك هذا التقرير قيمة المدفوعات التي تم تسجيلها لكل مورد',
    sales_report_by_branch_caption: 'يوضح لك هذا التقرير قيمة المبيعات التي تم تسجيلها لكل فرع',
    sales_report_by_category_caption: 'يوضح لك هذا التقرير قيمة المبيعات التي تم تسجيلها لكل فئة',
    sales_report_by_user_caption: 'يوضح لك هذا التقرير قيمة المبيعات التي تم تسجيلها لكل موظف',
    sales_report_by_payment_method_caption:
      'يوضح لك هذا التقرير قيمة المبيعات التي تم تسجيلها لكل طريقة دفع',
    sales_report_by_customer_caption: 'يوضح لك هذا التقرير قيمة المبيعات التي تم تسجيلها لكل عميل',
    supplier_due_caption: 'يوضح لك هذا التقرير قيمة المبالغ المستحقة لكل مورد',
  },
  tourSteps: {
    general: {
      skip: 'تخطي',
      previous: 'السابق',
      next: 'التالي',
      finish: 'انهاء',
      back: 'رجوع',
    },
    branches: {
      title: 'الفروع',
      description: 'يمكنك إضافة وتعديل الفروع',
      table: 'هذه هي قائمة فروعك',
      addBranch: 'اضغط هنا لإضافة فرع جديد',
      editBranch: 'اضغط الثلاثة نقاط لتعديل الفرع',
    },
    warehouses: {
      title: 'المستودعات',
      description: 'يمكنك إضافة وتعديل المستودعات',
      table: 'هذه هي قائمة المستودعات',
      addWarehouse: 'اضغط هنا لإضافة مستودع جديد',
      editWarehouse: 'اضغط الثلاثة نقاط لتعديل المستودع',
    },
    items: {
      title: 'الاصناف',
      description: 'يمكنك إضافة وتعديل الاصناف',
      table: 'هذه هي قائمة الاصناف',
      addItem: 'اضغط هنا لإضافة صنف جديد',
      editItem: 'اضغط الثلاثة نقاط لتعديل الصنف',
    },
    variants: {
      title: 'المتغيرات',
      description: 'يمكنك إضافة وتعديل المتغيرات',
      table: 'هذه هي قائمة المتغيرات',
      addVariant: 'اضغط هنا لإضافة متغير جديد',
      editVariant: 'اضغط الثلاثة نقاط لتعديل المتغير',
    },
    orders: {
      title: 'الطلبات',
      description: 'يمكنك إضافة وتعديل الطلبات',
      table: 'هذه هي قائمة الطلبات',
      addOrder: 'اضغط هنا لإضافة طلب جديد',
    },
    pos: {
      title: 'نقاط البيع',
      description: 'يمكنك إضافة وتعديل نقاط البيع',
      table: 'هذه هي قائمة نقاط البيع',
      addPos: 'اضغط هنا لإضافة نقطة بيع جديدة',
      editPos: 'اضغط الثلاثة نقاط لتعديل نقطة البيع',
    },
    devices: {
      title: 'الاجهزة',
      description: 'يمكنك إضافة وتعديل الاجهزة مثل الكاشير والطابعات',
      table: 'هذه هي قائمة الاجهزة',
      addDevice: 'اضغط هنا لإضافة جهاز جديد',
      editDevice: 'اضغط الثلاثة نقاط لتعديل الجهاز',
    },
    printers: {
      title: 'الطابعات',
      description: 'يمكنك إضافة وتعديل الطابعات',
      table: 'هذه هي قائمة الطابعات',
      addPrinter: 'اضغط هنا لإضافة طابعة جديدة',
      editPrinter: 'اضغط الثلاثة نقاط لتعديل الطابعة',
    },
    suppliers: {
      title: 'الموردين',
      description: 'يمكنك إضافة وتعديل الموردين',
      table: 'هذه هي قائمة الموردين',
      addSupplier: 'اضغط هنا لإضافة مورد جديد',
      editSupplier: 'اضغط الثلاثة نقاط لتعديل المورد',
    },
    users: {
      title: 'المستخدمين',
      description: 'يمكنك إضافة وتعديل المستخدمين',
      table: 'هذه هي قائمة المستخدمين',
      addUser: 'اضغط هنا لإضافة مستخدم جديد',
      editUser: 'اضغط الثلاثة نقاط لتعديل المستخدم',
    },
    roles: {
      title: 'الصلاحيات',
      description: 'يمكنك إضافة وتعديل الصلاحيات',
      table: 'هذه هي قائمة الصلاحيات',
      addRole: 'اضغط هنا لإضافة صلاحية جديدة',
      editRole: 'اضغط الثلاثة نقاط لتعديل الصلاحية',
    },

    customers: {
      title: 'العملاء',
      description: 'يمكنك إضافة وتعديل العملاء',
      table: 'هذه هي قائمة العملاء',
      addCustomer: 'اضغط هنا لإضافة عميل جديد',
      editCustomer: 'اضغط الثلاثة نقاط لتعديل العميل',
    },
    offers: {
      title: 'العروض',
      description: 'يمكنك إضافة وتعديل العروض',
      table: 'هذه هي قائمة العروض',
      addOffer: 'اضغط هنا لإضافة عرض جديد',
      editOffer: 'اضغط الثلاثة نقاط لتعديل العرض',
    },
    menu: {
      title: 'القائمة',
      description: 'يمكنك إضافة وتعديل القائمة',
      table: 'هذه هي قائمة القائمة',
      addMenu: 'اضغط هنا لإضافة قائمة جديدة',
      editMenu: 'اضغط الثلاثة نقاط لتعديل القائمة',
    },
    cancel_reasons: {
      title: 'أسباب الإلغاء',
      description: 'يمكنك إضافة وتعديل أسباب الإلغاء',
      table: 'هذه هي قائمة أسباب الإلغاء',
      addCancelReason: 'اضغط هنا لإضافة سبب إلغاء جديد',
      editCancelReason: 'اضغط الثلاثة نقاط لتعديل سبب الإلغاء',
    },
    eotd: {
      title: 'تقارير نهاية الجلسة',
      description: 'يمكنك عرض تقارير الجلسات',
      table: 'هذه هي قائمة تقارير نهاية الجلسات',
      viewEotd: 'اضغط الثلاثة نقاط لعرض تقرير الجلسة',
    },
    orderTransactions: {
      title: 'معاملات الطلبات',
      description: ' يمكنك عرض معاملات الطلبات مثل عمليات الدفع والإلغاء والإرجاع',
      table: 'هذه هي قائمة تقارير الطلبات',
      viewOrderTranscation: 'اضغط الثلاثة نقاط لعرض معاملةالطلب',
    },
    creditNotes: {
      title: 'اشعارات الخصم',
      description:
        'إشعار الخصم هو مستند يعطيه البائع للعميل الذي قام بإرجاع بضاعة، فهو إثبات من قبل البائع أن للعميل حق يجب دفعه له.',
      table: 'هذه هي قائمة اشعارات الخصم',
      viewCreditNote: 'اضغط الثلاثة نقاط لعرض اشعار الخصم',
      createCreditNote: 'اضغط هنا لإضافة اشعار خصم جديد',
    },
    products: {
      title: 'المنتجات',
      description: 'يمكنك إضافة وتعديل المنتجات على مستوى المؤسسة',
      table: 'هذه هي قائمة المنتجات',
      addProduct: 'اضغط هنا لإضافة منتج جديد',
      editProduct: 'اضغط الثلاثة نقاط لتعديل المنتج',
    },
    branchProducts: {
      title: 'المنتجات',
      description: 'يمكنك إضافة وتعديل المنتجات على مستوى الفرع',
      table: 'هذه هي قائمة المنتجات',
      editProduct: 'اضغط الثلاثة نقاط لتعديل الكمية او الاسعار',
      filterBranch: 'قم بفلترة المنتجات حسب الفرع',
    },
    categories: {
      title: 'الاقسام',
      description: 'يمكنك إضافة وتعديل الاقسام',
      table: 'هذه هي قائمة الاقسام',
      addCategory: 'اضغط هنا لإضافة قسم جديد',
      editCategory: 'اضغط الثلاثة نقاط لتعديل القسم',
    },
    purchaseOrders: {
      title: 'طلبات الشراء',
      description: 'يمكنك إضافة وتعديل طلبات الشراء',
      table: 'هذه هي قائمة طلبات الشراء',
      addPurchaseOrder: 'اضغط هنا لإضافة طلب شراء جديد',
      viewPurchaseOrder: 'اضغط الثلاثة نقاط لعرض طلب الشراء',
    },
    orderTypes: {
      title: 'انواع الطلبات',
      description: 'يمكنك إضافة وتعديل انواع الطلبات',
      table: 'هذه هي قائمة انواع الطلبات',
      addOrderType: 'اضغط هنا لإضافة نوع طلب جديد',
      editOrderType: 'اضغط الثلاثة نقاط لتعديل نوع الطلب',
    },
    stockTaking: {
      title: 'جرد المخزون',
      description: 'يمكنك إضافة وتعديل جرد المخزون',
      table: 'هذه هي قائمة جرد المخزون',
      addStockTaking: 'اضغط هنا لإضافة جرد جديد',
      viewStockTaking: 'اضغط الثلاثة نقاط لعرض جرد المخزون',
    },
  },
};

export default ar;
