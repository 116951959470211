import { createSlice } from '@reduxjs/toolkit';
import { TransferRequest, TransferRequestState } from 'src/@types/transferRequest';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: TransferRequestState = {
  isLoading: false,
  error: null,
  transferRequests: [],
  transferRequest: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'transferRequest',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET transferRequests  Success
    getTransferRequestsSuccess(state, action) {
      state.isLoading = false;
      state.transferRequests = action.payload;
    },

    // GET transferRequest Success
    getTransferRequestSuccess(state, action) {
      state.isLoading = false;
      state.transferRequest = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getTransferRequests() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { transferRequests: any[] } } = await axios.get(
        `/transfer-requests/${companyId}`
      );
      dispatch(slice.actions.getTransferRequestsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTransferRequest(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { transferRequest: TransferRequest } } = await axios.get(
        '/transfer-requests',
        {
          params: { name },
        }
      );
      dispatch(slice.actions.getTransferRequestSuccess(response.data.transferRequest));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// export add transfer request

export function addTransferRequest(transferRequest: TransferRequest) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { transferRequest: TransferRequest } } = await axios.post(
        '/transfer-requests/' + companyId,
        transferRequest
      );
      dispatch(slice.actions.getTransferRequestSuccess(response.data.transferRequest));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
