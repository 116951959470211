// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
// hooks
import useLocales from '../../../../hooks/useLocales';
import SupplierNewEditForm from './SupplierNewEditForm';

// ----------------------------------------------------------------------

export default function SupplierCreate() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();

  return (
    <Page title="Inventory: Create a new category">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.suppliers.create_supplier')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: translate('inventory.suppliers.create_supplier') },
          ]}
        />
        <SupplierNewEditForm />
      </Container>
    </Page>
  );
}
