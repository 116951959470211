import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack } from '@mui/material';
// routes
import {
  TransferRequest,
  TransferRequestItem,
  TransferRequestStatus,
} from 'src/@types/transferRequest';
import { UpdateWarehouse } from 'src/@types/warehouse';
import FormProvider from 'src/components/hook-form/FormProvider';
import useLocales from 'src/hooks/useLocales';
import { getSuppliers } from 'src/redux/slices/supplier';
import { addTransferRequest } from 'src/redux/slices/transferRequest';
import { useDispatch, useSelector } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import TransferNewEditAddress from './TransferNewEditAddress';
import TransferNewEditDetails from './TransferNewEditDetails';
import TransferNewEditStatusDate from './TransferNewEditStatusDate';

// ----------------------------------------------------------------------

type IFormValuesProps = Omit<
  TransferRequest,
  'createDate' | 'dueDate' | 'invoiceFrom' | 'invoiceTo'
>;

interface FormValuesProps extends IFormValuesProps {
  dueAt: Date | null;
  fromInventor: UpdateWarehouse | undefined;
  toInventory: UpdateWarehouse | undefined;
  items: TransferRequestItem[];
  total: number;
  totalTax: number;
}

type Props = {
  isEdit?: boolean;
  currentTransferRequest?: FormValuesProps;
};

export default function TransferRequestNewEditForm({ isEdit, currentTransferRequest }: Props) {
  const navigate = useNavigate();

  const [loadingSend, setLoadingSend] = useState(false);

  const { suppliers } = useSelector((state) => state.supplier);

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({
      dueAt: currentTransferRequest?.dueAt || new Date(),
      fromInventory: currentTransferRequest?.fromInventory || undefined,
      toInventory: currentTransferRequest?.toInventory || undefined,
      fromInventoryId: currentTransferRequest?.fromInventory?.id || 0,
      toInventoryId: currentTransferRequest?.toInventory?.id || 0,
      transferItems: currentTransferRequest?.items || [],
      status: currentTransferRequest?.status || TransferRequestStatus.INITIAL,
      total: currentTransferRequest?.total || 0,
      totalTax: currentTransferRequest?.totalTax || 0,
    }),
    [currentTransferRequest]
  );

  const methods = useForm<FormValuesProps>({
    // resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    getSuppliers();
  }, [suppliers]);

  useEffect(() => {
    if (isEdit && currentTransferRequest) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentTransferRequest]);

  const handleCreateAndSend = async (data: FormValuesProps) => {
    setLoadingSend(true);

    const items = data.items.map((item) => ({
      itemId: item.itemId,
      createdQty: item.createdQty,
    }));

    try {
      const transferRequest = {
        dueAt: data.dueAt,
        fromInventoryId: data.fromInventory?.id as number,
        toInventoryId: data.toInventory?.id || 0,
        transferItems: items,
        status: data.status,
      };
      dispatch(addTransferRequest(transferRequest));
      reset();
      setLoadingSend(false);
      navigate(PATH_DASHBOARD.inventory.transfersRequests);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods}>
      <Card>
        <TransferNewEditAddress />

        <TransferNewEditStatusDate />

        <TransferNewEditDetails />
      </Card>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <LoadingButton
          size="large"
          variant="contained"
          loading={loadingSend && isSubmitting}
          onClick={handleSubmit(handleCreateAndSend)}
        >
          {isEdit
            ? translate('inventory.purchase_orders.actions.Change')
            : translate('inventory.purchase_orders.actions.Add')}{' '}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
