import { createSlice } from '@reduxjs/toolkit';
// utils
import { State } from '../../../../@types/salesReport/salesReportByBranch';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

const initialState: State = {
  isLoading: false,
  error: null,
  salesByBranch: [],
  salesByCategory: [],
  salesByUser: [],
  salesByPaymentMethod: [],
  salesByCustomer: [],
};

const slice = createSlice({
  name: 'salesReportByBranch',
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
    },
    getSalesByBranchSuccess(state, action) {
      state.isLoading = false;
      state.salesByBranch = action.payload;
    },
    getSalesByCategorySuccess(state, action) {
      state.isLoading = false;
      state.salesByCategory = action.payload;
    },
    getSalesByUserSuccess(state, action) {
      state.isLoading = false;
      state.salesByUser = action.payload;
    },
    getSalesByPaymentMethodSuccess(state, action) {
      state.isLoading = false;
      state.salesByPaymentMethod = action.payload;
    },
    getSalesByCustomerSuccess(state, action) {
      state.isLoading = false;
      state.salesByCustomer = action.payload;
    },
    error(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------

export function getSalesByBranch(from: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.start());
    try {
      const response = await axios.post(`/sales-reports/${companyId}`, { from });
      dispatch(slice.actions.getSalesByBranchSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error(error.message));
    }
  };
}

export function getSalesByCategory(from: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.start());
    try {
      const response = await axios.post(`/sales-reports/${companyId}/by-category`, { from });
      dispatch(slice.actions.getSalesByCategorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error(error.message));
    }
  };
}

export function getSalesByUser(from: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.start());
    try {
      const response = await axios.post(`/sales-reports/${companyId}/by-user`, { from });
      dispatch(slice.actions.getSalesByUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error(error.message));
    }
  };
}

export function getSalesByPaymentMethod(from: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.start());
    try {
      const response = await axios.post(`/sales-reports/${companyId}/by-payment-method`, { from });
      dispatch(slice.actions.getSalesByPaymentMethodSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error(error.message));
    }
  };
}

export function getSalesByCustomer(from: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.start());
    try {
      const response = await axios.post(`/sales-reports/${companyId}/by-customer`, { from });
      dispatch(slice.actions.getSalesByCustomerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error(error.message));
    }
  };
}
