import { useCallback, useEffect } from 'react';
// @mui
import {
  Box,
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import QRCode from 'react-qr-code';
import { SaleOrder } from '../../../../../@types/saleOrder';
import Image from '../../../../../components/Image';
import Label from '../../../../../components/Label';
import Scrollbar from '../../../../../components/Scrollbar';
import useAuth from '../../../../../hooks/useAuth';
import useLocales from '../../../../../hooks/useLocales';
import { getItems } from '../../../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { fCurrency } from '../../../../../utils/formatNumber';
import { fDate } from '../../../../../utils/formatTime';
import SaleOrderToolbar from './SaleOrderToolbar';

// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  invoice: SaleOrder;
};

export default function SaleOrderDetails({ invoice }: Props) {
  const theme = useTheme();
  const { user } = useAuth();
  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.product);

  const {
    orderItems,
    totalWithTax,
    customer,
    orderInvoice,
    totalTax,
    orderDate,
    orderStatus,
    typeId,
  } = invoice;

  const getPriceByOrderType = useCallback(
    (productId: string) => {
      // filter product by id and type
      const product = items.find((product) => product.id === productId);

      // get price by type
      const price = product?.itemPrices.find((price) => price.orderTypeId === typeId)?.price;
      if (!price) return 0;
      return price;
    },
    [items, typeId]
  );

  const getInvoiceTitle = () => {
    if (customer.vatRegistrationNumber == null || customer.vatRegistrationNumber === undefined) {
      if (orderStatus !== 'CANCELLED') {
        return translate('accounting.invoicing.simplified_tax_invoice');
      }
      return translate('accounting.invoicing.simplified_tax_invoice_return');
    } else {
      if (orderStatus !== 'CANCELLED') {
        return translate('accounting.invoicing.tax_invoice');
      }
      return translate('accounting.invoicing.tax_invoice_return');
    }
  };

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  return (
    <>
      <SaleOrderToolbar invoice={invoice} getPriceByOrderType={getPriceByOrderType} />
      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: { sm: 'left' } }}>
              <Typography paragraph variant="overline">
                {' '}
                {translate('accounting.invoicing.invoice_date')}
              </Typography>
              <Typography variant="body2">{fDate(orderDate)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5, alignSelf: 'end' }}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src={user?.companyMemberships[0].company.profilePictureUrl}
              sx={{ maxWidth: '100%' }}
            />
          </Grid>

          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (orderStatus === 'paid' && 'success') ||
                  (orderStatus === 'unpaid' && 'warning') ||
                  (orderStatus === 'overdue' && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {orderStatus}
              </Label>
              <Typography>{translate('accounting.invoicing.invoice_no')}</Typography>
              <Typography variant="h6">{`INV-${orderInvoice.invoiceNumber}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Typography variant="body2" sx={{ fontWeight: '600' }}>
              {translate('accounting.invoicing.company_details')} :{' '}
            </Typography>
            <Typography sx={{ ml: 4 }} variant="body2">
              {user?.companyMemberships[0].company.name}
            </Typography>
            <Typography sx={{ ml: 4 }} variant="body2">
              {user?.companyMemberships[0].company.address}
            </Typography>
            <Typography sx={{ ml: 4 }} variant="body2">
              {user?.companyMemberships[0].company.vatRegistrationNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'center' } }}>
              <Typography variant="h6"> {customer && getInvoiceTitle()}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }} />
          </Grid>

          <Grid item xs={12} sm={12} sx={{ mb: 5 }}>
            <Typography>
              {translate('accounting.invoicing.customer')} : {customer.name}
            </Typography>
            <Typography sx={{ ml: 7 }} variant="body2">
              {customer.phone}
            </Typography>
            <Typography sx={{ ml: 7 }} variant="body2">
              {customer.vatRegistrationNumber ?? ''}
            </Typography>
          </Grid>

          {/* <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              date create
            </Typography>
           
          </Grid> */}
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">{translate('orders.description')}</TableCell>
                  <TableCell align="left">{translate('orders.qty')}</TableCell>
                  <TableCell align="right">{translate('orders.unit_price')}</TableCell>
                  <TableCell align="right">{translate('orders.total')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderItems.map((row: any, index: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{row.itemChild.item.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.qty}</TableCell>
                    <TableCell align="right">
                      {fCurrency(getPriceByOrderType(row.itemChild.id))}
                    </TableCell>
                    <TableCell align="right">
                      {fCurrency(row.itemChild.item.price * row.qty)}
                    </TableCell>
                  </TableRow>
                ))}

                {/* <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Box sx={{ mt: 2 }} />
                    <Typography>Subtotal</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Box sx={{ mt: 2 }} />
                    <Typography>{fCurrency(subTotalPrice)}</Typography>
                  </TableCell>
                </RowResultStyle> */}

                {/* <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography>Discount</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography sx={{ color: 'error.main' }}>
                      {discount && fCurrency(-discount)}
                    </Typography>
                  </TableCell>
                </RowResultStyle> */}

                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography>{translate('accounting.invoicing.invoice_tax')}</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography>{totalTax && fCurrency(totalTax)}</Typography>
                  </TableCell>
                </RowResultStyle>

                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography variant="h6">{translate('orders.total')}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="h6">{fCurrency(totalWithTax)}</Typography>
                  </TableCell>
                </RowResultStyle>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        <Grid item xs={12} sm={4} sx={{ mt: 5 }}>
          {orderInvoice.qrCode && (
            <div style={{ height: 'auto', margin: '0 auto', maxWidth: 120, width: '100%' }}>
              <QRCode
                name="QRCode"
                id="QRCode"
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={orderInvoice.qrCode}
                viewBox={`0 0 256 256`}
              />
            </div>
          )}
        </Grid>

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} md={5} sx={{ py: 3 }}>
            <Typography variant="subtitle2">
              {translate('inventory.purchase_orders.actions.payment_terms')}
            </Typography>
            <Typography variant="body2">
              {translate(`inventory.purchase_orders.terms.${orderInvoice.term}`)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">
              {translate('accounting.invoicing.generated_using')}{' '}
              {translate('accounting.invoicing.onestep_system')}{' '}
            </Typography>
            <Typography variant="body2">info@onestep.com.sa</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
