import { createSlice } from '@reduxjs/toolkit';
// utils
import { TransactionState } from '../../@types/transaction';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: TransactionState = {
  isLoading: false,
  error: null,
  orders: [],
  order: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET orders
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // GET order
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;

export function getOrders(filterStartDate: Date | null, filterEndDate: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`transactions/${companyId}/all-by-dates`, {
        from: filterStartDate,
        to: filterEndDate,
      });
      dispatch(slice.actions.getOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrder(branchId: string, orderId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`transactions/${branchId}/${orderId}`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAsDone(branchId: number, orderId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get(`transactions/${branchId}/${orderId}/make-it-done`);
      dispatch(getOrder(branchId.toString(), orderId.toString()));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
