import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';

// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
// sections
import { useEffect } from 'react';
import useLocales from 'src/hooks/useLocales';
import { getOrder } from 'src/redux/slices/order';
import { useDispatch, useSelector } from 'src/redux/store';
import OrderInvoice from './components/orderInvoice';

// ----------------------------------------------------------------------

export default function OrderDetails() {
  const { themeStretch } = useSettings();

  const { branchId, id } = useParams();

  const dispatch = useDispatch();

  const { order } = useSelector((state) => state.order);

  const { translate } = useLocales();

  useEffect(() => {
    branchId && dispatch(getOrder(branchId, id!));
  }, [branchId, dispatch, id]);

  return (
    <Page title="Invoice: View">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('orders.order_invoice')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.orders'),
              href: PATH_DASHBOARD.orders.list,
            },
            { name: `INV-${order?.orderNumber}` || '' },
          ]}
        />

        {order && <OrderInvoice order={order} />}
      </Container>
    </Page>
  );
}
