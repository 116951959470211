import { createSlice } from '@reduxjs/toolkit';
// utils
import { UnitOfMeasure, UnitOfMeasureState } from '../../@types/uom';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: UnitOfMeasureState = {
  isLoading: false,
  error: null,
  unitOfMeasures: [],
  unitOfMeasure: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'uom',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET UnitOfMeasures
    getUomsSuccess(state, action) {
      state.isLoading = false;
      state.unitOfMeasures = action.payload;
    },

    // GET unitOfMeasure
    getUomSuccess(state, action) {
      state.isLoading = false;
      state.unitOfMeasure = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getUoms() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasures: UnitOfMeasure[] } } = await axios.get(
        `/units-of-measurement/${companyId}`
      );
      dispatch(slice.actions.getUomsSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUnitOfMeasure(name: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasure: UnitOfMeasure } } = await axios.get(
        `/units-of-measurement/${companyId}`,
        {
          params: { name },
        }
      );
      dispatch(slice.actions.getUomSuccess(response.data.unitOfMeasure));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//add UnitOfMeasure
export function addUnitOfMeasure(unitOfMeasure: UnitOfMeasure) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasure: UnitOfMeasure } } = await axios.post(
        `/units-of-measurement/${companyId}`,
        unitOfMeasure
      );
      dispatch(slice.actions.getUomSuccess(response.data.unitOfMeasure));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//update UnitOfMeasure
export function updateUnitOfMeasure(id: number, unitOfMeasure: UnitOfMeasure) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasure: UnitOfMeasure } } = await axios.patch(
        `/units-of-measurement/${companyId}/${id}`,
        unitOfMeasure
      );
      dispatch(slice.actions.getUomSuccess(response.data.unitOfMeasure));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//delete UnitOfMeasure
export function deleteUnitOfMeasure(id: number) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { unitOfMeasure: UnitOfMeasure } } = await axios.delete(
        `/units-of-measurement/${companyId}/${id}`
      );
      dispatch(slice.actions.getUomSuccess(response.data.unitOfMeasure));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
