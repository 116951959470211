import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';

// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
// sections
import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import EotdInvoice from '../components/eotdInvoice';

// ----------------------------------------------------------------------

export default function EotdDetails() {
  const { themeStretch } = useSettings();

  const { id } = useParams();

  const { reports, isLoading } = useSelector((state) => state.eotd);

  const { translate } = useLocales();

  const eotd = reports.find((report) => report.id.toString() === id);

  return (
    <Page title="Eotd: View">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('eotd.eotd_details')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.eotd'),
              href: PATH_DASHBOARD.orders.eotd,
            },
            { name: `${eotd?.id}` || '' },
          ]}
        />

        <EotdInvoice eotd={eotd} isLoading={isLoading} />
      </Container>
    </Page>
  );
}
