import { createSlice } from '@reduxjs/toolkit';
// utils
import { ISupplierPaymentHistoryState } from '../../../../@types/warehouseControlReport/supplierPayment';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

// ----------------------------------------------------------------------

const initialState: ISupplierPaymentHistoryState = {
  isLoading: false,
  error: null,
  supplierPaymentHistory: null,
};

const slice = createSlice({
  name: 'supplierPaymentHistory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORT
    getSupplierPaymentHistorySuccess(state, action) {
      state.isLoading = false;
      state.supplierPaymentHistory = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;
// Actions

export const { getSupplierPaymentHistorySuccess, hasError, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getSupplierPaymentHistory(supplierId: string, from: Date | null, to: Date | null) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(
        `/warehouse-control-reports/supplier-payment-history/${companyId}/${supplierId}`,
        { from, to }
      );
      dispatch(getSupplierPaymentHistorySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function resetSupplierPaymentHistory() {
  return async () => {
    dispatch(getSupplierPaymentHistorySuccess(null));
  };
}
