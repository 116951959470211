import { useEffect } from 'react';
import { paramCase } from 'change-case';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
// sections
import { getUoms } from 'src/redux/slices/uom';
import UomNewEditForm from './UomNewEditForm';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

export default function UomEdit() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { id = '' } = useParams();

  const { translate } = useLocales();

  const { unitOfMeasures } = useSelector((state) => state.uom);

  const currentUom = unitOfMeasures.find((uom) => paramCase(uom.id.toString()) === id);

  useEffect(() => {
    dispatch(getUoms());
  }, [dispatch]);

  return (
    <Page title="Uoms: Edit Uom">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.uom.uom_edit')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: currentUom?.name as string },
          ]}
        />

        <UomNewEditForm isEdit currentUom={currentUom} />
      </Container>
    </Page>
  );
}
