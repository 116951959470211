import { createSlice } from '@reduxjs/toolkit';
// utils
import { IInventoriesActionsState } from '../../../../@types/warehouseReport/inventoryAction';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

// ----------------------------------------------------------------------

const initialState: IInventoriesActionsState = {
  isLoading: false,
  error: null,
  inventoriesActions: null,
  inventories: [],
};

const slice = createSlice({
  name: 'inventoriesActions',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORT
    getInventoriesActionsSuccess(state, action) {
      state.isLoading = false;
      state.inventoriesActions = action.payload;
    },

    // GET INVENTORY
    getInventorySuccess(state, action) {
      state.isLoading = false;
      state.inventories = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;

// Actions

export const { getInventoriesActionsSuccess, getInventorySuccess, hasError, startLoading } =
  slice.actions;

// ----------------------------------------------------------------------

export function getInventoriesActions(from: Date | null) {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());

    try {
      const response = await axios.post(`/reports/inventories-actions/${companyId}`, {
        from: from,
      });
      dispatch(getInventoriesActionsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInventory() {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());

    try {
      const response = await axios.get(`/inventory/purchase-inventory/${companyId}`);
      dispatch(getInventorySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reset() {
  return async () => {
    dispatch(getInventoriesActionsSuccess(null));
  };
}
