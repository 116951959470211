import { Card, CardContent, MenuItem, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import useLocales from 'src/hooks/useLocales';
import { Branch } from '../../../../@types/branch';
import { PointOfSale } from '../../../../@types/pointOfSale';
// components

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

const RootStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.white,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));

type Props = {
  branchesOptions: Branch[] | null;
  posOptions: PointOfSale[] | null;
  filterBranch: number | null;
  filterPos: number | null;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
  onFilterBranch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterPos: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterStartDate: (value: Date | null) => void;
  onFilterEndDate: (value: Date | null) => void;
};

export default function SalesReportsViewToolbar({
  posOptions,
  branchesOptions,
  filterPos,
  filterBranch,
  filterStartDate,
  filterEndDate,
  onFilterPos,
  onFilterBranch,
  onFilterStartDate,
  onFilterEndDate,
  ...other
}: Props) {
  const { translate } = useLocales();
  return (
    <RootStyle {...other}>
      <CardContent
        sx={{
          color: 'grey.800',
        }}
      >
        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ p: 3 }}>
          <TextField
            fullWidth
            select
            label={translate('orders.pos')}
            value={filterBranch}
            onChange={onFilterBranch}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { md: INPUT_WIDTH },
              textTransform: 'capitalize',
            }}
          >
            {branchesOptions &&
              branchesOptions.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  defaultValue={filterBranch + ''}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            fullWidth
            select
            label={translate('orders.pos')}
            value={filterPos}
            onChange={onFilterPos}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { md: INPUT_WIDTH },
              textTransform: 'capitalize',
            }}
          >
            {posOptions &&
              posOptions.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  defaultValue={filterPos + ''}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
          </TextField>

          <DatePicker
            label={translate('orders.start_date')}
            value={filterStartDate}
            onChange={onFilterStartDate}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                sx={{
                  maxWidth: { md: INPUT_WIDTH },
                }}
              />
            )}
          />

          <DatePicker
            label={translate('orders.end_date')}
            value={filterEndDate}
            onChange={onFilterEndDate}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                sx={{
                  maxWidth: { md: INPUT_WIDTH },
                }}
              />
            )}
          />
        </Stack>
      </CardContent>
    </RootStyle>
  );
}
