import { InputAdornment, Stack, TextField } from '@mui/material';
import useLocales from '../../../../../hooks/useLocales';
// components
import Iconify from '../../../../../components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  filterName: string;
  onFilterName: (value: string) => void;
};

export default function TaxTableToolbar({ filterName, onFilterName }: Props) {
  const { translate } = useLocales();
  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={translate('company.taxes.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
