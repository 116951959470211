import { createSlice } from '@reduxjs/toolkit';
// utils
import { AddonIngredient, AddonIngredientState } from '../../@types/addonIngredient';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: AddonIngredientState = {
  isLoading: false,
  error: null,
  ingredients: [],
  ingredient: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'addonIngredient',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET addonIngredients
    getAddonIngredientsSuccess(state, action) {
      state.isLoading = false;
      state.ingredients = action.payload;
    },

    // GET productIngredient
    getAddonIngredientSuccess(state, action) {
      state.isLoading = false;
      state.ingredient = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// // Actions
// export const { startLoading, hasError, getProductsSuccess, getProductSuccess, sortByProducts, filterProducts } = slice.actions;
// } = slice.actions;

// ----------------------------------------------------------------------

export function getAddonIngredients(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredients: AddonIngredient[] } } = await axios.get(
        `/ingredients/${defaultBranchId}/add-on/${id}`
      );
      dispatch(slice.actions.getAddonIngredientsSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddonIngredient(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: AddonIngredient } } = await axios.get(
        `/ingredients/${defaultBranchId}/add-on/${id}`
      );
      dispatch(slice.actions.getAddonIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAddonIngredient(data: AddonIngredient) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: AddonIngredient } } = await axios.post(
        `/ingredients/${defaultBranchId}/add-on`,
        data
      );
      dispatch(slice.actions.getAddonIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function updateAddonIngredient(data: AddonIngredient, id: number) {
  const defaultBranchId = localStorage.getItem('defaultBranchId');
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: { ingredient: AddonIngredient } } = await axios.patch(
      `/ingredients/${defaultBranchId}/add-on/${id}`,
      data
    );
    dispatch(slice.actions.getAddonIngredientSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

// ----------------------------------------------------------------------

export function deleteAddonIngredient(id: number) {
  return async () => {
    const defaultBranchId = localStorage.getItem('defaultBranchId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { ingredient: AddonIngredient } } = await axios.delete(
        `/ingredients/${defaultBranchId}/add-on/${id}`
      );
      dispatch(slice.actions.getAddonIngredientSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
