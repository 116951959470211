import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
// utils
import { fDate } from '../../../../../../utils/formatTime';
// @types
import { Order } from '../../../../../../@types/order';
//
import useLocales from 'src/hooks/useLocales';
import styles from './InvoiceStyle';

// ----------------------------------------------------------------------

type Props = {
  order: Order;
  user: any;
};

export default function InvoicePDF({ order, user }: Props) {
  const { orderItems, totalWithTax, orderStatus, orderDate, total, orderNumber } = order;

  const { translate } = useLocales();

  return (
    <Document>
      <Page size="A4" style={[styles.page, { textAlign: 'right' }]}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <Image
            source={user?.companyMemberships[0].company.profilePictureUrl}
            style={{ height: 32 }}
          />
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h3}>{translate(`orders.${orderStatus}`)}</Text>
            <Text> {`INV-${orderNumber}`} </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col8}>
            <Text style={[styles.overline, styles.mb8]} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
            <Text style={styles.body1} />
          </View>
          <View style={styles.col4}>
            <Text style={[styles.overline, styles.mb8]}>{translate('orders.invoice_from')}</Text>
            <Text style={styles.body1}>{user?.name}</Text>
            <Text style={styles.body1}>{user?.companyMemberships[0].company.address || ''}</Text>
            <Text style={styles.body1}>
              {user?.companyMemberships[0].company.vatRegistrationNumber || ''}
            </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={[styles.col6]}>
            <Text style={[styles.overline, styles.mb8]}>{translate('orders.due_date')}</Text>
            <Text style={styles.body1}>{fDate(orderDate)}</Text>
          </View>
          <View style={[styles.col6]}>
            <Text style={[styles.overline, styles.mb8]}>{translate('orders.create_date')}</Text>
            <Text style={styles.body1}>{fDate(orderDate)}</Text>
          </View>
        </View>

        <Text style={[styles.overline, styles.mb8]}>{translate('orders.order_invoice')}</Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>{translate('orders.total')}</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>{translate('orders.unit_price')}</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>{translate('orders.qty')}</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>{translate('orders.description')}</Text>
              </View>

              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {orderItems.map((item, index) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>
                    {item.itemChild.item.price * parseInt(item.qty)} {translate('orders.SR')}
                  </Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item.itemChild.item.price}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item.qty}</Text>
                </View>

                <View style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>{item.itemChild.item.name}</Text>
                  <Text>{item.itemChild.item.description}</Text>
                </View>

                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>
              </View>
            ))}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>
                  {total} {translate('orders.SR')}
                </Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text>{translate('orders.subtotal')}</Text>
              </View>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
            </View>

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>
                  {totalWithTax} {translate('orders.SR')}
                </Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text>{translate('orders.total_with_tax')}</Text>
              </View>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
            </View>

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>
                  {totalWithTax} {translate('orders.SR')}
                </Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.h4}>{translate('orders.total')}</Text>
              </View>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
            </View>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col8}>
            <Text style={styles.subtitle2}>{translate('orders.notes')}</Text>
            <Text>{translate('orders.thank_u_note')}</Text>
          </View>
          <View style={[styles.col4, styles.alignRight]}>
            <Text style={styles.subtitle2}>{translate('orders.have_aq')}</Text>
            <Text>{user?.emails[0].email}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
