import { paramCase } from 'change-case';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
// sections
import useLocales from 'src/hooks/useLocales';
import { getVariantIngredients } from 'src/redux/slices/variantIngredient';
import VariantActionNewEditForm from './VariantActionNewEditForm';

// ----------------------------------------------------------------------

export default function VariantActionEdit() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { id = '' } = useParams();

  const { translate } = useLocales();

  const { ingredients } = useSelector((state) => state.variantIngredient);

  const currentVariantIngredient = ingredients.find(
    (ingredient) => paramCase(ingredient.id.toString()) === id
  );

  useEffect(() => {
    dispatch(getVariantIngredients(parseInt(id)));
  }, [dispatch, id]);

  return (
    <Page title="Inventory: Edit Variant Actions">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.products.edit_variants')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: currentVariantIngredient?.item.name as string },
          ]}
        />

        <VariantActionNewEditForm
          isEdit
          currentVariantIngredient={currentVariantIngredient}
          id={id}
        />
      </Container>
    </Page>
  );
}
