import { ReactElement, ReactNode, useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import { AbilityContext } from 'src/contexts/Can';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from '../components/LoadingScreen';
import navConfig from '../layouts/dashboard/navbar/NavConfig';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const ability = useContext(AbilityContext);

  const { isAuthenticated, isInitialized } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  for (const navConfigItem of navConfig) {
    for (const item of navConfigItem.items) {
      const meta = findMetaRecursive([item], pathname);
      if (meta) {
        const can = ability.can(meta?.action, meta?.resource);
        if (can === false) {
          return <Navigate to={PATH_PAGE.page403} />;
        }
      }
    }
  }
  return <>{children}</>;
}

interface MenuItem {
  title: string;
  path: string;
  icon?: ReactElement;
  meta?: {
    action: string;
    resource: string;
  };
  children?: MenuItem[];
} // This will print the meta object for the specified path

function findMetaRecursive(
  menuItems: MenuItem[],
  currentPath: string
): { action: string; resource: string } | null {
  for (const menuItem of menuItems) {
    for (const item of menuItem.children || []) {
      if (currentPath === item.path) {
        return item.meta!;
      }
    }
  }
  return null;
}
