// @mui
import { Button, Dialog, ListItemButton, Stack, Typography } from '@mui/material';
import { UpdateWarehouse } from 'src/@types/warehouse';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  selected: (selectedId: string) => boolean;
  onClose: VoidFunction;
  onSelect: (address: UpdateWarehouse | null) => void;
  addressOptions: UpdateWarehouse[];
};

export default function TransferFromInventoryListDialog({
  open,
  selected,
  onClose,
  onSelect,
  addressOptions,
}: Props) {
  const handleSelect = (address: UpdateWarehouse | null) => {
    onSelect(address);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6"> Select address </Typography>

        <Button
          size="small"
          variant="outlined"
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ alignSelf: 'flex-end' }}
        >
          Add New
        </Button>
      </Stack>

      <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8 }}>
        {addressOptions.map((address) => (
          <ListItemButton
            key={address.id}
            selected={selected(address.id?.toString() || '')}
            onClick={() => handleSelect(address)}
            sx={{
              p: 1.5,
              borderRadius: 1,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="subtitle2">{address.name}</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {address.address}
            </Typography>
          </ListItemButton>
        ))}
      </Scrollbar>
    </Dialog>
  );
}
