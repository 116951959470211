import { createSlice } from '@reduxjs/toolkit';
// utils
import { ISupplierDebitsState } from '../../../../@types/warehouseControlReport/suppliersDebits';
import axios from '../../../../utils/axios';
//
import { dispatch } from '../../../store';

// ----------------------------------------------------------------------

const initialState: ISupplierDebitsState = {
  isLoading: false,
  error: null,
  supplierDebits: null,
};

const slice = createSlice({
  name: 'supplierDebits',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORT
    getSupplierDebitsSuccess(state, action) {
      state.isLoading = false;
      state.supplierDebits = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;

// Actions

export const { getSupplierDebitsSuccess, hasError, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getSupplierDebits() {
  const companyId = localStorage.getItem('companyId');

  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(
        `/warehouse-control-reports/suppliers-payment-history/${companyId}`
      );
      dispatch(getSupplierDebitsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
