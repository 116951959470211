import Container from '@mui/material/Container';
import React from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import MenuNewEditFrom from './MenuNewEditForm';
import { PATH_DASHBOARD } from 'src/routes/paths';

export default function MenuCreate() {
  const { translate } = useLocales();

  const { themeStretch } = useSettings();

  return (
    <Page title={translate('menus.add')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('menus.add')}
          links={[
            { name: translate('routes.root'), href: PATH_DASHBOARD.root },
            { name: translate('menus.title'), href: PATH_DASHBOARD.orders.menu.list },
            { name: translate('menus.add') },
          ]}
        />

        <MenuNewEditFrom isEdit={false} />
      </Container>
    </Page>
  );
}
