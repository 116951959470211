// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  reports: {
    // root: path(ROOTS_DASHBOARD, '/reports'),
    warehouseControl: {
      root: path(ROOTS_DASHBOARD, '/reports/warehouse-control'),
      supplierPayment: path(ROOTS_DASHBOARD, '/reports/warehouse-control/supplier-payment'),
      companyStockTaking: path(ROOTS_DASHBOARD, '/reports/warehouse-control/company-stock-taking'),
      supplierDebits: path(ROOTS_DASHBOARD, '/reports/warehouse-control/supplier-debits'),
    },
    tax: {
      taxReport: path(ROOTS_DASHBOARD, '/reports/tax-reports/tax-report'),
      branchesTaxReport: path(ROOTS_DASHBOARD, '/reports/tax-reports/branches-tax-report'),
    },
    warehouse: {
      idleStock: path(ROOTS_DASHBOARD, '/reports/warehouse/idle-stock'),
      inventoriesActions: path(ROOTS_DASHBOARD, '/reports/warehouse/inventories-actions'),
      stockValue: path(ROOTS_DASHBOARD, '/reports/warehouse/stock-value'),
    },
    sales: {
      customersPayments: path(ROOTS_DASHBOARD, '/reports/sales-report/customers-payments'),
      salesReportByBranch: path(ROOTS_DASHBOARD, '/reports/sales-report/sales-report-by-branch'),
      salesReportByCategory: path(
        ROOTS_DASHBOARD,
        '/reports/sales-report/sales-report-by-category'
      ),
      salesReportByUser: path(ROOTS_DASHBOARD, '/reports/sales-report/sales-report-by-user'),
      salesReportByPaymentMethod: path(
        ROOTS_DASHBOARD,
        '/reports/sales-report/sales-report-by-payment-method'
      ),
      salesReportByCustomer: path(
        ROOTS_DASHBOARD,
        '/reports/sales-report/sales-report-by-customer'
      ),
    },
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  usersManagement: {
    users: {
      new: path(ROOTS_DASHBOARD, '/users-management/users/create'),
      list: path(ROOTS_DASHBOARD, '/users-management/users'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/users-management/users/${id}/edit`),
    },
    role: {
      new: path(ROOTS_DASHBOARD, '/users-management/roles/create'),
      list: path(ROOTS_DASHBOARD, '/users-management/roles'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/users-management/roles/${id}/edit`),
    },
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    branches: {
      root: path(ROOTS_DASHBOARD, '/company/branches'),
      create: path(ROOTS_DASHBOARD, '/company/branches/create'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/company/branches/edit/${id}`),
    },
    warehouses: {
      root: path(ROOTS_DASHBOARD, '/company/warehouses'),
      create: path(ROOTS_DASHBOARD, '/company/warehouses/create'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/company/warehouses/edit/${id}`),
    },
    pointsOfSale: {
      root: path(ROOTS_DASHBOARD, '/company/points-of-sale'),
    },
    taxes: {
      root: path(ROOTS_DASHBOARD, '/company/taxes'),
    },
    purchaseTaxes: {
      root: path(ROOTS_DASHBOARD, '/company/purchase-taxes'),
    },
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    warehouse: path(ROOTS_DASHBOARD, '/inventory/products'),
    create: path(ROOTS_DASHBOARD, '/inventory/products/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/inventory/products/edit/${id}`),
    inventoryItems: path(ROOTS_DASHBOARD, '/inventory/inventory-items'),
    createInventoryItem: path(ROOTS_DASHBOARD, '/inventory/inventory-items/create'),
    actions: (id: string) => path(ROOTS_DASHBOARD, `/inventory/actions/${id}`),
    createActions: (id: string) => path(ROOTS_DASHBOARD, `/inventory/actions/create/${id}`),
    editActions: (id: string) => path(ROOTS_DASHBOARD, `/inventory/actions/edit/${id}`),
    categories: path(ROOTS_DASHBOARD, '/inventory/categories'),
    createCategory: path(ROOTS_DASHBOARD, '/inventory/categories/create'),
    editCategory: (id: string) => path(ROOTS_DASHBOARD, `/inventory/categories/edit/${id}`),
    suppliers: path(ROOTS_DASHBOARD, '/inventory/suppliers'),
    createSupplier: path(ROOTS_DASHBOARD, '/inventory/suppliers/create'),
    editSupplier: (id: string) => path(ROOTS_DASHBOARD, `/inventory/suppliers/edit/${id}`),
    devices: path(ROOTS_DASHBOARD, '/inventory/devices'),
    editVariant: (id: string) => path(ROOTS_DASHBOARD, `/inventory/variants/edit/${id}`),
    variants: (id: string) => path(ROOTS_DASHBOARD, `/inventory/variants/${id}`),
    createVariant: (id: string) => path(ROOTS_DASHBOARD, `/inventory/variants/create/${id}`),
    variantActions: (id: string) => path(ROOTS_DASHBOARD, `/inventory/variants/actions/${id}`),
    createVariantActions: (id: string) =>
      path(ROOTS_DASHBOARD, `/inventory/variants/actions/create/${id}`),
    editVariantActions: (id: string) =>
      path(ROOTS_DASHBOARD, `/inventory/variants/actions/edit/${id}`),
    addons: (id: string) => path(ROOTS_DASHBOARD, `/inventory/addons/${id}`),
    createAddon: (id: string) => path(ROOTS_DASHBOARD, `/inventory/addons/create/${id}`),
    editAddon: (id: string) => path(ROOTS_DASHBOARD, `/inventory/addons/edit/${id}`),
    addonActions: (id: string) => path(ROOTS_DASHBOARD, `/inventory/addons/actions/${id}`),
    createAddonActions: (id: string) =>
      path(ROOTS_DASHBOARD, `/inventory/addons/actions/create/${id}`),
    editAddonActions: (id: string) => path(ROOTS_DASHBOARD, `/inventory/addons/actions/edit/${id}`),
    editDevice: (id: string) => path(ROOTS_DASHBOARD, `/inventory/warehouse/device/edit/${id}`),
    createDevice: path(ROOTS_DASHBOARD, '/inventory/warehouse/device/create'),
    units: path(ROOTS_DASHBOARD, '/inventory/units'),
    createUnit: path(ROOTS_DASHBOARD, '/inventory/units/create'),
    editUnit: (id: string) => path(ROOTS_DASHBOARD, `/inventory/units/edit/${id}`),
    unitsConversion: (id: string) => path(ROOTS_DASHBOARD, `/inventory/units/conversion/${id}`),
    createUnitsConversion: (id: any) =>
      path(ROOTS_DASHBOARD, `/inventory/units/conversion/create/${id}`),
    editUnitsConversion: (id: any) =>
      path(ROOTS_DASHBOARD, `/inventory/units/conversion/edit/${id}`),
    // order types
    orderTypes: path(ROOTS_DASHBOARD, '/inventory/order-types'),
    purchaseOrders: path(ROOTS_DASHBOARD, '/inventory/purchase-orders'),
    createPurchaseOrder: path(ROOTS_DASHBOARD, '/inventory/purchase-orders/create'),
    viewPurchaseOrder: (id: string) => path('/dashboard/inventory/purchase-orders', `/${id}`),
    transfersRequests: path(ROOTS_DASHBOARD, '/inventory/transfers-requests'),
    createTransferRequest: path(ROOTS_DASHBOARD, '/inventory/transfers-requests/create'),
    viewTransferRequest: (id: string) => path('/dashboard/inventory/transfers-requests', `/${id}`),
    stockTaking: path(ROOTS_DASHBOARD, '/inventory/stock-takings'),
    createStockTaking: path(ROOTS_DASHBOARD, '/inventory/stock-takings/create'),
    viewStockTaking: (id: string) => path('/dashboard/inventory/stock-takings', `/${id}`),
  },
  invoicing: {
    root: path(ROOTS_DASHBOARD, '/accounting/invoicing'),
    create: path(ROOTS_DASHBOARD, '/accounting/invoicing/create'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/accounting/invoicing/${id}`),
  },
  creditNotes: {
    root: path(ROOTS_DASHBOARD, '/accounting/creditNotes'),
    create: path(ROOTS_DASHBOARD, '/accounting/creditNotes/create'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/accounting/creditNotes/${id}`),
  },
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    list: path(ROOTS_DASHBOARD, '/orders/list'),
    canceled: path(ROOTS_DASHBOARD, '/orders/canceled'),
    new: path(ROOTS_DASHBOARD, '/orders/new'),
    eotd: path(ROOTS_DASHBOARD, '/orders/reports/eotd'),
    viewEotd: (id: string) => path(ROOTS_DASHBOARD, `/orders/reports/eotd/${id}`),
    view: (branchId: string, id: string) => path(ROOTS_DASHBOARD, `/orders/${branchId}/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/orders/${id}/edit`),
    transactions: {
      root: path(ROOTS_DASHBOARD, '/orders/transactions'),
      view: (branchId: string, id: string) =>
        path(ROOTS_DASHBOARD, `/orders/transactions/${branchId}/${id}`),
    },
    customer: {
      root: path(ROOTS_DASHBOARD, '/orders/customer'),
      list: path(ROOTS_DASHBOARD, '/orders/customer/list'),
      new: path(ROOTS_DASHBOARD, '/orders/customer/new'),
      view: (id: number) => path(ROOTS_DASHBOARD, `/orders/customer/${id}`),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/orders/customer/${id}/edit`),
    },
    discount: {
      root: path(ROOTS_DASHBOARD, '/orders/discount'),
      list: path(ROOTS_DASHBOARD, '/orders/discount/list'),
      new: path(ROOTS_DASHBOARD, '/orders/discount/new'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/orders/discount/${id}`),
      view: (id: number) => path(ROOTS_DASHBOARD, `/orders/discount/${id}/details`),
    },
    menu: {
      root: path(ROOTS_DASHBOARD, '/orders/menu'),
      list: path(ROOTS_DASHBOARD, '/orders/menu/list'),
      new: path(ROOTS_DASHBOARD, '/orders/menu/new'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/orders/menu/${id}`),
      view: (id: number) => path(ROOTS_DASHBOARD, `/orders/menu/${id}/details`),
    },
    cancelReason: {
      root: path(ROOTS_DASHBOARD, '/orders/cancel-reasons'),
      statistics: path(ROOTS_DASHBOARD, '/orders/cancel-reasons/statistics'),
      details: (id: number) => path(ROOTS_DASHBOARD, `/orders/cancel-reasons/${id}`),
    },
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
