import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import qr from 'qr-image';
import { useEffect, useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import styles from './MenuPreviewStyle';

// ----------------------------------------------------------------------

type Props = {
  menu: any;
  user: any;
};

export default function MenuPreviewPDF({ menu, user }: Props) {
  const { url } = menu;

  const { translate } = useLocales();
  let [QRCode, setQRCode] = useState<any>(null);

  useEffect(() => {
    if (url) {
      setQRCode(generateQRCode(url));
    }
  }, [url]);

  return (
    <Document>
      <Page size="A4" style={[styles.page, { textAlign: 'right' }]}>
        <View style={[styles.gridContainer, { marginBottom: 30 }]} />
        <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
          <Image src={user?.companyMemberships[0].company.profilePictureUrl} style={styles.image} />
          <Text style={{ marginLeft: 20 }}>{user?.companyMemberships[0].company.name}</Text>
        </View>
        <View style={{ marginTop: 100 }}>
          <Text
            style={{
              marginTop: 300,
              height: 'auto',
              margin: '0 auto',
              maxWidth: 200,
              width: '100%',
              fontSize: 12,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {translate('menus.scan_code')} {'\n'}
            {translate('menus.for_menu')}
          </Text>
        </View>
        {/* <View style={{ marginTop: 20 }}>
          <Iconify icon="ic:outline-keyboard-double-arrow-down" width={50} height={50} />
        </View> */}
        <View style={{ marginTop: 20 }}>
          {url && (
            <View style={{ height: 'auto', margin: '0 auto', maxWidth: 120, width: '100%' }}>
              <Image src={QRCode} />
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
}

const generateQRCode = (text: string): string => {
  const qrCode = qr.imageSync(text, { type: 'png' });
  return `data:image/png;base64,${qrCode.toString('base64')}`;
};
