//types
import { Discount, DiscountState, UpdateDiscount } from '../../@types/discount';
// utils
import axios from '../../utils/axios';
// Redux
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: DiscountState = {
  isInitialized: false,
  discounts: [],
};

const slice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    getDiscountsSuccess: (state, action) => {
      state.discounts = action.payload;
      state.isInitialized = true;
    },
    createDiscountSuccess: (state, action) => {
      state.discounts?.push(action.payload);
    },
    applyDiscountSuccess: (state, action) => {
      const appliedDiscount = state.discounts?.find(
        (discount) => discount.id === action.payload.id
      );
      if (appliedDiscount) {
        appliedDiscount.isApplied = true;
        state.discounts = state.discounts?.map((discount) => {
          if (discount.id === action.payload.id) {
            return appliedDiscount;
          }
          return discount;
        });
      }
    },
    unApplyDiscountSuccess: (state, action) => {
      const unAppliedDiscount = state.discounts?.find(
        (discount) => discount.id === action.payload.id
      );
      if (unAppliedDiscount) {
        unAppliedDiscount.isApplied = false;
      }
    },
    // UPDATE Discount
    updateDiscountSuccess: (state, action) => {
      state.discounts = state.discounts?.map((discount) => {
        if (discount.id === action.payload.id) {
          return action.payload;
        }
        return discount;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getDiscountsSuccess,
  createDiscountSuccess,
  applyDiscountSuccess,
  unApplyDiscountSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getDiscounts() {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    const response = await axios.get<{ data: Discount[] }>(`/discounts/${companyId}`);
    dispatch(getDiscountsSuccess(response.data));
  };
}

export function createDiscount(discount: UpdateDiscount) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    const response = await axios.post<{ data: Discount }>(`/discounts/${companyId}`, discount);
    dispatch(createDiscountSuccess(response.data));
  };
}

export function updateDiscount(discountId: number, discount: UpdateDiscount) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.patch(`/discounts/${companyId}/${discountId}`, discount);
    dispatch(slice.actions.updateDiscountSuccess(discount));
  };
}

export function deleteDiscount(id: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.delete(`/discounts/${companyId}/${id}`);
  };
}

export function applyDiscount(id: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    const response = await axios.get(`/discounts/${companyId}/${id}/apply/`);
    dispatch(applyDiscountSuccess(response.data));
  };
}

export function unApplyDiscount(id: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    const response = await axios.get(`/discounts/${companyId}/${id}/unApply/`);
    dispatch(unApplyDiscountSuccess(response.data));
  };
}

export function addBranchToDiscount(id: number, branchId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.get(`/discounts/${companyId}/${id}/branch/${branchId}`);
  };
}

export function removeBranchFromDiscount(id: number, branchId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.delete(`/discounts/${companyId}/${id}/branch/${branchId}`);
  };
}

export function addCategoryToDiscount(id: number, categoryId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.get(`/discounts/${companyId}/${id}/category/${categoryId}`);
  };
}

export function removeCategoryFromDiscount(id: number, categoryId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.delete(`/discounts/${companyId}/${id}/category/${categoryId}`);
  };
}

export function addItemToDiscount(id: number, itemId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.get(`/discounts/${companyId}/${id}/item/${itemId}`);
  };
}

export function removeItemFromDiscount(id: number, itemId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.delete(`/discounts/${companyId}/${id}/item/${itemId}`);
  };
}

export function addCustomerToDiscount(id: number, customerId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.get(`/discounts/${companyId}/${id}/customer/${customerId}`);
  };
}

export function removeCustomerFromDiscount(id: number, customerId: number) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    await axios.delete(`/discounts/${companyId}/${id}/customer/${customerId}`);
  };
}
