import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import SaleOrderDetails from 'src/pages/dashboard/accounting/invoicing/SaleOrderDetails';
import PurchasesTaxesList from 'src/pages/dashboard/company/purchaseTaxes/PurchaseTaxesList';
import TaxesList from 'src/pages/dashboard/company/taxes/TaxesList';
import MenuCreate from 'src/pages/dashboard/orders/menus/MenuCreate';
import MenuEdit from 'src/pages/dashboard/orders/menus/MenuEdit';
import MenuList from 'src/pages/dashboard/orders/menus/MenuList';
import MenuViewDetails from 'src/pages/dashboard/orders/menus/MenuViewDetails';
import PurchaseOrderCreate from 'src/pages/dashboard/wareHouse/purchases/PurchaseOrderCreate';
import PurchaseOrdersList from 'src/pages/dashboard/wareHouse/purchases/PurchaseOrdersList';
import StockTakingDetails from 'src/pages/dashboard/wareHouse/stockTaking/StockTakingDetails';
import TransferRequestCreate from 'src/pages/dashboard/wareHouse/transfers/TransferRequestCreate';
import TransferRequestDetails from 'src/pages/dashboard/wareHouse/transfers/TransferRequestDetails';
import TransfersRequestsList from 'src/pages/dashboard/wareHouse/transfers/TransfersRequestsList';
import LoadingScreen from '../components/LoadingScreen';
import EotdDetails from '../pages/dashboard/orders/endOfDay/EndOfDayDetails';
import EndOfDayReports from '../pages/dashboard/orders/endOfDay/EndOfDayReports';
import OrderDetails from '../pages/dashboard/orders/ordersHistory/OrderDetails';
import SalesReports from '../pages/dashboard/reports/SalesReports';
import ActionsCreate from '../pages/dashboard/wareHouse/actions/ActionsCreate';
import ActionsEdit from '../pages/dashboard/wareHouse/actions/ActionsEdit';
import ActionsList from '../pages/dashboard/wareHouse/actions/ActionsList';
import AddonCreate from '../pages/dashboard/wareHouse/addons/AddonCreate';
import AddonEdit from '../pages/dashboard/wareHouse/addons/AddonEdit';
import AddonsTable from '../pages/dashboard/wareHouse/addons/AddonsTable';
import AddonActionCreate from '../pages/dashboard/wareHouse/addonsActions/AddonActionCreate';
import AddonActionEdit from '../pages/dashboard/wareHouse/addonsActions/AddonActionEdit';
import AddonActionsList from '../pages/dashboard/wareHouse/addonsActions/AddonsActionList';
import CategoryCreate from '../pages/dashboard/wareHouse/categories/CategoryCreate';
import InventoryCategoryEdit from '../pages/dashboard/wareHouse/categories/CategoryEdit';
import InventoryWarehouseCategoryList from '../pages/dashboard/wareHouse/categories/CategoryList';
import OrderTypesList from '../pages/dashboard/wareHouse/order-types/OrderTypesList';
import SupplierCreate from '../pages/dashboard/wareHouse/suppliers/SupplierCreate';
import SupplierEdit from '../pages/dashboard/wareHouse/suppliers/SupplierEdit';
import ConversionCreate from '../pages/dashboard/wareHouse/uom/conversions/ConversionCreate';
import ConversionEdit from '../pages/dashboard/wareHouse/uom/conversions/ConversionEdit';
import ConversionsList from '../pages/dashboard/wareHouse/uom/conversions/ConversionsList';
import UomCreate from '../pages/dashboard/wareHouse/uom/UomCreate';
import UomEdit from '../pages/dashboard/wareHouse/uom/UomEdit';
import UomList from '../pages/dashboard/wareHouse/uom/UomList';
import VariantActionEdit from '../pages/dashboard/wareHouse/variantActions/VariantActionEdit';
import VariantActionsList from '../pages/dashboard/wareHouse/variantActions/VariantActionList';
import VariantsTable from '../pages/dashboard/wareHouse/variants/VariantsTable';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'company',
          children: [
            { path: 'branches', element: <BrachList /> },
            { path: 'branches/create', element: <BranchCreate /> },
            { path: 'branches/edit/:id', element: <BranchEdit /> },
            { path: 'warehouses', element: <WarehouseList /> },
            { path: 'warehouses/create', element: <WarehouseCreate /> },
            { path: 'warehouses/edit/:id', element: <WarehouseEdit /> },
            { path: 'points-of-sale', element: <PointOfSaleList /> },
            { path: 'taxes', element: <TaxesList /> },
            { path: 'purchase-taxes', element: <PurchasesTaxesList /> },
          ],
        },
        {
          path: 'reports',
          children: [
            { path: 'sales', element: <SalesReports /> },
            { path: 'sales-report/customers-payments', element: <CustomersPayments /> },
            { path: 'sales-report/sales-report-by-branch', element: <SalesReportByBranch /> },
            { path: 'sales-report/sales-report-by-category', element: <SalesReportByCategory /> },
            { path: 'sales-report/sales-report-by-user', element: <SalesReportByUser /> },
            {
              path: 'sales-report/sales-report-by-payment-method',
              element: <SalesReportByPaymentMethod />,
            },
            {
              path: 'sales-report/sales-report-by-customer',
              element: <SalesReportByUserCustomer />,
            },

            // supplier payment
            { path: 'warehouse-control/supplier-payment', element: <SupplierPayment /> },
            { path: 'warehouse-control/supplier-debits', element: <SuppliersDebits /> },
            // tax report
            { path: 'tax-reports/tax-report', element: <TaxReport /> },
            { path: 'tax-reports/branches-tax-report', element: <BranchesTaxReport /> },
            // company stock taking
            { path: 'warehouse-control/company-stock-taking', element: <CompanyStockTaking /> },
            // warehouse
            { path: 'warehouse/idle-stock', element: <IdleStock /> },
            { path: 'warehouse/inventories-actions', element: <InventoriesActions /> },
            { path: 'warehouse/stock-value', element: <StockValue /> },
            // { path: 'inventory', element: <InventoryReport /> },
            // { path: 'expenses', element: <ExpensesReport /> },
            // { path: 'profit-and-loss', element: <ProfitAndLossReport /> },
            // { path: 'balance-sheet', element: <BalanceSheetReport /> },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'users-management',
          children: [
            { element: <Navigate to="/dashboard/users-management/users" replace />, index: true },
            { path: 'users', element: <UserList /> },
            { path: 'users/create', element: <UserCreate /> },
            { path: 'users/:id/edit', element: <UserCreate /> },
            { path: 'roles', element: <RoleList /> },
            { path: 'roles/create', element: <RoleCreate /> },
            { path: 'roles/:id/edit', element: <RoleEdit /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/dashboard/inventory" replace />, index: true },
            { path: 'products', element: <ProductList /> },
            { path: 'products/:name', element: <ProductCreate /> },
            { path: 'products/edit/:id', element: <ProductEdit /> },
            { path: 'inventory-items', element: <InventoryItemList /> },
            { path: 'inventory-items/create', element: <InventoryItemCreate /> },
            { path: 'actions/create/:id', element: <ActionsCreate /> },
            { path: 'actions/edit/:id', element: <ActionsEdit /> },
            { path: 'categories', element: <InventoryWarehouseCategoryList /> },
            { path: 'actions/:id', element: <ActionsList /> },
            { path: 'units', element: <UomList /> },
            { path: 'units/create', element: <UomCreate /> },
            { path: 'units/edit/:id', element: <UomEdit /> },
            { path: 'units/conversion/:id', element: <ConversionsList /> },
            { path: 'units/conversion/create/:id', element: <ConversionCreate /> },
            { path: 'units/conversion/edit/:id', element: <ConversionEdit /> },
            { path: 'categories/create', element: <CategoryCreate /> },
            { path: 'categories/edit/:id', element: <InventoryCategoryEdit /> },
            { path: 'variants/:id', element: <VariantsTable /> },
            { path: 'variants/actions/:id', element: <VariantActionsList /> },
            { path: 'variants/actions/create/:id', element: <VariantActionCreate /> },
            { path: 'variants/actions/edit/:id', element: <VariantActionEdit /> },
            { path: 'addons/:id', element: <AddonsTable /> },
            { path: 'addons/create/:id', element: <AddonCreate /> },
            { path: 'addons/edit/:id', element: <AddonEdit /> },
            { path: 'addons/actions/:id', element: <AddonActionsList /> },
            { path: 'addons/actions/create/:id', element: <AddonActionCreate /> },
            { path: 'addons/actions/edit/:id', element: <AddonActionEdit /> },
            { path: 'suppliers', element: <SuppliersList /> },
            { path: 'suppliers/create', element: <SupplierCreate /> },
            { path: 'suppliers/edit/:id', element: <SupplierEdit /> },
            { path: 'devices', element: <DevicesList /> },
            { path: 'warehouse/device/create', element: <DeviceCreate /> },
            { path: 'warehouse/device/edit/:id', element: <DeviceEdit /> },
            // purchase orders
            { path: 'purchase-orders', element: <PurchaseOrdersList /> },
            { path: 'purchase-orders/create', element: <PurchaseOrderCreate /> },
            { path: 'purchase-orders/:id', element: <PurchaseOrderDetails /> },
            // Order Types
            { path: 'order-types', element: <OrderTypesList /> },
            // transfers requests list
            { path: 'transfers-requests', element: <TransfersRequestsList /> },
            { path: 'transfers-requests/create', element: <TransferRequestCreate /> },
            { path: 'transfers-requests/:id', element: <TransferRequestDetails /> },
            // stock taking
            { path: 'stock-takings', element: <StockTakingList /> },
            { path: 'stock-takings/create', element: <StockTakingCreate /> },
            { path: 'stock-takings/:id', element: <StockTakingDetails /> },
          ],
        },
        {
          path: 'accounting',
          children: [
            { path: 'invoicing', element: <SaleOrderList /> },
            { path: 'invoicing/create', element: <SaleOrderCreate /> },
            { path: 'invoicing/:id', element: <SaleOrderDetails /> },
            { path: 'creditNotes', element: <CreditNotesList /> },
            { path: 'creditNotes/create', element: <CreditNotesCreate /> },
            { path: 'creditNotes/:id', element: <CreditNoteDetails /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/dashboard/orders/" replace />, index: true },
            { path: 'list', element: <OrdersList /> },
            { path: 'canceled', element: <CanceledOrdersList /> },
            { path: ':branchId/:id', element: <OrderDetails /> },
            { path: 'reports/eotd', element: <EndOfDayReports /> },
            { path: 'reports/eotd/:id', element: <EotdDetails /> },
            // customer
            { path: 'customer/list', element: <CustomerList /> },
            { path: 'customer/:id', element: <CustomerStats /> },
            { path: 'customer/:id/edit', element: <CustomerEdit /> },
            { path: 'customer/new', element: <CustomerCreate /> },
            // discount
            { path: 'discount/list', element: <DiscountList /> },
            { path: 'discount/:id', element: <DiscountEdit /> },
            { path: 'discount/new', element: <DiscountCreate /> },
            { path: 'discount/:id/details', element: <DiscountViewDetails /> },

            // menus list
            { path: 'menu/list', element: <MenuList /> },
            { path: 'menu/:id', element: <MenuEdit /> },
            { path: 'menu/new', element: <MenuCreate /> },
            { path: 'menu/:id/details', element: <MenuViewDetails /> },

            // cancel reasons
            { path: 'cancel-reasons', element: <CancelReasonsList /> },
            { path: 'cancel-reasons/statistics', element: <CancelReasonsStatistics /> },
            { path: 'cancel-reasons/:id', element: <CancelReasonsDetails /> },

            // transactions
            { path: 'transactions', element: <TransactionsList /> },
            { path: 'transactions/:branchId/:id', element: <TransactionDetails /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      // element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// COMPANY
// BRANCHES
const BrachList = Loadable(lazy(() => import('../pages/dashboard/company/branches/BranchList')));
const BranchCreate = Loadable(
  lazy(() => import('../pages/dashboard/company/branches/BranchCreate'))
);
const BranchEdit = Loadable(lazy(() => import('../pages/dashboard/company/branches/BranchEdit')));
// WAREHOUSES
const WarehouseList = Loadable(
  lazy(() => import('../pages/dashboard/company/warehouses/WarehouseList'))
);
const WarehouseCreate = Loadable(
  lazy(() => import('../pages/dashboard/company/warehouses/WarehouseCreate'))
);
const WarehouseEdit = Loadable(
  lazy(() => import('../pages/dashboard/company/warehouses/WarehouseEdit'))
);

// POINTS OF SALE
const PointOfSaleList = Loadable(
  lazy(() => import('../pages/dashboard/company/pointsOfSale/PointsOfSaleList'))
);

// Sale Orders

const SaleOrderList = Loadable(
  lazy(() => import('../pages/dashboard/accounting/invoicing/SaleOrderList'))
);
const SaleOrderCreate = Loadable(
  lazy(() => import('../pages/dashboard/accounting/invoicing/SaleOrderCreate'))
);

// credit notes

const CreditNotesList = Loadable(
  lazy(() => import('../pages/dashboard/accounting/creditNotes/CreditNoteList'))
);

const CreditNotesCreate = Loadable(
  lazy(() => import('../pages/dashboard/accounting/creditNotes/CreditNoteCreate'))
);

const CreditNoteDetails = Loadable(
  lazy(() => import('../pages/dashboard/accounting/creditNotes/CreditNoteDetails'))
);

const InventoryItemList = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/items/InventoryItemList'))
);

const InventoryItemCreate = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/items/InventoryItemCreate'))
);

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceProductDetails = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceProductEdit = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductEdit'))
);
// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// PURCHASE ORDERS

const PurchaseOrderDetails = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/purchases/PurchaseOrderDetails'))
);

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// ROLE
const RoleList = Loadable(lazy(() => import('../pages/dashboard/roles/RoleList')));
const RoleCreate = Loadable(lazy(() => import('../pages/dashboard/roles/RoleCreate')));
const RoleEdit = Loadable(lazy(() => import('../pages/dashboard/roles/RoleEdit')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

//INVENTORY
const ProductList = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/products/ProductList'))
);
const ProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/products/ProductCreate'))
);
const ProductEdit = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/products/ProductEdit'))
);
const SuppliersList = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/suppliers/SupplierList'))
);

const DevicesList = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/devices/DevicesList'))
);
const DeviceCreate = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/devices/DeviceCreate'))
);
const DeviceEdit = Loadable(lazy(() => import('../pages/dashboard/wareHouse/devices/DeviceEdit')));

const VariantActionCreate = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/variantActions/VariantActionCreate'))
);

// stock taking
const StockTakingList = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/stockTaking/StockTakingList'))
);

const StockTakingCreate = Loadable(
  lazy(() => import('../pages/dashboard/wareHouse/stockTaking/StockTakingCreate'))
);

// ============================================

const CustomerList = Loadable(
  lazy(() => import('../pages/dashboard/orders/customers/CustomerList'))
);

const CustomerCreate = Loadable(
  lazy(() => import('../pages/dashboard/orders/customers/CustomerCreate'))
);

const CustomerEdit = Loadable(
  lazy(() => import('../pages/dashboard/orders/customers/CustomerEdit'))
);

const CustomerStats = Loadable(
  lazy(() => import('../pages/dashboard/orders/customers/CustomerStats'))
);

// DISCOUNTS
const DiscountList = Loadable(
  lazy(() => import('../pages/dashboard/orders/discounts/DiscountList'))
);

const DiscountCreate = Loadable(
  lazy(() => import('../pages/dashboard/orders/discounts/DiscountCreate'))
);

const DiscountEdit = Loadable(
  lazy(() => import('../pages/dashboard/orders/discounts/DiscountEdit'))
);

const DiscountViewDetails = Loadable(
  lazy(() => import('../pages/dashboard/orders/discounts/DiscountDetails'))
);

const OrdersList = Loadable(
  lazy(() => import('../pages/dashboard/orders/ordersHistory/OrdersList'))
);
const CanceledOrdersList = Loadable(
  lazy(() => import('../pages/dashboard/orders/ordersHistory/CanceledOrdersList'))
);

// Cancel Reasons
const CancelReasonsList = Loadable(
  lazy(() => import('../pages/dashboard/orders/cancel-reasons/CancelReasonsList'))
);

const CancelReasonsDetails = Loadable(
  lazy(() => import('../pages/dashboard/orders/cancel-reasons/CancelReasonsDetails'))
);

const CancelReasonsStatistics = Loadable(
  lazy(() => import('../pages/dashboard/orders/cancel-reasons/CancelReasonsStatistics'))
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// REPORTS

// WAREHOUSE CONTROL
const SupplierPayment = Loadable(
  lazy(() => import('../pages/dashboard/reports/warehouseControl/SupplierPayment'))
);
const SuppliersDebits = Loadable(
  lazy(() => import('../pages/dashboard/reports/warehouseControl/SuppliersDebits'))
);

const CompanyStockTaking = Loadable(
  lazy(() => import('../pages/dashboard/reports/warehouseControl/CompanyStockTaking'))
);

// TAX REPORTS
const TaxReport = Loadable(lazy(() => import('../pages/dashboard/reports/taxReport/TaxReport')));
const BranchesTaxReport = Loadable(
  lazy(() => import('../pages/dashboard/reports/taxReport/BranchesTaxReport'))
);

// WAREHOUSE
const IdleStock = Loadable(lazy(() => import('../pages/dashboard/reports/warehouse/IdleStock')));
const InventoriesActions = Loadable(
  lazy(() => import('../pages/dashboard/reports/warehouse/InventoriesActions'))
);
const StockValue = Loadable(lazy(() => import('../pages/dashboard/reports/warehouse/StockValue')));

// SALES REPORTS
const CustomersPayments = Loadable(
  lazy(() => import('../pages/dashboard/reports/salesReport/CustomersPayments'))
);
const SalesReportByBranch = Loadable(
  lazy(() => import('../pages/dashboard/reports/salesReport/SalesReportByBranch'))
);
const SalesReportByCategory = Loadable(
  lazy(() => import('../pages/dashboard/reports/salesReport/SalesReportByCategory'))
);
const SalesReportByUser = Loadable(
  lazy(() => import('../pages/dashboard/reports/salesReport/SalesReportByUser'))
);
const SalesReportByPaymentMethod = Loadable(
  lazy(() => import('../pages/dashboard/reports/salesReport/SalesReportByPaymentMethod'))
);
const SalesReportByUserCustomer = Loadable(
  lazy(() => import('../pages/dashboard/reports/salesReport/SalesReportByUserCustomer'))
);

const TransactionsList = Loadable(
  lazy(() => import('../pages/dashboard/orders/transactions/TransactionsList'))
);

const TransactionDetails = Loadable(
  lazy(() => import('../pages/dashboard/orders/transactions/TransactionDetails'))
);
