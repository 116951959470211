import { useState } from 'react';
// @mui
import { Checkbox, MenuItem, TableCell, TableRow } from '@mui/material';
// @types
// components
import useLocales from 'src/hooks/useLocales';
import { fDateTime } from 'src/utils/formatTime';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

type Props = {
  id: string;
  row: any;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function EotdTableRow({
  id,
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditRow,
}: Props) {
  const { openAt, closeAt, pointOfSaleSalesReport } = row;
  const { translate } = useLocales();

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected} id={id}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ alignItems: 'left' }}>{fDateTime(openAt)}</TableCell>

      <TableCell sx={{ alignItems: 'left' }}>{fDateTime(closeAt)}</TableCell>
      <TableCell sx={{ alignItems: 'left' }}>{pointOfSaleSalesReport?.cashInOpening}</TableCell>
      <TableCell sx={{ alignItems: 'left' }}>{pointOfSaleSalesReport?.cashInClosing}</TableCell>
      <TableCell sx={{ alignItems: 'left' }}>{pointOfSaleSalesReport?.expectedCash}</TableCell>
      <TableCell sx={{ alignItems: 'left' }}>{pointOfSaleSalesReport?.deference}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {/* <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                {translate('inventory.products.delete')}
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
                {translate('orders.view')}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
