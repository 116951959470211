import { createSlice } from '@reduxjs/toolkit';
// utils
import {
  CreateProduct,
  ProductState,
  UpdateProductPrice,
  UpdateQuantity,
} from '../../@types/product';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: ProductState = {
  isLoading: false,
  error: null,
  products: [],
  items: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // UPDATE PRODUCT
    updateProductSuccess(state, action) {
      state.isLoading = false;
      state.products = state.products.map((product) =>
        product.id === action.payload.id ? action.payload : product
      );
    },

    // DELETE PRODUCT
    deleteProductSuccess(state, action) {
      state.isLoading = false;
      state.products = state.products.filter((product) => product.id !== action.payload.id);
    },

    // GET ITEMS
    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },

    // update item success
    updateItemSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getProductsSuccess, updateProductSuccess, getItemsSuccess } =
  slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`inventory-items/${companyId}`);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add inventory item function

export async function addProduct(inventoryItem: CreateProduct): Promise<CreateProduct> {
  const companyId = localStorage.getItem('companyId');
  // remove images from inventory item object
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`/inventory-items/${companyId}`, inventoryItem);
    dispatch(slice.actions.updateProductSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error;
  }
}

//upload product image function
export function uploadProductImage(files: FormData, id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `/inventory-items/${companyId}/update-image/${id}`,
        files,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      dispatch(slice.actions.updateProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProduct(id: string, product: CreateProduct) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const {
        name,
        nameLocalized,
        canBePurchased,
        canBeSold,
        canBeStored,
        categoryId,
        description,
        taxId,
      } = product;
      const body = {
        name,
        nameLocalized,
        canBePurchased,
        canBeSold,
        canBeStored,
        categoryId,
        description,
        taxId,
      };
      const response = await axios.patch(`/inventory-items/${companyId}/${id}`, body);
      dispatch(slice.actions.updateProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// delete product function
export function deleteProduct(id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: CreateProduct } = await axios.delete(
        `/inventory-items/${companyId}/${id}`
      );
      dispatch(slice.actions.updateProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// active product
export function activeProduct(id: string) {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: CreateProduct } = await axios.get(
        `/inventory-items/${companyId}/active/${id}`
      );
      dispatch(slice.actions.updateProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getItems() {
  return async () => {
    const companyId = localStorage.getItem('companyId');
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`inventory-items/items/${companyId}`);
      dispatch(slice.actions.getItemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getItemsByBranch(branchId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`inventory-items/by-branch/${branchId}`);
      dispatch(slice.actions.getItemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getItemsByInventory(inventoryId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await axios.get(`inventory-items/${companyId}/by-inventory/${inventoryId}`);
      dispatch(slice.actions.getItemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updatePrice(data: UpdateProductPrice) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`inventory-items/items/${companyId}/update-price`, data);
      dispatch(slice.actions.updateItemSuccess(response.data));
      return response?.data;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function updateQuantity(id: number, data: UpdateQuantity) {
  const companyId = localStorage.getItem('companyId');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `inventory-items/${companyId}/update-quantity/${id}`,
        data
      );
      dispatch(slice.actions.updateItemSuccess(response.data));
      return response?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
