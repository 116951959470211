import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { getMenus } from 'src/redux/slices/menu';
import { useDispatch, useSelector } from 'src/redux/store';
import MenuNewEditFrom from './MenuNewEditForm';
import { PATH_DASHBOARD } from 'src/routes/paths';

export default function MenuEdit() {
  const { translate } = useLocales();

  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { id = 0 } = useParams();

  const { menus } = useSelector((state) => state.menus);

  const currentMenu = menus?.find((menu) => menu.id === Number(id));

  useEffect(() => {
    dispatch(getMenus());
  }, [dispatch]);

  return (
    <Page title={translate('menus.edit')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('menus.edit')}
          links={[
            { name: translate('routes.root'), href: PATH_DASHBOARD.root },
            { name: translate('menus.title'), href: PATH_DASHBOARD.orders.menu.list },
            { name: currentMenu?.name as string },
          ]}
        />

        <MenuNewEditFrom isEdit currentMenu={currentMenu} />
      </Container>
    </Page>
  );
}
