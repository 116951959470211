// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
// hooks
import useLocales from '../../../../hooks/useLocales';
import ActionNewEditForm from './ActionNewEditForm';
import { useParams } from 'react-router-dom';
import { paramCase } from 'change-case';

// ----------------------------------------------------------------------

export default function ActionCreate() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  //get id from params
  const { id } = useParams<{ id: string }>();

  return (
    <Page title="Inventory: Create a new Ingredient">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.products.add_ingredient')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.inventory'),
              href: PATH_DASHBOARD.inventory.root,
            },
            { name: translate('inventory.products.add_ingredient') },
          ]}
        />
        <ActionNewEditForm id={paramCase(id as string)} />
      </Container>
    </Page>
  );
}
