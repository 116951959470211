import { useContext } from 'react';
import { NavListItemProps, NavListProps } from 'src/components/nav-section';
import { AbilityContext } from 'src/contexts/Can';

export const CanViewMenuGroup = (item: NavListProps) => {
  const ability = useContext(AbilityContext);
  // ! This same logic is used in canViewHorizontalNavMenuGroup and canViewHorizontalNavMenuHeaderGroup. So make sure to update logic in them as well
  const hasAnyVisibleChild =
    item.children &&
    item.children.some((i: NavListItemProps) => ability.can(i.meta?.action, i.meta?.resource));

  // ** If resource and action is defined in item => Return based on children visibility (Hide group if no child is visible)
  // ** Else check for ability using provided resource and action along with checking if has any visible child
  if (!(item.meta?.action && item.meta?.resource)) {
    return hasAnyVisibleChild;
  }
  return ability.can(item.meta?.action, item.meta?.resource) && hasAnyVisibleChild;
};

export const CanViewMenuItem = (item: NavListItemProps) => {
  const ability = useContext(AbilityContext);
  return ability.can(item.meta?.action, item.meta?.resource);
};
