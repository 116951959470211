// @mui
import {
  Box,
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TransferRequest, TransferRequestStatus } from 'src/@types/transferRequest';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import Image from '../../../../../components/Image';
import TransferRequestToolbar from './TransferRequestToolbar';

// ----------------------------------------------------------------------

type Props = {
  invoice?: TransferRequest;
};

export default function TransferRequestInvoice({ invoice }: Props) {
  const theme = useTheme();
  const { user } = useAuth();
  const { translate } = useLocales();

  if (!invoice) {
    return null;
  }

  const { transferItems, fromInventory, toInventory } = invoice;

  return (
    <>
      <TransferRequestToolbar invoice={invoice} />
      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: { sm: 'left' } }}>
              <Typography paragraph variant="overline">
                {' '}
                {translate('inventory.transfers_requests.transfer_request_status')}
              </Typography>
              <Typography variant="overline">
                {translate(`inventory.transfers_requests.status.${invoice.status}`)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5, alignSelf: 'end' }}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src={user?.companyMemberships[0].company.profilePictureUrl}
              sx={{ maxWidth: '100%' }}
            />
          </Grid>

          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (invoice.status === TransferRequestStatus.APPROVED && 'success') ||
                  (invoice.status === TransferRequestStatus.INITIAL && 'warning') ||
                  (invoice.status === TransferRequestStatus.PENDING && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {invoice.status}
              </Label>
              <Typography>
                {translate('inventory.transfers_requests.transfer_request_no')}
              </Typography>
              <Typography variant="h6">{`TR-${invoice.id}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Typography variant="body2" sx={{ fontWeight: '600' }}>
              {translate('accounting.invoicing.company_details')} :{' '}
            </Typography>
            <Typography sx={{ ml: 4 }} variant="body2">
              {user?.companyMemberships[0].company.name}
            </Typography>
            <Typography sx={{ ml: 4 }} variant="body2">
              {user?.companyMemberships[0].company.address}
            </Typography>
            <Typography sx={{ ml: 4 }} variant="body2">
              {user?.companyMemberships[0].company.vatRegistrationNumber}
            </Typography>
            <Typography sx={{ ml: 4 }} variant="body2">
              {fromInventory?.name ?? ''}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'center' } }}>
              <Typography variant="h6">
                {' '}
                {translate('inventory.transfers_requests.transfer_request')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }} />
          </Grid>

          <Grid item xs={12} sm={12} sx={{ mb: 5 }}>
            <Typography>
              {translate('inventory.transfers_requests.to_inventory')} : {toInventory?.name}
            </Typography>
            <Typography sx={{ ml: 7 }} variant="body2">
              {toInventory?.address}
            </Typography>
            {/* <Typography sx={{ ml: 7 }} variant="body2">
              {supplier?.vatRegistrationNumber ?? ''}
            </Typography> */}
          </Grid>

          {/* <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              date create
            </Typography>
           
          </Grid> */}
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">{translate('orders.description')}</TableCell>
                  <TableCell align="left">{translate('orders.qty')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {transferItems.map((row: any, index: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{row.item.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.createdQty}</TableCell>
                  </TableRow>
                ))}

                {/* <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Box sx={{ mt: 2 }} />
                    <Typography>Subtotal</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Box sx={{ mt: 2 }} />
                    <Typography>{fCurrency(subTotalPrice)}</Typography>
                  </TableCell>
                </RowResultStyle> */}

                {/* <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography>Discount</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography sx={{ color: 'error.main' }}>
                      {discount && fCurrency(-discount)}
                    </Typography>
                  </TableCell>
                </RowResultStyle> */}

                {/* <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography>{translate('accounting.invoicing.invoice_tax')}</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography>{totalTax && fCurrency(totalTax)}</Typography>
                  </TableCell>
                </RowResultStyle>

                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography variant="h6">{translate('orders.total')}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="h6">{fCurrency(totalWithTax)}</Typography>
                  </TableCell>
                </RowResultStyle> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        {/* <Grid item xs={12} sm={4} sx={{ mt: 5 }}>
          {orderInvoice.qrCode && (
            <div style={{ height: 'auto', margin: '0 auto', maxWidth: 120, width: '100%' }}>
              <QRCode
                name="QRCode"
                id="QRCode"
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={orderInvoice.qrCode}
                viewBox={`0 0 256 256`}
              />
            </div>
          )}
        </Grid> */}

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} md={5} sx={{ py: 3 }}>
            <Typography variant="subtitle2">
              {translate('inventory.transfers_requests.status.title')}
            </Typography>
            <Typography variant="body2">
              {translate(`inventory.transfers_requests.status.${invoice.status}`)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">
              {translate('accounting.invoicing.generated_using')}{' '}
              {translate('accounting.invoicing.onestep_system')}{' '}
            </Typography>
            <Typography variant="body2">info@onestep.com.sa</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
