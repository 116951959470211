import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
  RHFUploadSingleFile,
} from 'src/components/hook-form';
import { CustomFile } from 'src/components/upload';
import useLocales from 'src/hooks/useLocales';
import { getBranches } from 'src/redux/slices/branch';
import { createMenu, updateMenu, uploadMenuPDF } from 'src/redux/slices/menu';
import { useDispatch, useSelector } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fData } from 'src/utils/formatNumber';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

interface FormValuesProps extends Omit<any, 'image'> {
  name: string;
  image: CustomFile | string | null;
}

type Props = {
  isEdit: boolean;
  currentMenu?: any;
};

export default function MenuNewEditFrom({ isEdit, currentMenu }: Props) {
  const { translate } = useLocales();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const NewBranchSchema = Yup.object().shape({
    name: Yup.string().required(translate('errors.name_required')),
    nameLocalized: Yup.string().required(translate('errors.name_required')),
    image: Yup.string().required(translate('errors.image_required')),
    branchId: Yup.number().required(translate('errors.branch_required')),
  });

  const { isLoading } = useSelector((state) => state.menus);
  const { branches } = useSelector((state) => state.branches);

  const defaultValues = useMemo(
    () => ({
      name: currentMenu?.name || '',
      nameLocalized: currentMenu?.nameLocalized || '',
      image: currentMenu?.image || undefined,
      branchId: currentMenu?.branchId || undefined,
    }),

    [currentMenu]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewBranchSchema),
    defaultValues,
  });

  const { reset, setValue, getValues, handleSubmit } = methods;

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  useEffect(() => {
    if (isEdit && currentMenu) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentMenu]);

  const handleUploadChange = async (id: any) => {
    const { image } = getValues();
    let formData = new FormData();

    if (image) {
      formData.append('files', image);
      try {
        dispatch(await uploadMenuPDF(formData, id, currentMenu?.branchId));
      } catch (error) {
        enqueueSnackbar({ ...error, message: 'auth.errors.error_occurred' }, { variant: 'error' });
      }
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const image = acceptedFiles[0];

      setValue(
        'image',
        Object.assign(image, {
          preview: URL.createObjectURL(image),
        })
      );
    },
    [setValue]
  );

  const onSubmit = async (data: FormValuesProps) => {
    if (isEdit && currentMenu) {
      try {
        data.id = currentMenu.id;
        const body = {
          name: data.name,
          nameLocalized: data.nameLocalized,
        };
        await dispatch(updateMenu(body));
        reset();
        enqueueSnackbar(translate('messages.update_success'));
      } catch (error) {
        console.error(error);
      }
    }
    if (!isEdit) {
      try {
        const dataWithoutImages = {
          name: data.name,
          nameLocalized: data.nameLocalized,
          branchId: parseInt(data.branchId),
        };
        currentMenu = dataWithoutImages;
        const res = await dispatch(createMenu(dataWithoutImages));
        console.log(res);
        if (res?.id) {
          await handleUploadChange(res?.id);
        }
        reset();
        enqueueSnackbar(translate('messages.create_success'));
        navigate(PATH_DASHBOARD.orders.menu.list);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="name" label={translate('menus.name')} />
              <RHFTextField name="nameLocalized" label={translate('menus.nameLocalized')} />
            </Box>

            {/* branch select */}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
              <RHFSelect
                name="branchId"
                label={translate('menus.select_branch')}
                sx={{ minWidth: 120 }}
              >
                <option value="" />

                {branches?.map((branch: any) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </RHFSelect>
            </Box>

            <Box sx={{ mt: 3 }}>
              <LabelStyle>{translate('menus.upload_pdf_file')}</LabelStyle>
              <RHFUploadSingleFile
                name="image"
                accept={{ 'application/pdf': ['.pdf'] }}
                onDrop={handleDrop}
                maxSize={5000000}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    {'account.allowed_picture_types'}
                    {'account.max_file_size'} {fData(5000000)}
                  </Typography>
                }
              />
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                {!isEdit ? translate('general.create') : translate('general.edit')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
