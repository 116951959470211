import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import useLocales from '../../../../../hooks/useLocales';
// components
import { Branch } from '../../../../../@types/branch';
import Iconify from '../../../../../components/Iconify';

// ----------------------------------------------------------------------
const INPUT_WIDTH = 160;

type Props = {
  branches: Branch[];
  filterName: string;
  filterBranch: number;
  onFilterName: (value: string) => void;
  onFilterBranch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function OrderTypesTableToolbar({
  branches,
  filterName,
  filterBranch,
  onFilterName,
  onFilterBranch,
}: Props) {
  const { translate } = useLocales();
  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        select
        label={translate('company.points_of_sale.choose_branch')}
        value={filterBranch}
        onChange={onFilterBranch}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {branches.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={translate('inventory.order_types.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
