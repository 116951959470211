import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
import useTable, { emptyRows, getComparator } from '../../../../hooks/useTable';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// @types
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from '../../../../components/table';
// sections
// Translate
import { Can } from '@casl/react';
import { Tax } from 'src/@types/tax';
import DialogAnimate from 'src/components/animate/DialogAnimate';
import { getTaxes } from 'src/redux/slices/tax';
import TaxTableRow from 'src/sections/@dashboard/company/taxes/list/TaxTableRow';
import TaxTableToolbar from 'src/sections/@dashboard/company/taxes/list/TaxTableToolbar';
import ability from 'src/utils/ability';
import useLocales from '../../../../hooks/useLocales';
import TaxesCreateEditForm from './TaxesEdit';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'company.taxes.name', align: 'left' },
  { id: 'rate', label: 'company.taxes.rate', align: 'center' },
  { id: 'default', label: 'company.taxes.default', align: 'center' },
  { id: '' },
];

export default function TaxesList() {
  const { themeStretch } = useSettings();

  const { translate } = useLocales();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { taxes } = useSelector((state) => state.taxes);

  const [currentId, setCurrentId] = useState(0);

  const [isEdit, setIsEdit] = useState(false);

  const [onCreateAndEdit, setOnCreateAndEdit] = useState(false);

  const [currentTax, setCurrentTax] = useState<Tax | null>(null);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [tableData, setTableData] = useState(taxes as Tax[]);

  const [filterName, setFilterName] = useState('');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleClose = () => {
    setOnCreateAndEdit(false);
    setIsEdit(false);
    setCurrentId(0);
  };

  const handleEdit = (id: number) => {
    setCurrentId(id);
    setIsEdit(true);
    setOnCreateAndEdit(true);
  };

  const handleDeleteRow = (id: number) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleViewRow = (id: number) => {
    navigate('/');
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = (!dataFiltered.length && !!filterName) || !tableData.length;

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    dispatch(getTaxes());
  }, [dispatch]);

  useEffect(() => {
    setTableData(taxes as Tax[]);
  }, [taxes]);

  useEffect(() => {
    const filteredTaxes = taxes?.filter((tax) => tax.id === currentId);
    setCurrentTax(filteredTaxes ? filteredTaxes[0] : null);
  }, [currentId, taxes]);

  return (
    <Page title={translate('company.taxes.title')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('company.taxes.title')}
          links={[
            { name: translate('routes.root'), href: PATH_DASHBOARD.root },
            { name: translate('company.title'), href: PATH_DASHBOARD.invoice.root },
            { name: translate('company.taxes.title') },
          ]}
          action={
            <Can I="write" a="tax" ability={ability}>
              {() => (
                <Button
                  variant="contained"
                  onClick={() => setOnCreateAndEdit(true)}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  {translate('company.taxes.add_tax')}
                </Button>
              )}
            </Can>
          }
        />

        <Card>
          <TaxTableToolbar filterName={filterName} onFilterName={handleFilterName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id.toString())
                    )
                  }
                  actions={
                    <Stack spacing={1} direction="row">
                      <Tooltip title="Sent">
                        <IconButton color="primary">
                          <Iconify icon={'ic:round-send'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Download">
                        <IconButton color="primary">
                          <Iconify icon={'eva:download-outline'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Print">
                        <IconButton color="primary">
                          <Iconify icon={'eva:printer-fill'} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id.toString())
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TaxTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id.toString())}
                        onSelectRow={() => onSelectRow(row.id.toString())}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEdit(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label={translate('general.dense')}
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>

        <DialogAnimate open={onCreateAndEdit} onClose={handleClose}>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center">
              {translate(isEdit ? 'company.taxes.edit_tax' : 'company.taxes.add_tax')}
            </Typography>
          </Box>
          <TaxesCreateEditForm isEdit={isEdit} currentTax={currentTax} handleClose={handleClose} />
        </DialogAnimate>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: Tax[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
