// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
// hooks
import useSettings from '../../../../../hooks/useSettings';
// components
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
// hooks
import useLocales from '../../../../../hooks/useLocales';
import ConversionNewEditForm from './ConversionNewEditForm';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'src/redux/store';
import { getUoms } from 'src/redux/slices/uom';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function ConversionCreate() {
  const { themeStretch } = useSettings();
  const { id = '' } = useParams();
  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { unitOfMeasures } = useSelector((state) => state.uom);

  const currentUnit = unitOfMeasures.find((uom) => uom.id === parseInt(id));

  useEffect(() => {
    dispatch(getUoms());
  }, [dispatch]);

  return (
    <Page title="Inventory: Create a new Conversion">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('inventory.conversions.create_conversion')}
          links={[
            { name: translate('nav.dashboard'), href: PATH_DASHBOARD.root },
            {
              name: translate('nav.unitsConversion'),
              href: PATH_DASHBOARD.inventory.units,
            },
            { name: translate('inventory.conversions.create_conversion') },
          ]}
        />
        <ConversionNewEditForm currentUnit={currentUnit} />
      </Container>
    </Page>
  );
}
