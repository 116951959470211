import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, MenuItem, Stack } from '@mui/material';
// utils
// components
import { FormProvider, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
// translate
import useLocales from '../../../../../hooks/useLocales';
// redux
import { SaleOrder } from 'src/@types/saleOrder';
import { startLoading } from 'src/redux/slices/orderType';
import { paySaleOrder } from '../../../../../redux/slices/saleOrder';
import { useDispatch } from '../../../../../redux/store';

// ----------------------------------------------------------------------

type TPayment = {
  paymentMethod: string;
  paid: number;
};

const PAYMENT_METHODS = ['CASH', 'CARD', 'BANK_TRANSFER', 'CHECK'];

interface FormValuesProps extends Omit<TPayment, 'avatarUrl'> {}

type Props = {
  id: string;
  currentInvoice?: SaleOrder;
  handleClose: () => void;
};

export default function RecordPayment({ id, currentInvoice, handleClose }: Props) {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const NewPointOfSaleSchema = Yup.object().shape({
    paymentMethod: Yup.string().required(translate('errors.name_required')),
    paid: Yup.number().required(translate('errors.name_required')),
  });

  const defaultValues = useMemo(
    () => ({
      paymentMethod: '',
      paid: (currentInvoice?.total && parseInt(currentInvoice.total)) || 0,
    }),
    [currentInvoice?.total]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPointOfSaleSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      console.log(data);
      startLoading();
      await dispatch(paySaleOrder(id, data));
      reset();
      enqueueSnackbar(translate('messages.create_success'));
      window.location.reload();
      handleClose();
    } catch (error) {
      enqueueSnackbar(translate('messages.create_error'));
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField
                name="paid"
                type="number"
                label={translate('accounting.invoicing.paid_amount')}
                disabled
              />
              <RHFSelect
                InputLabelProps={{
                  shrink: true,
                }}
                name="paymentMethod"
                label={translate('accounting.invoicing.payment_method')}
                SelectProps={{
                  native: false,
                  sx: { textTransform: 'capitalize' },
                }}
              >
                <MenuItem>{translate('accounting.invoicing.choose_payment_method')}</MenuItem>
                {PAYMENT_METHODS &&
                  PAYMENT_METHODS?.map((method) => (
                    <MenuItem key={method} value={method}>
                      {translate(`accounting.invoicing.${method}`)}
                    </MenuItem>
                  ))}
              </RHFSelect>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {translate('general.create')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
